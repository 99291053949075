import Typography from "@mui/material/Typography";


import google from "../image/google.png";
import app from "../image/app.png";
import mobile from "../image/phone.svg";
import mobile1 from "../image/mobileImg.png";

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Rating from '@mui/material/Rating';
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import "../css/body.css";
import { Grid, Box, Avatar, CardContent, Card } from "@mui/material";
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';
import "../css/landing_body.css"
import { useState } from "react";
import { Link } from "react-router-dom";

const LandingBody = () => {
    const [value, setValue] = useState(4);

    return (
        <>
            <Grid container component="main" className="mainContainer">

                <Grid item xs={12} lg={7} >

                    <Typography variant="h4" style={{ fontWeigth: "bolder", }}><strong>Welcome to Meddaily.in <br></br>The Ultimate B2B Solution for Healthcare Professionals </strong></Typography>

                    <br></br>

                    <Typography color="text.secondary" style={{ fontSize: "16px" }}> Are you ready to take your healthcare business to new heights? Look no further than Meddaily.in app, the groundbreaking B2B app designed to revolutionize the way medical professionals collaborate and thrive in today's fast-paced industry.</Typography>
                    <br></br>
                    <br></br>
                    <Grid>
                        <Stack direction="row" spacing={2}>
                            <Link to="/retailer_login" >
                                <button className="getStarted-btn"> <span className="landStart">Get</span>  Started</button>
                            </Link>
                            <br/>
                            <button variant="outlined" className="btn-learnmore" style={{ color: 'black', border: '1px solid black' }} >Learn More</button>
                        </Stack>
                    </Grid>
                    <br></br>

                    <Typography variant="h6" style={{ fontWeight: "bolder" }}> Our Testimoney </Typography>
                    <br></br>
                    <Stack direction="row" spacing={2}>
                        <Avatar sx={{ backgroundColor: '#f7f7f7' }}>
                            <Typography className='menuIcon' sx={{ mt: 1 }}><ThumbUpOutlinedIcon sx={{ color: '#6eafab' }} /></Typography>
                        </Avatar>
                        <Rating name="read-only" value={value} readOnly />
                    </Stack>
                    <Typography sx={{ ml: 7.5 }}>Review 4.6/5</Typography>
                </Grid>

                <Grid item xs={12} lg={5} >
                    <div classname='imageStylingBox'>
                        <img src={mobile1} alt="mobile_img1" className="imageStyles" />
                    </div>
                </Grid>
                {/* <Grid item xs={12} lg={5} >
                    <div classname='imageStylingBox'>
                        <img src={mobile1} alt="mobile_img1" height='600rem' width="520rem" />
                    </div>
                </Grid> */}
            </Grid >

            <Box className="commonBoxStyle">
                <Grid xs={12}>
                    <Typography variant="h4" style={{ fontWeigth: "bolder" }}><strong><center>We are making it simple</center> </strong></Typography>
                </Grid>
                <Grid container spacing={2} >

                    <Grid item lg={4} xs={12}>
                        <Card className="cardlanding" >

                            <CardContent>
                                <Avatar sx={{ backgroundColor: '#f7f7f7' }}>
                                    <Typography className='menuIcon' ><LoginOutlinedIcon sx={{ color: '#6eafab' }} /></Typography>
                                </Avatar>
                                <br />
                                <Typography variant="h6" style={{ fontWeigth: "bolder" }}>
                                    Sign Up or Log in
                                </Typography>
                                <br />

                                <Typography color="text.secondary" style={{ fontSize: "16px" }}>
                                    Download the MedDaily app from your app store and create your account. If you already have an account, simply log in to proceed.
                                </Typography>

                            </CardContent>

                        </Card>
                    </Grid>

                    <Grid item xs={12} lg={4}>
                        <Card className="cardlanding">

                            <CardContent>
                                <Avatar sx={{ backgroundColor: '#f7f7f7' }}>
                                    <Typography className='menuIcon' ><MedicalServicesOutlinedIcon sx={{ color: '#6eafab' }} /></Typography>
                                </Avatar>
                                <br />
                                <Typography variant="h6" style={{ fontWeigth: "bolder" }}>
                                    Search for your medicine
                                </Typography>
                                <br />

                                <Typography color="text.secondary" style={{ fontSize: "16px" }}>
                                    Use the search bar or browse through categories to find the specific medicine offering by your nearest distributor.
                                </Typography>

                            </CardContent>

                        </Card>
                    </Grid>

                    <Grid item lg={4} xs={12} >
                        <Card className="cardlanding">
                            <CardContent>
                                <Avatar sx={{ backgroundColor: '#f7f7f7' }}>
                                    {/* <Avatar sx={{ backgroundColor: '#f7f7f7' }}> */}
                                    <Typography className='menuIcon' ><PersonAddAltOutlinedIcon sx={{ color: '#6eafab' }} /></Typography>
                                </Avatar>
                                <br />
                                <Typography variant="h6" style={{ fontWeigth: "bolder" }}>
                                    Place your order
                                </Typography>
                                <br />

                                <Typography color="text.secondary" style={{ fontSize: "16px" }}>
                                    Click on the "Place Order" button to confirm your purchase. You will receive an order confirmation and a summary of your purchase.
                                </Typography>

                            </CardContent>

                        </Card>
                    </Grid>
                </Grid>
            </Box>



            <div className="promotionlanding">
                <div>
                    <Typography variant="h5" style={{ color: "black" }}>
                        <h3  >Working For Your Better health</h3>

                        <Typography color="text.secondary" style={{ fontSize: "16px" }}>
                            Are you ready to take your healthcare business to new heights? Look no further than Meddaily.in app, the groundbreaking B2B app designed to revolutionize the way medical professionals collaborate and thrive in today's fast-paced industry.
                        </Typography>
                    </Typography>
                    <img src={google} alt="logo" className="downloadlanding" />
                    <img src={app} alt="logo" className="downloadlanding" />
                </div>
                <div className="mobile-imglanding">
                    <img style={{width:"100%"}} src={mobile} alt="mobile_img" />
                </div>
            </div>
        </>
    );
};

export default LandingBody;

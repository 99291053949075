import Home from "./pages/home";
import Products from "./pages/products";
import Details from "./pages/details";
import TermsCondition from "./pages/terms_condition";
import PrivacyPolicy from "./pages/privacy_policy";
import OrderSummary from "./pages/order_Summary";
import ContactUs from "./pages/contact";
import Login from "./pages/login";
import Forgot from "./pages/forgot";
import ChangeForgot from "./pages/changePasssword";

import SignUp from "./pages/signup";
import { Route, Routes } from "react-router-dom";
import GlobalStyleOverrides from "./components/theme";
import { ThemeProvider } from "@mui/material/styles";
// import Leading from "./pages/landing";
import Landing from "./pages/landing";
import Dashboard from "./pages/DashBoard";
import Layout from "./components/resuableComponents/Layout";
// import RetNavbar from "./pages/RetNavbar";
// import Sidebar from "./pages/Sidebar";
import OrderList from "./pages/OrderList";
import ReturnList from "./pages/ReturnList";
import OrderDetail from "./pages/OrderDetail";
import DisLogin from "./pages/DisLogin";
import DistDashboard from "./pages/DistDashboard";
import ProductList from "./pages/ProductList";
import InventoryList from "./pages/InventoryList";
import DDashboard from "./components/resuableComponents/DDashboard";
import BulkUploadInv from "./pages/BulkUploadInv";
import ListNewProductInv from "./pages/ListNewProductInv";
import DisReturnTable from "./pages/DisReturnTable";
import DisorderDetail from "./pages/DiorderDetail";
import Invoice from "./pages/Invoice";
import AddOffer from "./pages/AddOffer";
import ReturnPolicy from "./pages/ReturnPolicy";
import DistributorSignUp from "./pages/DistributorSignUp";
import PaymentConfirmed from "./pages/PaymentConfirmed";
import ViewDetails from "./pages/viewdetails";
import Detailsreturn from "./pages/Detailsreturn";

function App() {
  return (
    <>
      <ThemeProvider theme={GlobalStyleOverrides()}>
        <Routes>
          <Route
            path="/dashboard"
            element={
              <>
                <Layout pageTitle={"Dashboard"}>
                  <Dashboard />
                </Layout>
              </>
            }
          ></Route>
          <Route
            path="/orderlist"
            element={
              <>
                <Layout pageTitle={"Dashboard"}>
                  <OrderList />
                </Layout>
              </>
            }
          ></Route>
          <Route
            path="/returnlist"
            element={
              <>
                <Layout pageTitle={"Dashboard"}>
                  <ReturnList />
                </Layout>
              </>
            }
          ></Route>
          <Route
            path="/orderdetail/:orderId"
            element={
              <>
                <Layout pageTitle={"Dashboard"}>
                  <OrderDetail />
                </Layout>
              </>
            }
          ></Route>
          <Route
            path="/payment_confirmed/:id"
            element={
              <>
                {/* <Layout pageTitle={"Thank You"}> */}
                <PaymentConfirmed />
                {/* </Layout> */}
              </>
            }
          ></Route>

          {/* <Route
						path='/inventorylist'
						element={
							<>
								<Layout pageTitle={'Dashboard'}>
									<InventoryList />
								</Layout>
							</>
						}></Route> */}
          <Route path="/" exact element={<Landing />} />
          <Route path="/home" exact element={<Home />} />
          <Route path="/products" exact element={<Products />} />
          <Route path="/products/:categoryId" exact element={<Products />} />
          <Route path="/products/:category_Id" exact element={<Products />} />

          <Route path="/details" exact element={<Details />} />
          <Route path="/details/:productId" exact element={<Details />} />

          <Route path="/termscondition" exact element={<TermsCondition />} />
          <Route path="/return-policy" exact element={<ReturnPolicy />} />
          <Route path="/privacy" exact element={<PrivacyPolicy />} />
          <Route path="/ordersummary" exact element={<OrderSummary />} />
          <Route path="/contactus" exact element={<ContactUs />} />
          <Route path="/retailer_login" exact element={<Login />} />
          <Route path="/retailer_forgot" exact element={<Forgot />} />
          <Route
            path="/retailer_forgot_setpassword"
            exact
            element={<ChangeForgot />}
          />

          <Route path="/card/:_id" exact element={<ViewDetails />} />
          <Route path="/detailsreturn" exact element={<Detailsreturn />} />

          <Route path="/retailer_signup" exact element={<SignUp />} />
          <Route
            path="/distributor_signup"
            exact
            element={<DistributorSignUp />}
          />
          <Route path="/distributorlogin" exact element={<DisLogin />} />
          {/* <Route path='/distdashboard' element={<DistDashbord />} /> */}
          <Route
            path="/distdashboard"
            element={
              <>
                <DistDashboard>
                  <DDashboard />
                </DistDashboard>
              </>
            }
          ></Route>
          <Route
            path="/productlist"
            element={
              <>
                <DistDashboard>
                  <ProductList />
                </DistDashboard>
              </>
            }
          ></Route>
          <Route
            path="/myinventory"
            element={
              <>
                <DistDashboard>
                  <InventoryList />
                </DistDashboard>
              </>
            }
          ></Route>
          <Route
            path="/bulkuploadinv"
            element={
              <>
                <DistDashboard>
                  <BulkUploadInv />
                </DistDashboard>
              </>
            }
          ></Route>
          <Route
            path="/listnewinv"
            element={
              <>
                <DistDashboard>
                  <ListNewProductInv />
                </DistDashboard>
              </>
            }
          ></Route>
          <Route
            path="/distreturn"
            element={
              <>
                <DistDashboard>
                  <DisReturnTable />
                </DistDashboard>
              </>
            }
          ></Route>
          <Route
            path="/disorderdetail/:orderId"
            element={
              <>
                <DistDashboard>
                  <DisorderDetail />
                </DistDashboard>
              </>
            }
          ></Route>
          <Route
            path="/invoice"
            element={
              <>
                <DistDashboard>
                  <Invoice />
                </DistDashboard>
              </>
            }
          ></Route>
          <Route
            path="/offers"
            element={
              <>
                <DistDashboard>
                  <AddOffer />
                </DistDashboard>
              </>
            }
          ></Route>
          {/* <Route path='/orderlist' element={<OrderList />} /> */}

          <Route path="*" exact element={<h1>Page Not found</h1>} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;

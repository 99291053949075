// import axios from "axios";
import { axiosInstance } from "../Axios";
const { createSlice } = require("@reduxjs/toolkit");
const toaster = require("toastr");
// var easyinvoice = require('easyinvoice');
// import * as easyinvoice from 'easyinvoice'
// var fileDownload = require('js-file-download');

const HomeReducer = createSlice({
  name: "home",
  initialState: {
    success: false,
    error: null,
    loading: false,
    category: null,
    allorder: null,
    allreturn: null,
    login: null,
    signUp: null,
    retailerProfile: null,
    dsID: null,
    addcart: null,
    deletecart: null,
    orderDetails: null,
    cart: null,
    offer: null,
    offerSelect: null,
    dislogin: null,
    disprofile: null,
    disorder: null,
    disproduct: null,
    disInvent: null,
    disReturn: null,
    currentMenuOption: "program",
    currentSubMenuOption: "",
  },
  reducers: {
    categoryRequest(state, action) {
      state.loading = true;
    },
    categorySuccess(state, action) {
      state.loading = false;
      state.category = action.payload;
      state.error = null;
    },
    categoryFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    allorderRequest(state, action) {
      state.loading = true;
    },
    allorderSuccess(state, action) {
      state.loading = false;
      state.allorder = action.payload;
      state.error = null;
    },
    allorderFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    allreturnRequest(state, action) {
      state.loading = false;
    },
    allreturnSuccess(state, action) {
      state.loading = false;
      state.allreturn = action.payload;
      state.error = null;
    },
    allreturnFail(state, action) {
      state.loading = false;
    },
    loginRequest(state, action) {
      state.loading = true;
    },
    loginSuccess(state, action) {
      state.loading = false;
      state.login = action.payload;
      state.error = null;
    },
    loginFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    signUpRequest(state, action) {
      state.loading = true;
    },
    signUpSuccess(state, action) {
      state.loading = false;
      state.signUp = action.payload;
      state.error = null;
    },
    signUpFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    retailerprofileRequest(state, action) {
      state.loading = true;
    },
    retailerprofileSuccess(state, action) {
      state.loading = false;
      state.retailerProfile = action.payload;
      state.error = null;
    },
    retailerprofileFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setSelectedDestributorID(state, action) {
      state.dsID = action.payload;
    },
    setSelectedOffer(state, action) {
      state.offerSelect = action.payload;
    },
    addcartRequest(state, action) {
      state.loading = true;
    },
    addcartSuccess(state, action) {
      state.loading = false;
      state.addcart = action.payload;
      state.error = null;
    },
    addcartFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    deletecartRequest(state, action) {
      state.loading = true;
    },
    deletecartSuccess(state, action) {
      state.loading = false;
      state.deletecart = action.payload;
      state.error = null;
    },
    deletecartFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    orderdetailsRequest(state, action) {
      state.loading = true;
    },
    orderdetailsSuccess(state, action) {
      state.loading = false;
      state.orderDetails = action.payload;
      state.error = null;
    },
    orderdetailsSuccessNew(state, action) {
      state.loading = false;
      state.orderDetails = action.payload;
      state.error = null;
    },
    orderdetailsFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    orderdetailsFailNew(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getCartRequest(state, action) {
      state.loading = true;
    },
    getCartSuccess(state, action) {
      state.loading = false;
      state.cart = action.payload;
      state.error = null;
    },
    MyOrderFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    MyOrderRequest(state, action) {
      state.loading = true;
    },
    MyOrderSuccess(state, action) {
      state.loading = false;
      state.cart = action.payload;
      state.error = null;
    },
    getCartFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    getOfferRequest(state, action) {
      state.loading = true;
    },
    getOfferSuccess(state, action) {
      state.loading = false;
      state.offer = action.payload;
      state.error = null;
    },
    getOfferFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    disloginRequest(state, action) {
      state.loading = true;
    },
    disloginSuccess(state, action) {
      state.loading = false;
      state.dislogin = action.payload;
      state.error = null;
    },
    disloginFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    disprofileRequest(state, action) {
      state.loading = true;
    },
    disprofileSuccess(state, action) {
      state.loading = false;
      state.disprofile = action.payload;
      state.error = null;
    },
    disprofileFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    disorderRequest(state, action) {
      state.loading = true;
    },
    disorderSuccess(state, action) {
      state.loading = false;
      state.disorder = action.payload;
      state.error = null;
    },
    disorderFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    disproductRequest(state, action) {
      state.loading = true;
    },
    disproductSuccess(state, action) {
      state.loading = false;
      state.disproduct = action.payload;
      state.error = null;
    },
    disproductFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    disInventaryRequest(state, action) {
      state.loading = true;
    },
    disInventarySuccess(state, action) {
      state.loading = false;
      state.disInvent = action.payload;
      state.error = null;
    },
    disInventaryFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    setCurrentMenu(state, action) {
      state.currentMenuOption = action.payload;
      // storeData('currentMenuOption', action.payload);
    },
    setCurrentSubMenu(state, action) {
      state.currentSubMenuOption = action.payload;
      // storeData('currentSubMenuOption', action.payload);
    },
    disReturnRequest(state, action) {
      state.loading = true;
    },
    disReturnSuccess(state, action) {
      state.loading = false;
      state.disReturn = action.payload;
      state.error = null;
    },
    disReturnFail(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const { actions } = HomeReducer;
const config = {
  headers: {
    "Content-Type": "multipart/form-data",
  },
};

const config2 = {
  headers: {
    "Content-Type": "application/json",
  },
};
export const {
  MyOrderFail,
  MyOrderSuccess,
  MyOrderRequest,
  categoryRequest,
  setCurrentMenu,
  setCurrentSubMenu,
  categorySuccess,
  categoryFail,
  allorderRequest,
  allorderSuccess,
  allorderFail,
  allreturnRequest,
  allreturnSuccess,
  allreturnFail,
  loginRequest,
  loginSuccess,
  loginFail,
  signUpRequest,
  signUpSuccess,
  signUpFail,
  retailerprofileRequest,
  retailerprofileSuccess,
  retailerprofileFail,
  setSelectedDestributorID,
  addcartRequest,
  addcartSuccess,
  addcartFail,
  deletecartRequest,
  deletecartSuccess,
  deletecartFail,
  orderdetailsRequest,
  orderdetailsSuccess,
  orderdetailsSuccessNew,
  orderdetailsFailNew,
  orderdetailsFail,
  getCartRequest,
  getCartSuccess,
  getCartFail,
  getOfferRequest,
  getOfferSuccess,
  getOfferFail,
  setSelectedOffer,
  disloginRequest,
  disloginSuccess,
  disloginFail,
  disprofileRequest,
  disprofileSuccess,
  disprofileFail,
  disorderRequest,
  disorderSuccess,
  disorderFail,
  disproductRequest,
  disproductSuccess,
  disproductFail,
  disInventaryRequest,
  disInventarySuccess,
  disInventaryFail,
  disReturnRequest,
  disReturnSuccess,
  disReturnFail,
} = actions;

export const fetchgetcategoryDispatch = (bodyData) => async (dispatch) => {
  try {
    dispatch(categoryRequest());
    const { data } = await axiosInstance.get("/getcategory", config);
    dispatch(categorySuccess(data));
  } catch (error) {
    dispatch(
      categoryFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const fetchAllOrderDispatch = (bodyData) => async (dispatch) => {
  try {
    dispatch(allorderRequest());
    const { data } = await axiosInstance.get("/all_order", config);
    dispatch(allorderSuccess(data));
  } catch (error) {
    dispatch(
      allorderFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const loginDispatch = (bodyData, navigate) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const { data } = await axiosInstance.post(
      "/retailer_login",
      bodyData,
      config2
    );
    dispatch(loginSuccess(data));
    if (data.status) {
      localStorage.setItem("meddaily-main", JSON.stringify(data.data));
      sessionStorage.setItem("token", data.token);
      console.log("TOKEN", data.token);
      navigate("/home");
      dispatch(fetchretailerprofileDispatch());
    } else {
      // Swal.fire(
      //   'Please wait',
      //   '"Waiting for admin approval!',
      //   'error'
      // )
      alert(data.message);
    }
  } catch (error) {
    dispatch(
      loginFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

export const forGotPAss = (bodyData, navigate) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const { data } = await axiosInstance.post(
      "/forgot_password",
      bodyData,
      config
    );
    dispatch(loginSuccess(data));
    if (data.status) {
      localStorage.setItem("otp", data.otp);
      navigate("/retailer_forgot_setpassword");
      dispatch(fetchretailerprofileDispatch());
    } else {
      alert(data.message);
    }
  } catch (error) {
    dispatch(
      loginFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

export const getSearch = (bodyData, navigate) => async (dispatch) => {
  try {
    // dispatch(loginRequest());
    const { data } = await axiosInstance.get(
      "/product_search",
      {
        params: {
          field_name: bodyData.field_name,
          value: bodyData.value,
        },
      },
      config
    );
    // console.log(data)

    if (data.status) {
    } else {
      alert(data.message);
    }

    return data;
  } catch (error) {
    dispatch(
      loginFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

export const changeForGotPassword =
  (bodyData, navigate) => async (dispatch) => {
    try {
      dispatch(loginRequest());
      const { data } = await axiosInstance.post(
        "/update_password",
        bodyData,
        config
      );
      dispatch(loginSuccess(data));
      if (data.status) {
        localStorage.removeItem("otp");
        navigate("/retailer_login");
        dispatch(fetchretailerprofileDispatch());
      } else {
        alert(data.message);
      }
    } catch (error) {
      dispatch(
        loginFail(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        )
      );
    }
  };
export const disloginDispatch = (bodyData, navigate) => async (dispatch) => {
  try {
    dispatch(disloginRequest());
    const { data } = await axiosInstance.post(
      "/distributor_login",
      bodyData,
      config
    );
    dispatch(disloginSuccess(data));
    if (data.status) {
      localStorage.setItem("id", data.data._id); // Set the _id in localStorage
      localStorage.setItem("disToken", data?.token);
      console.log("first", data?.token);
      navigate("/distdashboard");
      dispatch(fetchdisprofileDispatch());
      dispatch(fetchdisproductDispatch());
      dispatch(fetchdisorderDispatch());
      dispatch(fetchdisInventaryDispatch());
    }
  } catch (error) {
    dispatch(
      disloginFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const fetchget_all_returnDispatch = (bodyData) => async (dispatch) => {
  try {
    dispatch(allreturnRequest());
    const configs = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    const { data } = await axiosInstance.get("/get_all_return", configs);
    dispatch(allreturnSuccess(data));
  } catch (error) {
    dispatch(
      allreturnFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

export const signUpDispatch = (bodyData) => async (dispatch) => {
  try {
    dispatch(signUpRequest());
    const { data } = await axiosInstance.post(
      "/retailer_register",
      bodyData,
      config
    );
    console.log("bodyData before request:", bodyData);
    console.log("Response from the server:", data);
    if (data.uploadedFiles) {
      console.log("Uploaded files:", data.uploadedFiles);
    }
    dispatch(signUpSuccess(data));

    if (data.status) {
      alert("Registration successful");
      setTimeout(() => {
        if (data.status) {
          window.location.href = "/retailer_login";
        }
      }, 1000);
    } else {
      alert(data.message);
    }
  } catch (error) {
    dispatch(
      signUpFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

export const signUpDistributorDispatch = (bodyData) => async (dispatch) => {
  try {
    dispatch(signUpRequest());
    const { data } = await axiosInstance.post(
      "/distributor_register",
      bodyData,
      config
    );
    dispatch(signUpSuccess(data));
    if (data.status) {
      alert(data.message);
      setTimeout(() => {
        if (data.status) {
          window.location.href = "/";
        }
      }, 1000);
    }
    // console.log(data);

    // callBack();
  } catch (error) {
    dispatch(
      signUpFail(
        alert(error.message),
        toaster.error(error.response && error.response.data.message),
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

export const fetchretailerprofileDispatch = (bodyData) => async (dispatch) => {
  try {
    dispatch(retailerprofileRequest());
    const configs = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    const { data } = await axiosInstance.get("/retailer_profile", configs);
    dispatch(retailerprofileSuccess(data));
  } catch (error) {
    dispatch(
      retailerprofileFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const fetchdisprofileDispatch = (bodyData) => async (dispatch) => {
  try {
    dispatch(disprofileRequest());
    const configs = {
      headers: {
        token: localStorage.getItem("disToken"),
      },
    };
    console.log("conf", configs);
    const { data } = await axiosInstance.get("/distributor_profile", configs);
    dispatch(disprofileSuccess(data));
  } catch (error) {
    dispatch(
      disprofileFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const addToCartDispatch = (bodyData, formdata) => async (dispatch) => {
  try {
    dispatch(addcartRequest());
    const configs = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    console.log(bodyData, "bodyData");
    const { data } = await axiosInstance.post(
      "/add_to_cart",
      bodyData,
      configs
    );
    console.log(data, "data");
    dispatch(addcartSuccess(data));
    dispatch(fetchgetOfferDispatch(formdata));
  } catch (error) {
    dispatch(
      addcartFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const delete_cartDispatch = (bodyData) => async (dispatch) => {
  try {
    console.log("bodyData", bodyData);
    dispatch(deletecartRequest());
    const configs = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    const { data } = await axiosInstance.delete(
      "/delete_cart",
      bodyData,
      configs
    );
    dispatch(deletecartSuccess(data));
  } catch (error) {
    dispatch(
      deletecartFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const fetchorderdetailsDispatch = (order_id) => async (dispatch) => {
  try {
    // console.log("Fetching order details...",order_id);
    // dispatch(orderdetailsRequest());
    // const configs = {
    //   headers: {
    //     token: sessionStorage.getItem("token"),
    //   },
    // };
    const payload = { order_id };
    const { data } = await axiosInstance.post(
      `/order_detail`,
      payload,
      config2
    );
    console.log("Fetched order details:", payload);
    console.log("Fetched order details:", data);
    dispatch(orderdetailsSuccess(data));
    return data;
  } catch (error) {
    dispatch(
      orderdetailsFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const fetchorderdetailsDispatchNew = (order_id) => async (dispatch) => {
  try {
    const config2 = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };

    const { data } = await axiosInstance.get(
      `/order_details?order_id=${order_id}`,
      config2
    );
    console.log("Fetched order details:");
    console.log("Fetched order details:", data);
    dispatch(orderdetailsSuccessNew(data));
    return data;
  } catch (error) {
    dispatch(
      orderdetailsFailNew(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const addReturnDetails = (bodyData) => async (dispatch) => {
  try {
    dispatch(orderdetailsRequest());
    const configs = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    const { data } = await axiosInstance.post(
      `/return_order`,
      bodyData,
      configs
    );
    dispatch(orderdetailsSuccess(data));
  } catch (error) {
    dispatch(
      orderdetailsFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

export const downloadInvoice = (bodyData) => async (dispatch) => {
  try {
    dispatch(orderdetailsRequest());
    const configs = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
      responseType: "blob",
    };
    const { data } = await axiosInstance.post(
      `/create_invoice`,
      bodyData,
      configs
    );
    dispatch(orderdetailsSuccess());

    const response = await axiosInstance.get(
      `/get_invoice`,
      { params: { order_id: bodyData.order_id } },
      configs
    );

    return response.data;
  } catch (error) {
    dispatch(
      orderdetailsFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

export const fetchgetCartDispatch = (setCartData) => async (dispatch) => {
  try {
    dispatch(getCartRequest());
    const configs = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    const { data } = await axiosInstance.get("/get_cart", configs);
    // console.log(data);
    dispatch(getCartSuccess(data));
    setCartData(data.data);
  } catch (error) {
    dispatch(
      getCartFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};

export const fetchgetMyOrderDispatch = () => async (dispatch) => {
  try {
    dispatch(MyOrderRequest());
    const configs = {
      headers: {
        token: sessionStorage.getItem("token"),
      },
    };
    const { data } = await axiosInstance.get("/my_order", configs);
    console.log("data", data);
    dispatch(MyOrderSuccess(data));
    // setMyOrderData(data.data);
    return data;
  } catch (error) {
    dispatch(
      MyOrderFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const fetchgetOfferDispatch = (formdata) => async (dispatch) => {
  try {
    dispatch(getOfferRequest());
    const { data } = await axiosInstance.get("/getoffer", formdata);
    dispatch(getOfferSuccess(data));
  } catch (error) {
    dispatch(
      getOfferFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const fetchdisorderDispatch = () => async (dispatch) => {
  try {
    dispatch(disorderRequest());
    const configs = {
      headers: {
        token: localStorage.getItem("disToken"),
      },
    };
    const { data } = await axiosInstance.get("/distributor_order", configs);
    dispatch(disorderSuccess(data));
  } catch (error) {
    dispatch(
      disorderFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const fetchdisproductDispatch = () => async (dispatch) => {
  try {
    dispatch(disproductRequest());
    const configs = {
      headers: {
        token: localStorage.getItem("disToken"),
      },
    };
    const { data } = await axiosInstance.get(
      "/distributor_get_product",
      configs
    );
    dispatch(disproductSuccess(data));
  } catch (error) {
    dispatch(
      disproductFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const fetchdisInventaryDispatch = () => async (dispatch) => {
  try {
    dispatch(disInventaryRequest());
    const configs = {
      headers: {
        token: localStorage.getItem("disToken"),
      },
    };
    const { data } = await axiosInstance.get("/my_inventory", configs);
    dispatch(disInventarySuccess(data));
  } catch (error) {
    dispatch(
      disInventaryFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export const fetchdisreturnReq = () => async (dispatch) => {
  try {
    dispatch(disReturnRequest());
    const configs = {
      headers: {
        token: localStorage.getItem("disToken"),
      },
    };
    const { data } = await axiosInstance.get("/get_demo_file", configs);
    dispatch(disReturnSuccess(data));
  } catch (error) {
    dispatch(
      disReturnFail(
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message
      )
    );
  }
};
export default HomeReducer;

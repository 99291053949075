import React, { useState } from 'react';
import axios from 'axios';
import toastr from 'toastr';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../image/MEDDAILY-LOGO-inverted.png';
import { useDispatch } from 'react-redux';
import { disloginDispatch } from '../reducers/HomeReducer';
import LoaderModal from '../components/resuableComponents/LoaderModal';
// import config from "../appConfig";

export default function DisLogin() {
	const [isSubmitting,setIsSubmitting]=useState(false)
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const [phone, setPhone] = useState('');
	const [password, setpassword] = useState('');

	const handleChangePhone = (e) => {
		setPhone(e.target.value);
	};
	const handleChangePassword = (e) => {
		setpassword(e.target.value);
	};

 function login() {
		setIsSubmitting(true)
		if (phone === '') {
			return toastr.warning('Please enter credentials');
		}
		if (password === '') {
			return toastr.warning('Please enter credentials');
		}
		const reqBody = {
			phone: phone,
			password: password,
		};
	
		setTimeout(() => {
			dispatch(disloginDispatch(reqBody, navigate),setIsSubmitting(false));
		}, 2000);
		
	}

	return (
		<>
			<div className='container-xxl' style={{ width: '45rem' }}>
				<div className='authentication-wrapper authentication-basic container-p-y'>
					<div className='authentication-inner'>
						<div className='card'>
							<div className='card-body'>
								<div
									className='app-brand justify-content-center'
									style={{ padding: '1rem .5rem' }}>
									<Link to='index.html' className='app-brand-link gap-2'>
										<img
											src={logo}
											alt='user'
											style={{ height: '120px', width: '300px' }}
										/>
									</Link>
								</div>

								<div id='formAuthentication' className='mb-3'>
									<div className='mb-3'>
										<label htmlFor='email' className='form-label float-start'>
											Email or Username /distributor login
										</label>
										<input
											type='text'
											className='form-control'
											id='phone'
											name='phone'
											placeholder='Enter your email or username'
											onChange={handleChangePhone}
											autoFocus
										/>
									</div>
									<div className='mb-3 form-password-toggle'>
										<div className='d-flex justify-content-between'>
											<label className='form-label' htmlFor='password'>
												Password
											</label>
										</div>
										<div className='input-group input-group-merge'>
											<input
												type='password'
												id='password'
												className='form-control'
												name='password'
												placeholder='&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;&#xb7;'
												onChange={handleChangePassword}
												aria-describedby='password'
											/>
											<span className='input-group-text cursor-pointer'>
												<i className='bx bx-hide'></i>
											</span>
										</div>
									</div>
									<div className='mb-3'>
										<div className='form-check'>
											<input
												className='form-check-input'
												type='checkbox'
												id='remember-me'
											/>
											<label className='form-check-label' htmlFor='remember-me'>
												{' '}
												Remember Me{' '}
											</label>
										</div>
									</div>
									<div className='mb-3'>
										<button
											className='btn d-grid w-100'
											type='submit'
											onClick={login}
											style={{ background: '#6eafab', color: 'white' }}>
											Sign in
										</button>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
			</div>
			{isSubmitting?<LoaderModal/>:""}
		</>
	);
}


import { Box, Button, Grid, Typography, Card, Avatar } from "@mui/material";
import Body from "../components/Body";
import NavbarLanding from "../components/NavbarLanding";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Footer from "../components/footer";
import google from "../image/google.png";
import app from "../image/app.png";
import mobile from "../image/phone.svg";
import LandingBody from "../components/LandingBody";

function Landing() {
  return (
    <>

      <NavbarLanding />

      <LandingBody />

      <Footer />

    </>
  );
}
export default Landing;

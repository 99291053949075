import React from 'react';
import { CircularProgress } from '@mui/material';
const LoaderModal = () => {
	return (
		<div
			style={{
				position: 'fixed',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				backgroundColor: '#F8F8F8AD',
			}}>
			<div
				style={{ left: '50%', top: '50%', zIndex: 1000, position: 'absolute' }}>
				<div className='spinner'></div>
				<CircularProgress color='secondary' />
			</div>
		</div>
	);
};

export default LoaderModal;

import React from "react";
import { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  IconButton,
  Divider,
  Autocomplete,
  Input,
} from "@mui/material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Swal from 'sweetalert2'

const ReturnInitiate = (props) => {
  console.log("hio",props)
  var [returnAmount, setreturnAmount] = useState(0);
  var [returnQty, setreturnQty] = useState(0);


  function sweetAlert(){
    Swal.fire('Return Initiated','You can now close this window','success')
  }
 
  return (
    <>
      <Box
        position="absolute"
        top="15%"
        left="35%"
        width={600}
        height={600}
        overflow="scroll"
        bgcolor="background.paper"
        border="2px solid #fff"
        borderRadius={2}
        boxShadow={24}
        p={4}
      >
        <Box
          display="flex"
          columnGap={18}
          boxShadow="0px 4px 4px -2px rgba(0, 0, 0, 0.3)"
        >
          <Box display="flex" alignItems="center">
            <IconButton disableRipple onClick={props.handleClose}>
              <ArrowBackRoundedIcon
                fontSize="large"
                sx={{ color: "#212121" }}
              />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              color="#212121"
              textAlign="center"
            >
              Return Initiate
            </Typography>
          </Box>
        </Box>
        <Box
          mt={0.5}
          p={2}
          className="retun_model_des"
          boxShadow="0px 4px 4px -2px rgba(187, 222, 251,0.5)"
        >
          <Box>
            <Typography id="modal-modal-description">
              Order ID: {props.orderDetails?.data?.order_id}
            </Typography>
          </Box>
          <Box>
            <Typography>
              Created: {props.orderDetails?.data?.createdAt}
            </Typography>
          </Box>
        </Box>

        {[0]?.map((_, index) => (
          <Box
            my={2}
            key={index}
            py={2}
            px={4}
            columnGap={4}
            borderRadius={2}
            border="2px solid rgba(187, 222, 251,0.8)"
          >
            <Box display="flex" justifyContent="space-between">
              <Box width="50%" textAlign="start">
                <Typography
                  fontWeight={500}
                  color="#212121"
                  id="modal-modal-description"
                >
                  Product name: {props.orderDetails?.data?.products[0].name}
                </Typography>
              </Box>
              <Box width="50%" textAlign="end">
                <Typography id="modal-modal-description">
                  Total Price: Rs. {props.orderDetails?.data?.price}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Box width="50%" textAlign="start">
                <Typography id="modal-modal-description">{props?.orderDetails?.data?.products[0].name}</Typography>
              </Box>
              <Box width="50%">
                <Typography id="modal-modal-description" textAlign="end">
                  P.Qty- {props.orderDetails?.data?.bonus_quantity}
                </Typography>
              </Box>
            </Box>
            <Box display="flex" mt={3} justifyContent="space-between">
              <Box width="50%" textAlign="start">
                <Typography
                  fontWeight={500}
                  color="#212121"
                  id="modal-modal-description"
                >
                  Enter return Qty
                </Typography>
              </Box>
              <Box width="50%">
                <Typography id="modal-modal-description" textAlign="end">
                  <input
                    type="number"
                    placeholder="Enter quantity"
                    onChange={e=>{
                      if((e.target.value)<= props.orderDetails?.data?.bonus_quantity && e.target.value>0){
                        setreturnAmount((props?.orderDetails?.data?.products[0].price*1 * e.target.value)+(props?.orderDetails?.data?.deliveryCost*1))
                      }
                        setreturnQty(e.target.value)
                      
                     
                      }}
                    minLength={props.orderDetails?.data?.bonus_quantity}
                  />
                </Typography>
              </Box>
            </Box>
          </Box>
        ))}

        <Box my={2} py={2} px={4} columnGap={4} borderRadius={2}>
          <Typography
            id="modal-modal-description"
            variant="h5"
            color="#212121"
            fontWeight={600}
          >
            Estimated return Value
          </Typography>

          <Box mt={3} display="flex" justifyContent="space-between">
            <Box>
              <Typography
                variant="h6"
                textAlign="center"
                id="modal-modal-description"
              >
                Total Amount:&nbsp;
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h6"
                color="#4ab5aa"
                textAlign="center"
                id="modal-modal-description"
              >
                Rs. {props.orderDetails?.data?.price}
              </Typography>
            </Box>
          </Box>

          <Box my={1} display="flex" justifyContent="space-between">
            <Box>
              <Typography
                variant="h6"
                textAlign="center"
                id="modal-modal-description"
              >
                Returned Amount:&nbsp;
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h6"
                color="#4ab5aa"
                textAlign="center"
                id="modal-modal-description"
              >
                Rs. {returnAmount.toFixed(2)}
              </Typography>
            </Box>
          </Box>
          <Box my={1} display="flex" justifyContent="space-between">
            <Box>
              <Typography
                variant="h6"
                textAlign="center"
                id="modal-modal-description"
              >
                Delivery Amount:&nbsp;
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h6"
                color="#4ab5aa"
                textAlign="center"
                id="modal-modal-description"
              >
                Rs. {props?.orderDetails?.data?.deliveryCost.toFixed(2)}
              </Typography>
            </Box>
          </Box>

          <Box mt={6} mb={2} height={0.05} fullWidth>
            <Divider light />
          </Box>
          <Box mb={4} display="flex" justifyContent="space-between">
            <Box>
              <Typography
                variant="h6"
                color="#212121"
                textAlign="center"
                id="modal-modal-description"
              >
                Est Total Return:&nbsp;
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h6"
                color="#212121"
                textAlign="center"
                id="modal-modal-description"
              >
                Rs. {returnAmount.toFixed(2)}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column" rowGap={1}>
          <Box>
            <Typography fontWeight={600} color="#4ab5aa">
              Returning Reason:
            </Typography>
          </Box>
          <Box>
            <FormControl fullWidth>
              <Autocomplete
                sx={{
                  boxShadow: "0px 4px 4px -2px rgba(0, 0, 0, 0.3)",
                  backgroundColor: "rgba(231, 255, 204, 0.8)",
                }}
                size="small"
                disablePortal
                id="demo-simple-select"
                options={[
                  {
                    label: "Expire Product",
                    value: "Expire Product",
                  },
                  {
                    label: "Mismatched Product",
                    value: "Mismatched Product",
                  },
                ]}
                renderInput={(params) => (
                  <TextField {...params} onChange={props.handleChange} />
                )}
              />
            </FormControl>
          </Box>
        </Box>

        <Box my={2} display="flex" flexDirection="column" rowGap={1}>
          <Box>
            <Typography fontWeight={600} color="#4ab5aa">
              Additional Notes (optional):
            </Typography>
          </Box>
          <FormControl>
            <Input
              sx={{
                backgroundColor: "#e3f2fd",
                padding: 2,
                borderRadius: 2,
              }}
              onChange={props.handleChangeNotes}
              multiline
              placeholder="Enter notes..."
              rows={5}
              width="100%"
            />
          </FormControl>
        </Box>

        <Box my={2} display="flex" flexDirection="column" rowGap={1}>
          <Box>
            <Typography fontWeight={600} color="#4ab5aa">
              Additional Files (optional):
            </Typography>
          </Box>
          <Box
            borderRadius={1.25}
            bgcolor="#e3f2fd"
            m={2}
            padding={2.5}
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            onChange={props.handleChangeFiles}
          >
            <Box>
              <CloudUploadIcon
                sx={{
                  backgroundColor: "inherit",
                  color: "#4ab5aa",
                  fontSize: "50px",
                }}
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="space-evenly"
            >
              <Typography
                variant="h6"
                fontWeight={500}
                color="#212121"
                textAlign="center"
                mb={1}
              >
                Upload file
              </Typography>
              <Typography variant="subtitle2">
                Supported Formats: Pdf, Jpeg, Png upto 10mb
              </Typography>
            </Box>
            <Box>
              <Input
                display="flex"
                justifyContent="center"
                type="file"
                accept=".pdf, jpeg, .png"
                sx={{ cursor: "pointer" }}
                onChange={props.handleChangeFiles}
              />
            </Box>
          </Box>
        </Box>

        {returnQty <= props?.orderDetails?.data?.bonus_quantity && returnQty>0? (
 <Box mt={2} display="flex" justifyContent="center">
 <Button
   disableElevation
   disableRipple
   sx={{
     backgroundColor: "#4ab5aa",
     color: "#fff",
     ":hover": {
       backgroundColor: "#43a399",
     },
   }}
   onClick={(event) => (
       props.continueToSubmit(event, returnAmount,returnQty), props.handleClose() , sweetAlert()
     )}
 >
   Continue&nbsp;
 </Button>
</Box>
        ):""}

       
      </Box>
    </>
  );
};

export default ReturnInitiate;

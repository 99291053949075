import logo from "../image/catalog/logonew.jpg";
import cartIcon from "../image/cart.png";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { Link, useNavigate } from "react-router-dom";

import "../css/navbar.css";
import { useEffect, useState } from "react";
import axios from "axios";
import { Menu, MenuItem, IconButton, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";

const Navbar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const { retailerProfile } = useSelector((state) => state.home);

  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   sessionStorage.removeItem("token");
  //   navigate("/");
  // }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDashboard = (e) => {
    navigate("/dashboard");
  };
  const handleLogout = (e) => {
    sessionStorage.removeItem("token");
    // sessionStorage.removeItem('loginUser')
    navigate("/");
  };
  const handleSetting = (e) => {
    navigate("/");
  };

  const [count, setCount] = useState();

  useEffect(() => {
    getCartCount();
  }, []);

  const getCartCount = () => {
    axios
      .get("https://api.meddaily.in/get_cart", {
        headers: { "token": sessionStorage.getItem("token") },
      })
      .then((response) => {
        setCount(response.data);
        // setCartCount((prevCount) => prevCount + cartQuantity);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="navbar2">
        <div className="logo-div">
          <Link to={"/home"}>
            <img src={logo} alt="logo" width="200px" />
          </Link>
        </div>

        <div className="search-box2">
          <div className="select">
            <select className="form-select2">
              <option selected>Generic</option>
              <option value="1">Generic</option>
              <option value="2">OTC</option>
              <option value="3">Surgical</option>
              <option value="4">Branded</option>
            </select>
          </div>
          <div>
            <input
              type="text"
              className="input-field2"
              placeholder="Search for medicine , distributor etc..."
            />
          </div>
        </div>

        <div className="btn-div2">
          <Link to="/ordersummary">
            <button className="btn-cart">
              <div className="div-cart-btn">
                <div className="cart_logo_div">
                  <img
                    src={cartIcon}
                    className="cart"
                    alt="cart"
                    width="30px"
                  />
                  {/* <ShoppingCartIcon className='cart' /> */}
                </div>
              </div>
            </button>
          </Link>

          {/* <Link to="/retailer_login">
            <button className="btn-login">Login</button>
          </Link> */}

          <IconButton
            className="profile-icon1"
            onClick={handleClick}
            aria-controls="profile-menu"
            aria-haspopup="true"
            sx={{ color: "#ffff", fontSize: "4rem" }}
          >
            <AccountCircleIcon />
            <span style={{ fontSize: 16 }}>
              {retailerProfile?.data?.ownername}
            </span>
          </IconButton>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleDashboard}>My Profile</MenuItem>
            <MenuItem onClick={handleSetting}>Settings</MenuItem>
            {/* <MenuItem onClick={() => handleLogout()}>Logout</MenuItem> */}
          </Menu>
        </div>
      </div>
    </>
  );
};

export default Navbar;

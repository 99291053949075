import { Typography } from "@mui/material";
import "../css/card.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { useEffect, useState } from "react";
import { axiosInstance } from "../Axios";

const ProductCard = ({ searchQuery ,sortType,distributorName,distributorId}) => {

  const navigate = useNavigate();
  const { categoryId } = useParams();
  const [cardData, setCardData] = useState([]);
  const [distributorData, setDistributorData] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       let response;

  //       if (categoryId) {
  //         response = await axiosInstance.post(
  //           "/category_product",
  //           {
  //             title: searchQuery,
  //             category_id: categoryId,
  //           },
  //           { headers: { token: sessionStorage.getItem("token") } }
  //         );
  //       } else if (distributorId) {
  //         console.log(distributorId,">>>>>>>>>>>>>>>>>>>>>>>>ID>>>>>>>>>>>>");
  //         const distributorResponse = await axiosInstance.post('/get_productss', {
  //           distributorId: distributorId,
  //         });

  //         const { status, message, data } = distributorResponse.data;

  //         if (status) {
  //           console.log('Distributors found successfully:', data);
  //           setCardData(data);
  //         } else {
  //           console.error('Error:', message);
  //         }

  //         return; // Exit the function early when distributor data is fetched
  //       }

  //       if (sortType && response?.data.product) {
  //         const sortedCopy = [...response.data.product];
  //         if (sortType === "1") {
  //           sortedCopy.sort((a, b) => a.price - b.price);
  //         } else if (sortType === "2") {
  //           sortedCopy.sort((a, b) => b.price - a.price);
  //         }
  //         setCardData(sortedCopy);
  //       } else {
  //         setCardData(response?.data.product || []);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching product data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [searchQuery, sortType, distributorName, distributorId, categoryId]);
  useEffect(() => {
    const fetchProducts = async () => {
            setCardData([])
            try {
              let response;
              // if (searchQuery) {
                response = await axiosInstance.post(
                  "/category_product",
                  {
                    title: searchQuery,
                    category_id: categoryId,
                    // distributorName: distributorName
                  },
                  { headers: { token: sessionStorage.getItem("token") } }
                );
              if (sortType && response.data.product) {
                const sortedCopy = [...response.data.product];
                if (sortType === "1") {
                  sortedCopy.sort((a, b) => a.price - b.price);
                } else if (sortType === "2") {
                  sortedCopy.sort((a, b) => b.price - a.price);
                }
                setCardData(sortedCopy);
              } else {
                setCardData(response.data.product);
              }
                    console.log(cardData,"response");
                    
                  } catch (error) {
                    console.error("Error fetching product data:", error);
                  }
                };
   

  const getdatadisid = async () => {
  try {
   
   console.log(distributorId,">>>>>>ID>>>>>>>>>>>");
    const response = await axiosInstance.post('/get_productss', {
      distributorId: distributorId,
      productName:searchQuery
    });
    
    const { status,message } = response.data;
    
    if (status) {
      console.log('Distributors found successfully:', response.data.data);
      setDistributorData(response.data.data)
      if (sortType && response.data.data) {
        const sortedCopy = [...response.data.data];
        if (sortType === "1") {
          sortedCopy.sort((a, b) => a.price - b.price);
        } else if (sortType === "2") {
          sortedCopy.sort((a, b) => b.price - a.price);
        }
        setDistributorData(sortedCopy)
      }
    } else {
      console.error('Error:', message);
    }
  } catch (error) {
    console.error('API Error:', error);
  }
}
getdatadisid()
         fetchProducts();
  }, [searchQuery,sortType,distributorName,distributorId,categoryId]);
  

  // const onProductClick = (id) => {
  //   navigate(`/ordersummary/${id}`);
  // }

  const onProductClick = (id) => {
    navigate(`/details/${id}`);
  }

  return (
    <>
    {distributorId ?(   
    distributorData.map((item) => (

<div className="card">
  <Typography variant="h6" style={{fontSize:"20px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
    {item?.name} <br></br>
  </Typography>

  
  <Typography mt={3} style={{fontSize:"12px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
    {item?.subtitle}
    </Typography>

  <Typography mt={3} mb={2} variant="h6" style={{fontSize:"18px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
    Best Price* <span style={{ color: "#30d5c8" }}>₹ {item?.price} </span>
  </Typography>

  <button className="buy-now-btn" onClick={() => onProductClick(item?._id)}> Buy Now</button>
</div>
))
    ):( 

      cardData.map((item) => (

        <div className="card">
          <Typography variant="h6" style={{fontSize:"20px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {item?.name} <br></br>
          </Typography>
        
          
          <Typography mt={3} style={{fontSize:"12px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            {item?.subtitle}
            </Typography>

          <Typography mt={3} mb={2} variant="h6" style={{fontSize:"18px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
            Best Price* <span style={{ color: "#30d5c8" }}>₹ {item?.price} </span>
          </Typography>

          <button className="buy-now-btn" onClick={() => onProductClick(item?._id)}> Buy Now</button>
        </div>
      ))
    )}
 
   
    </>
  );
};
export default ProductCard;
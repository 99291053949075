import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import login_image from "../image/bg.svg";
import med_logo from "../image/MEDDAILY-LOGO-inverted.png";
import play_app_icon from "../image/catalog/app.png";
import "../css/login.css";
import { Box, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { loginDispatch , forGotPAss} from "../reducers/HomeReducer";
import LoaderModal from "../components/resuableComponents/LoaderModal";

const logInSchema = Yup.object().shape({
  email: Yup.string()
    ,
 
});

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.home);
  const [data, setData] = useState(false);
  const [step1, setStep1] = useState(1);

  // console.log('login', login.status);

  // const [isMessage, setIsMessage] = useState(null);
  // const [loginData, setLoginData] = useState({
  // 	phone: '',
  // 	password: '',
  // });

  // const handleChange = (e) => {
  // 	const { id, value } = e.target;
  // 	setLoginData((prevData) => ({
  // 		...prevData,
  // 		[id]: value,
  // 	}));
  // };

  // const handleLogin = (e) => {
  // 	axios
  // 		.post('https://api.meddaily.in/retailer_login', loginData)
  // 		.then((res) => {
  // 			if (res?.data?.status) {
  // 				sessionStorage.setItem('key', JSON.stringify(res.data));
  // 				sessionStorage.setItem('token', res.data.token);
  // 				navigate('/home');
  // 			} else {
  // 				alert('Invalid Credentials!');
  // 				// navigate('/login')
  // 			}
  // 		})
  // 		.catch((error) => {
  // 			console.log(error);
  // 		});
  // };

  return (
    <>
      <div className="login-body">
        <div className="big-img-div">
          <img src={login_image} alt="login" className="img-login" />
        </div>

        <div className="form-div-1">
          <Link style={{ textDecoration: "none" }} to="/">
            <img src={med_logo} alt="company_logo" width="250" height="100" />
          </Link>

          <div className="form">
            <Typography variant="h4" mt={8}>
              <strong>Forgot Password</strong>
            </Typography>
            <Typography color="#2D3748" mt={2} mb={4}>
              For Retailers.
            </Typography>

            <div className="div-for-data">
              <Formik
                initialValues={{
                  email: "",
                }}
                validationSchema={logInSchema}
                onSubmit={(values) => {
                  dispatch(forGotPAss(values, navigate));
                




                  console.log("login", values);
                }}
              >
                {(formik) => {
                  const { errors, touched, isValid, dirty } = formik;
                  return (
                    <Form>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        sx={{
                          flexWrap: {
                            xs: "wrap",
                            sm: "no-wrap",
                            md: "no-wrap",
                            lg: "no-wrap",
                          },
                        }}
                      >
                        <div>
                          <Typography>
                            <strong>Email</strong>
                          </Typography>
                          <Field
                            type="text"
                            name="email"
                            style={{
                              height: "45px",
                              marginTop: "15px",
                              width: "15rem",
                              borderRadius: "10px",
                              // border: 'none',
                              // outline: 'none',
                              borderColor: errors.email ? "red" : "",
                            }}
                            placeholder="Email "
                            // className='form-data'
                            id="phone"
                            // onChange={handleChange}
                            // value={loginData.phone}
                            className="form-control"
                          />

                          {errors.email ? (
                            <span style={{ color: "red" }}>{errors.email}</span>
                          ) : null}
                        </div>

                      </Box>
                      <div className="create-acc-div">
                        <button className="register-button" type="submit">
                          Generate OTP
                        </button>
                        <Typography mt={2} mb={6}>
                          Don you have an account?{" "}
                          <Link to="/retailer_login">Sign In</Link>
                        </Typography>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
          <img src={play_app_icon} alt="logo" className="play_app_icon" />
        </div>
      </div>
      {loading ? <LoaderModal /> : ""}
    </>
  );
};

export default Login;

import { Typography } from '@mui/material';
import privacy_image from '../image/privacy.jpeg';
import Navbar from '../components/Navbar';
import Footer from '../components/footer';
import '../css/privacy.css';
const PrivacyPolicy = () => {
	return (
		<>
			<Navbar />
			<div className='image-privacy-div'>
				<img
					src={privacy_image}
					alt='privacy_img'
					width='100%'
					height='400'
					className='privacy-img'
				/>
				<div className='centered'>
					<Typography variant='h2'> Privacy Policy</Typography>
					<Typography variant='h4'> Last Modified : Dec 2022 </Typography>
				</div>
			</div>
			<div className='privacy-div'>
				<div className='intro-div'>
					<Typography mb={4} variant='h4'>
						Introduction
					</Typography>
					<Typography fontSize='16px' variant='p'>
						Your visit to this Website (defined below) is subject to this
						Privacy Policy and Terms of Use available at www.meddaily.in This
						website www.meddaily.in and all the web–pages, hyperlinks and tools
						provided thereunder (the “Website”) is owned by M/s.
						Meddaily.in (“our”, “us”, “we”,“firm”). We value your trust and
						respect your privacy. Unless otherwise defined in this Privacy
						Policy, terms used in this Privacy Policy have the same meanings as
						in our Terms of Use. This Privacy Policy provides you with details
						about the manner in which your data is collected, stored and used by
						us. You are advised to read this Privacy Policy carefully.
					</Typography>{' '}
					<br />
					<br />
					<Typography mb={4} variant='h6'>
						BY VISITING OUR WEBSITE, YOU EXPRESSLY GIVE US CONSENT TO USE AND
						DISCLOSE YOUR PERSONAL INFORMATION IN ACCORDANCE WITH THIS PRIVACY
						POLICY. IF YOU DO NOTAGREE TO THE TERMS OF THE POLICY, PLEASE DO NOT
						USE OR ACCESS OUR WEBSITE.
						<br />
						<Typography fontSize='16px' variant='p'>
							This Privacy Policy is published by us, in compliance of:
						</Typography>
						<br />
						<Typography fontSize='15px' variant='p'>
							<ul>
								<li>
									{' '}
									Section 43A of the Information Technology Act, 2000; and
								</li>
								<li>
									Regulation 4 of the Information Technology (Reasonable
									Security Practices and Procedures and Sensitive Personal
									Information) Rules, 2011.
									<br />
									<Typography fontSize='10px' variant='h6'>
										General{' '}
									</Typography>
									<Typography fontSize='16px' variant='p'>
										We will not sell, share or rent your personal information to
										any third party or use your email address/mobile number for
										unsolicited emails and/or SMS. Any emails and/or SMS sent by
										us will only be in connection with the provision of agreed
										Services, Products and this Privacy Policy. To carry out
										general marketing we would be sending out emails to users
										registered on our Website (or by any means and in any media,
										including, but not limited to, on our Website or through our
										merchants or in advertisements / marketing campaigns / any
										form of audio or visual media or websites). We reserve the
										right to communicate your personal information to any third
										party that makes a legally compliant request for its
										disclosure.
									</Typography>
									<Typography fontSize='10px' variant='h6'>
										Collection of Information
									</Typography>
									<Typography fontSize='16px' variant='p'>
										In order to provide our Services, we may collect the
										following types of information:
									</Typography>
								</li>
								<Typography fontSize='15px' variant='h5'>
									User information –{' '}
									<Typography fontSize='16px' variant='p'>
										When you use the Services, we automatically receive and
										record information from your applicable browser. Such
										information may include your contacts, the location of your
										device, text messages, your device storage, your IP address,
										cookie information, software and hardware attributes,
										contacts, and unique device ID. Most of the information we
										collect during your use of our Website, such as your device
										and hardware IDs and device type, the content of your
										request, and basic usage stats about your device and general
										use of our Services does not by itself identify you to us,
										though it may be unique or consist of or contain information
										that you consider personal. Specifically, we collect, retain
										and use information collected, retained and shared through
										Facebook platform, Twitter, Google, and YouTube, according
										to the applicable terms and conditions of their privacy
										policies. You are not ordinarily required to register or
										provide personal information in order to access the Website,
										though certain functionalities such as a membership program,
										through which purchasers of products may receive discounts
										on online purchases, participating in any contests, etc.,
										may require registration.
									</Typography>
								</Typography>
								<Typography fontSize='15px' variant='h5'>
									User ID –{' '}
									<Typography fontSize='16px' variant='p'>
										In order to avail the Services, you shall have to sign up on
										the Website.
									</Typography>
								</Typography>
								<Typography fontSize='15px' variant='h5'>
									User history and preferences –{' '}
									<Typography fontSize='16px' variant='p'>
										We save your query terms as well as the experience/category
										selected, these may be associated with the unique User ID of
										your device. We also save your preferences as recorded
										through your use of the Services and use them for
										personalization features.
									</Typography>
								</Typography>

								<Typography fontSize='15px' variant='h5'>
									Cookies –{' '}
									<Typography fontSize='16px' variant='p'>
										When you use our Services, we may send one or more cookies.
										We use cookies to improve the quality of our service by
										storing user preferences and tracking user information.
										Certain of our Birkenstock Products and Services allow you
										to download and/or personalize the content you receive from
										us. For Birkenstock Products and Services, we will record
										information about your preferences, along with any
										information you provide yourself.
									</Typography>
								</Typography>
							</ul>
							<Typography fontSize='15px' variant='h5'>
								Use of Information
							</Typography>
							<ul>
								<li>
									The personal information we collect allows us to keep you
									posted on our latest announcements, upcoming events, including
									confirmations, security alerts and support, and administrative
									messages. It also helps us to improve our Services. If you do
									not want to be on our mailing list, you can write us a mail at
									___________________________mentioning your preferences.
								</li>
								<li>
									From time to time, we may use your personal information to
									send important notices, such as communications and changes to
									our terms, conditions, and policies. Since this information is
									important to your interaction with us, you cannot opt-out of
									receiving these communications.
								</li>
								<li>
									We may also use personal information for internal purposes
									such as data analysis and research to improve our Services,
									Products and customer communication.
								</li>
								<li>
									We collect non-personally identifiable information about you
									generally to improve the features and functionality of the
									Website. We may analyse trends through aggregated demographic
									and psychographic offers to you. We may also share this
									non-personally identifiable information with our partners to
									allow them to assist us in delivering tailored advertisements,
									promotions, deals, discounts, and other offers to you when you
									use the Services.
								</li>
								<li>
									We may also collect information about you through other
									methods, including research surveys or information we obtain
									from third parties, including verification services, data
									services, as well as public sources.
								</li>
							</ul>
							<Typography fontSize='15px' variant='h6'>
								Sharing of Personal Information
							</Typography>
							<Typography fontSize='15px' variant='p'>
								We only share personal information with other companies or
								individuals in the following limited circumstances:
							</Typography>
							<ul>
								<li>
									We may share with third parties the location of your device in
									order to improve and personalize your experience of the
									Services.
								</li>
								<li>
									We may share with third parties, certain pieces of
									non-personal information, such as the number of users who used
									a particular Service, users who clicked on a particular
									advertisement or who skipped a particular advertisement, as
									well as similar aggregated information. Such information does
									not identify you individually.
								</li>
								<li>
									We provide such information to trusted businesses or persons
									for the purpose of processing personal information on our
									behalf. We require that these parties agree to process such
									information based on our instructions and in compliance with
									this Privacy Policy and any other appropriate confidentiality
									and security measures.
								</li>
								<li>
									Advertisers: We allow advertisers and/or merchant partners
									(“Advertisers”) to choose the demographic information of users
									who will see their advertisements and/or promotional offers
									and you agree that we may provide any of the information we
									have collected from you in non- personally identifiable form
									to an Advertiser, in order for that Advertiser to select the
									appropriate audience for those advertisements and/or offers.
								</li>
								<li>
									If you sign through a third-party social networking site or
									service, your list of “friends” from that site or service may
									be automatically imported to the Website and or Service.
									Again, we do not control the policies and practices of any
									other third-party site or service.
								</li>
								<br />
								<br />
								<Typography fontSize='13px' variant='h6'>
									Security
								</Typography>
								<Typography fontSize='16px' variant='p'>
									The security of your personal information is important to us.
									We take precautions, including administrative, technical and
									physical measures to safeguard your personal information
									against loss, theft, and misuse, as well as against
									unauthorized access, disclosure, alteration and destruction.
									When you enter sensitive information on our registration, we
									encrypt that information. This Website has various electronic,
									procedural, and physical security measures in place to protect
									the loss, misuse, and alteration of information, or any
									accidental loss, destruction or damage to data. When you
									submit your information via the Website, your information is
									protected through our security systems.
								</Typography>
								<br />
								<br />
								<Typography fontSize='13px' variant='h6'>
									Accessing Third-Party Websites
								</Typography>
								<Typography fontSize='16px' variant='p'>
									Website, mobile site and mobile app may contain links to other
									websites. Please note that when you click on one of these
									links, you are entering another website for which the Company
									has no responsibility. We encourage you to read the privacy
									statements of all such sites as their policies may be
									materially different from this Privacy Policy. Of course, you
									are solely responsible for maintaining the secrecy of your
									passwords, and your membership account information. Please be
									very careful, responsible, and alert with this information,
									especially whenever you are online.
								</Typography>
								<br />
								<br />
								<Typography fontSize='13px' variant='h6'>
									Protection of Children
								</Typography>
								<Typography fontSize='16px' variant='p'>
									If you are a user under the age of 18 (“Child User”), please
									do not access the Website. We also reserve the right to
									remove, at our sole discretion, any content or material posted
									by a user which may be of defamatory or sexual nature or
									offensive to other users in any manner, and take appropriate
									legal action against such user in accordance with our Terms of
									Use.
								</Typography>
								<br />
								<br />
								<Typography fontSize='13px' variant='h6'>
									Cookies
								</Typography>
								<Typography fontSize='16px' variant='p'>
									A cookie is a chunk of data that is sent to the website from a
									server and stored on the hard drive of your computer and/or
									mobile (“Cookies”). A session Cookie disappears automatically
									after you close your browser. A persistent Cookie remains
									after you close your browser and may be used by your browser
									on subsequent visits to the Website. We may use Cookies
									related information to: (a) remember your data and/or personal
									information so that you will not have to re-enter it during
									your visit or the next time you access the Services; (b)
									provide customized third-party advertisements, content and
									information; (c) monitor the effectiveness of third-party
									marketing campaigns; (d) monitor aggregate site usage metrics
									such as total number of visitors and pages viewed; and (e)
									track your entries, submissions and status in any promotions
									or other activities. We may allow third-party service
									providers, like advertisers, to place and read their own
									Cookies, web beacons and similar technologies to collect data
									and/or personal information. This data and/or personal
									information are collected directly and automatically by these
									third parties, and we do not participate in these data
									transmissions and these third-party cookies are not covered
									under this Privacy Policy.
								</Typography>
								<br />
								<br />
								<Typography fontSize='13px' variant='h6'>
									Changes to the Privacy Policy
								</Typography>
								<Typography fontSize='16px' variant='p'>
									We reserve the right to change the Privacy Policy at our sole
									discretion. We will inform users of any such change by us
									posting the updated Privacy Policy on our Website. We
									encourage you to review this Privacy Policy regularly for any
									such changes. You can determine when we last modified the
									Privacy Policy by referring to the “Last Updated” legend
									below. Your continued use of the Services will be subject to
									the then-current Privacy Policy.
								</Typography>

								<br />
								<br />
								<Typography fontSize='13px' variant='h6'>
									Changes to the Privacy Policy
								</Typography>
								<Typography fontSize='16px' variant='p'>
									We reserve the right to change the Privacy Policy at our sole
									discretion. We will inform users of any such change by us
									posting the updated Privacy Policy on our Website. We
									encourage you to review this Privacy Policy regularly for any
									such changes. You can determine when we last modified the
									Privacy Policy by referring to the “Last Updated” legend
									below. Your continued use of the Services will be subject to
									the then-current Privacy Policy.
								</Typography>
								<br />
								<br />
								<Typography fontSize='13px' variant='h6'>
									Compliance with Laws and Law Enforcement
								</Typography>
								<Typography fontSize='16px' variant='p'>
									We will cooperate with government and law enforcement
									officials and private parties to enforce and comply with the
									law. We reserve the right to track IP addresses for the
									purposes of fraud prevention and to release IP addresses to
									legal authorities. We will disclose information about you to
									government or law enforcement officials or private parties
									when we believe reasonably necessary to comply with law, to
									protect the property and rights of the Company or a third
									party, to protect the safety of the public or any person, or
									to prevent or stop activity we may consider to be, or to pose
									a risk of being, any illegal, unethical or legally actionable
									activity.
								</Typography>
								<br />
								<br />
								<Typography fontSize='13px' variant='h6'>
									Email Notifications
								</Typography>
								<Typography fontSize='16px' variant='p'>
									You may be contacted by email or other means; for example, we
									may send you a promotional offer on behalf of other businesses
									or communicate with you about your use of the service. Also,
									we may receive a confirmation when you open an email from us.
									This confirmation helps us improve our Services. If you do not
									want to receive email or other mail from us, please indicate
									your preference by writing to us at support@medimny.com.
									Please note that if you do not want to receive legal notices
									from us, those legal notices will still govern your use of the
									Services and you are responsible for reviewing such legal
									notices for changes.
								</Typography>
								<br />
								<br />
								<Typography fontSize='13px' variant='h6'>
									Governing Law and Dispute Resolution
								</Typography>
								<Typography fontSize='16px' variant='p'>
									This Privacy Policy shall be subject to and construed in
									accordance with the laws of India and the parties hereby
									submit to the exclusive jurisdiction of the Courts at Cuttack,
									Odisha, India
								</Typography>
								<br />

								<Typography fontSize='16px' variant='p'>
									In the event of any disputes, differences, or controversies
									between the parties hereto, out of or in connection with the
									provisions of this Privacy Policy, or any action taken
									hereunder, the parties hereto shall thoroughly explore all
									possibilities for an amicable settlement. In case an amicable
									settlement cannot be reached, such disputes, differences or
									controversies shall be resolved by Arbitration & Conciliation
									Act, 1996 subject to the exclusive jurisdiction of the Courts
									at Cuttack, Odisha, India and You hereby accede to and accept
									the jurisdiction of such Courts.
								</Typography>
								<br />
								<br />
								<Typography fontSize='13px' variant='h6'>
									Contact Information
								</Typography>
								<Typography fontSize='16px' variant='p'>
									If you have any questions or concerns with respect to this
									Privacy Policy or the Website or any information contained on
									thereon, you may contact us by writing to us
									at admin@meddaily.in .
								</Typography>
							</ul>
						</Typography>
					</Typography>
				</div>
			</div>
			<Footer />
		</>
	);
};

export default PrivacyPolicy;

import Typography from '@mui/material/Typography';
import imgage1 from '../image/catalog/demo/banners/Cms-Banner-01.png';
import imgage2 from '../image/catalog/demo/banners/Cms-Banner-02.png';
import imgage3 from '../image/catalog/demo/banners/Cms-Banner-03.png';
import easy_to_order from '../image/catalog/demo/banners/2.png';
import fast_delivery from '../image/catalog/demo/banners/3.png';
import best_quality from '../image/catalog/demo/banners/1.png';
import outSourcing from '../image/catalog/demo/banners/Outsourcing.png';
import google from '../image/google.png';
import app from '../image/app.png';
import mobile from '../image/phone.svg';
import Card from './card';
import Newcard from './resuableComponents/newlylaunch';
import axios from 'axios';
// import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import '../css/body.css';
import ImageSlider from './ImageSlider';
import hero1 from '../image/cache/catalog/demo/banners/Main-Banner-1-1903x600.jpg';
import hero2 from '../image/cache/catalog/demo/banners/Main-Banner-2-1903x600.jpg';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Products from '../pages/products';
import { useDispatch, useSelector } from 'react-redux';
import { fetchgetcategoryDispatch } from '../reducers/HomeReducer';
import { Carousel } from 'react-bootstrap';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const images = [
	{
		id: 1,
		src: hero1,
		alt: 'Image',
	},
	{
		id: 2,
		src: hero2,
		alt: 'Image',
	},
];

const Body = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { category } = useSelector((state) => state.home);
	const [cardData, setCardData] = useState([]);
	const [currentIndex, setCurrentIndex] = useState(0);
	const handleChenge = () => {
		axios
			.get('/retailer_home', {
				headers: { token: sessionStorage.getItem('token') },
			})
			.then((res) => {
				setCardData(res.data.category);
				console.log(res.data);
			})
			.catch((e) => {
				console.log(e);
			});
	};
	useEffect(() => {
		handleChenge();
		dispatch(fetchgetcategoryDispatch());
	}, []);

	const onCategoryClick = (category_id) => {
		navigate(`/products/${category_id}`);
	};

	const handlePrev = () => {
		setCurrentIndex((prevIndex) => (prevIndex - 1 < 0 ? cardData?.length - 1 : prevIndex - 1));
	};

	const handleNext = () => {
		setCurrentIndex((prevIndex) => (prevIndex + 1 >= cardData?.length ? 0 : prevIndex + 1));
	};

	return (
		<>
			<div className='hero-div'>
				{/* <img src={hero} alt='hero_image' className='hero-img' /> */}
				{/* <h1>dfgsd</h1> */}
				<ImageSlider images={images} className='hero-img' />
			</div>
			<div className='mid-div'>
				<Typography variant='h4' color='#30d5c8'>
					Categories
				</Typography>
				<Typography variant='h5' color='#30d5c8'>
					Get Best Deals in All Categories
				</Typography>

				<div className='img-div'>
					
						{/* <button className='buttoncard' onClick={handlePrev}>
							<FaChevronLeft />
						</button> */}
						{category?.data &&
							category?.data.length > 0 &&
							category?.data?.map((cat) => (
								<div
								
									style={{
										width:"200px",
										transform: `translateX(${-currentIndex * 250}px)`,
										transition: "transform 0.5s ease", flex: '0 0 auto'
									}}
									key={cat._id} 
									onClick={() => onCategoryClick(cat._id)}>
									<img src={cat.image} className='banner' />
									<Typography variant='h5'>{cat.name}</Typography>
								</div>
							))}

						{/* <button className='buttoncard' onClick={handleNext}>
							<FaChevronRight />
						</button> */}
					
				</div>
			</div>
			
			<div className='color'>
				<Typography pt={4} variant='h4' className='heading-newly'>
					Newly Launched - Upto 70% Off
				</Typography>
			</div>
			{/* <button onClick={handleScrollLeft} >
				Scroll Left
			</button> */}
			<div className='newly-launched '>
				<Newcard />
			</div>
			{/* <button onClick={handleScrollRight}>
				Scroll Right
			</button> */}
			<div className='color'>
				<Typography pt={4} variant='h4'>
					Best Selling Products
				</Typography>
			</div>
			<div className='best-selling'>
				{/* <button className="btn-left">
          <ChevronLeftIcon style={{ color: "white" }} />
        </button> */}
				<Card name={cardData} />
			</div>

			<div className='last-div'>
				<Typography variant='h6' style={{ color: '#30d5c8' }}>
					WHAT WE SERVE
				</Typography>
				<Typography variant='h4' style={{ fontWeigth: 'bolder' }}>
					<strong>
						{' '}
						Your Favourite Medicine <br></br>Supply Partner
					</strong>
				</Typography>

				<div className='serve-div'>
					<div className='easy_to_order'>
						<img src={easy_to_order} alt='img' />
						<br></br>
						<Typography variant='h5'>Easy to Order</Typography>
						<Typography style={{ fontSize: '16px', color: '#30d5c8' }} mt={2}>
							lorem ipsum lorem ipsum lorem ipsum<br></br>lorem ipsum lorem
							ipsum
						</Typography>
					</div>

					<div className='fast_delivery'>
						<img src={fast_delivery} alt='img' />
						<br></br>
						<Typography variant='h5'> Fast Delivery</Typography>
						<Typography style={{ fontSize: '16px', color: '#30d5c8' }} mt={2}>
							lorem ipsum lorem ipsum lorem ipsum<br></br>lorem ipsum lorem
							ipsum
						</Typography>
					</div>

					<div className='best_quality'>
						<img src={best_quality} alt='img' />
						<br></br>
						<Typography variant='h5'> Best Quality</Typography>
						<Typography style={{ fontSize: '16px', color: '#30d5c8' }} mt={2}>
							lorem ipsum lorem ipsum lorem ipsum<br></br>lorem ipsum lorem
							ipsum
						</Typography>
					</div>
				</div>
			</div>

			<div className='register-div'>
				<Typography sx={{'@media (max-width:992px)':{textAlign:'center',}}} variant='h2' style={{ color: 'white' }}>
					We Deliver value <br></br>through colaboration
				</Typography>
				<img src={outSourcing} alt='img' className='outsourcing' />
				<Link to='/retailer_signup'>
					<button className='register-btn'>
						REGISTER AS DISTRIBUTOR
						{/* {" "}
            <Typography variant="h5">REGISTER AS DISTRIBUTOR</Typography> */}
					</button>
				</Link>
			</div>

			<div className='promotion'>
				<div>
					<Typography variant='h5' style={{ color: 'white' }}>
						One-Stop Solution To Your Medicine Supply Chain <br></br>
						Download The Med-Daily Mobile App
					</Typography>
					<img src={google} alt='logo' className='download' />
					<img src={app} alt='logo' className='download' />
				</div>
				<div className='mobile-img'>
					<img src={mobile} alt='mobile_img' />
				</div>
			</div>
		</>
	);
};

export default Body;

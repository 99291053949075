import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchAllOrderDispatch } from '../reducers/HomeReducer';

function createData(
	name: string,
	calories: number,
	fat: number,
	carbs: number,
	protein: number
) {
	return { name, calories, fat, carbs, protein };
}
const head = ['NAME', 'SUBTITLE', 'IMAGE', 'DESCRIPTION'];

const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function InventoryList() {
	const { disproduct } = useSelector((state) => state.home);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(fetchAllOrderDispatch());
	}, []);
	return (
		// <TableContainer component={Paper}>
		// 	<Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
		// 		<TableHead>
		// 			<TableRow>
		// 				{head.map((i) => {
		// 					return <TableCell>{i}</TableCell>;
		// 				})}
		// 			</TableRow>
		// 		</TableHead>
		// 		<TableBody>
		// 			{disInvent?.product &&
		// 				disInvent?.product.length > 0 &&
		// 				disInvent?.product.map((row) => (
		// 					<TableRow
		// 						key={row.name}
		// 						sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
		// 						<TableCell
		// 							component='th'
		// 							scope='row'
		// 							style={{ padding: 15, textAlign: 'start' }}>
		// 							{row._id}
		// 						</TableCell>
		// 						<TableCell
		// 							align='right'
		// 							style={{ padding: 15, textAlign: 'start' }}>
		// 							{row.name}
		// 						</TableCell>
		// 						<TableCell
		// 							align='right'
		// 							style={{ padding: 15, textAlign: 'start' }}>
		// 							{row.subtitle}
		// 						</TableCell>
		// 						<TableCell
		// 							align='right'
		// 							style={{ padding: 15, textAlign: 'start' }}>
		// 							{row.price}
		// 						</TableCell>
		// 						{/* <TableCell
		// 							align='right'
		// 							style={{ padding: 15, textAlign: 'start' }}>
		// 							{row.order_status === 0
		// 								? 'Cancel'
		// 								: row.order_status === 1
		// 								? 'Shipped'
		// 								: row.order_status === 3
		// 								? 'Delivered'
		// 								: row.order_status === 4
		// 								? 'Order Placed'
		// 								: ''}
		// 						</TableCell>
		// 						<TableCell
		// 							onClick={() => navigate(`/orderdetail/${row.order_id}`)}
		// 							align='right'
		// 							style={{
		// 								padding: 15,
		// 								textAlign: 'start',
		// 								cursor: 'pointer',
		// 							}}>
		// 							View Details
		// 						</TableCell> */}
		// 					</TableRow>
		// 				))}
		// 		</TableBody>
		// 	</Table>
		// </TableContainer>
		<div className='content-wrapper'>
			<div className='container-xxl flex-grow-1 container-p-y'>
				<div className='row'>
					<div className='card'>
						<h5 className='card-header float-start'>My Inventory</h5>

						<div className='table-responsive text-nowrap'>
							<table className='table'>
								<thead>
									<tr>
										{head.map((i) => {
											return <TableCell>{i}</TableCell>;
										})}
									</tr>
								</thead>
								<tbody>
									{disproduct &&
										disproduct?.product.length > 0 &&
										disproduct?.product.map((product) => {
											return (
												<tr key={product._id}>
													<td>{product.name}</td>
													<td>{product.sub_title}</td>
													<td>{product.image}</td>
													<td>{product.description}</td>
													{/* <td>
														<div className='dropdown'>
															<Link
																className='dropdown-item'
																to={{
																	pathname: '/updateinv',
																	state: {
																		productId: product._id,
																	},
																}}>
																{' '}
																View Full Details
															</Link>
														</div>
													</td> */}
												</tr>
											);
										})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

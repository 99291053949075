import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

const ListNewProductInv = () => {
	const [distributorList, setDistributorList] = useState('');
	const { disInvent } = useSelector((state) => state.home);
	return (
		<>
			<div className='content-wrapper'>
				<div className='container-xxl flex-grow-1 container-p-y'>
					<div className='row'>
						<div className='card'>
							<h5 className='card-header float-start'>My Inventory</h5>
							<div className='table-responsive text-nowrap'>
								<table className='table'>
									<thead>
										<tr>
											<th>Name</th>
											<th>Subtitle</th>
											<th>Price</th>
											<th>InStock</th>
											<th>Action</th>
										</tr>
									</thead>
									<tbody className='table-border-bottom-0'>
										{disInvent &&
											disInvent.product.length > 0 &&
											disInvent.product.map((dist, k) => [
												<tr key={k}>
													<td>
														<i className='fab fa-angular fa-lg text-danger me-3'></i>{' '}
														<strong>{dist.name}</strong>
													</td>
													<td>{dist.subtitle}</td>
													<td>{dist.price}</td>
													<td>{dist.stock}</td>
													<td>
														<div className='dropdown'>
															{/* <Link
																className='dropdown-item'
																// to="/distributordetails"
																to={{
																	pathname: '/distributordetails',
																	state: { id: dist._id },
																}}>
																{' '}
																View Full Details
															</Link> */}
														</div>
													</td>
												</tr>,
											])}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default ListNewProductInv;

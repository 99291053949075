import Navbar2 from "../components/navBar_2";
import Footer from "../components/footer";
import Offer from "../components/offer";
import "../css/details.css";
import { Button, Typography } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  addToCartDispatch,
  setSelectedDestributorID,
} from "../reducers/HomeReducer";
import { storeProductPrice } from "../utils";

const Details = () => {
  const dispatch = useDispatch();
  const { dsID } = useSelector((state) => state.home);
  const [productDetails, setProductData] = useState();
  console.log(productDetails, "productDetails");
  const [distributerData, setDistributerData] = useState([]);
  const { productId } = useParams();
  const [cartQuantity, setCartQuantity] = useState(0);

  useEffect(() => {
    axios
      .post(
        "/product_details",
        {
          id: productId,
        },
        { headers: { token: sessionStorage.getItem("token") } }
      )
      .then((res) => {
        setProductData(res.data.product);
        setDistributerData(res.data.distributor);
      })
      .catch((error) =>
        console.error("Error fetching category product data:", error)
      );
  }, [productId]);

  const addToCart = (e, id, price) => {
    e.preventDefault();

    if (cartQuantity === 0) {
      Swal.fire({
        position: "top-end",
        icon: "error",
        title: "Please select quantity",
        showConfirmButton: false,
        timer: 1500,
      });

      return;
    }

    const data = {
      product_id: productId,
      distributor_id: id,
      price,
      quantity: cartQuantity,
    };
    let formdata = new FormData();
    formdata.append("product_id", productId);
    formdata.append("distributor_id", dsID);

    console.log(data, "data");
    dispatch(addToCartDispatch(data, formdata));
    setTimeout(() => {
      window.location.href = "/#/ordersummary";
    }, 2000);
  };
  const incrementQuantity = (distributorId) => {
    setCartQuantity(cartQuantity + 1);
    dispatch(setSelectedDestributorID(distributorId));
    // cartQuantity > 1 ? setCartQuantity(cartQuantity + 1 ) : setCartQuantity(distributerData.stock)
    // setCartQuantity(Number(cartQuantity) + 1);
  };

  const decrementQuantity = () => {
    if (cartQuantity > 0) {
      setCartQuantity(cartQuantity - 1);
    } else if (cartQuantity === 0) {
      dispatch(setSelectedDestributorID(null));
    }
  };
  // const incrementQuantity = (distributorId) => {
  // 	const distributor = distributerData?.find((distributor) => {
  // 		console.log("????>>>>???", distributor.distributorId === distributorId);
  // 		return distributor.distributorId === distributorId
  // 	});

  // 	console.log('ID', distributerData);

  // 	if (!distributor) {
  // 		console.log('Distributor not found');
  // 		return;
  // 	}

  // 	const stock = distributor.stock;

  // 	console.log('Cart Quantity:', cartQuantity);
  // 	console.log('Stock:', stock);

  // 	if (cartQuantity + 1 <= stock) {
  // 		setCartQuantity((prevQuantity) => prevQuantity + 1);
  // 		dispatch(setSelectedDestributorID(distributorId));
  // 	} else {
  // 		console.log('Exceeded stock limit');
  // 	}
  // };

  // const decrementQuantity = () => {
  // 	if (cartQuantity > 0) {
  // 		setCartQuantity(cartQuantity - 1);
  // 	} else if (cartQuantity === 0) {
  // 		dispatch(setSelectedDestributorID(null));
  // 	}
  // };

  const handleQuantityChange = (e) => {
    setCartQuantity(e.target.value);
  };

  useEffect(() => {
    if (productDetails) {
      console.log(productDetails, "test");
      const distrubter = {};
      productDetails.distributors?.forEach((element) => {
        distrubter[element.distributorId] = element;
      });
      localStorage.setItem("productDetails", JSON.stringify(distrubter));
    }
  }, [productDetails]);

  return (
    <>
      <Navbar2 />
      <div style={{ padding: "14px" }}>
        <div className="medicine-details">
          {productDetails && (
            <div>
              <Typography variant="h4">{productDetails.title}</Typography>
              <div className="branded">
                <Typography fontSize={14}>Branded</Typography>
              </div>
              <Typography mt={3} mb={2} variant="h6" color="#818181">
                Best Price*{" "}
                <span style={{ color: "#6EAFAB" }}>
                  ₹ {productDetails?.distributors[0]?.price}
                </span>
              </Typography>
              <ul>
                <li> {productDetails?.sub_title}</li>
                <li>Country of Origin : India</li>
                <li>
                  Delivery charges if applicable will be applied at checkout
                </li>
              </ul>
            </div>
          )}
          <div className="offers-div">
            <Typography variant="h6" mt={2} color="#6EAFAB">
              Offers Available
            </Typography>
            <div className="offerList">
              <Offer />
              <Offer />
              <Offer />
            </div>
          </div>
        </div>
        {productDetails && (
          <div className="description">
            <Typography variant="h5" color="#6EAFAB">
              Description
            </Typography>
            <Typography style={{ padding: "20px" }}>
              {productDetails.description}
            </Typography>
          </div>
        )}

        <div class="available-distributor">
          <Typography variant="h6" color="#6EAFAB" mb={3}>
            Available Distributors
          </Typography>
          {productDetails?.distributors?.map((item) => (
            <div
              className="dist-name"
              style={{
                marginTop: 10,
                display: "flex",
                flexDirection: "column",
              }}
            >
              {/* <Typography>M/S Recipharam Pharmaservices Pvt. Ltd</Typography>
              <Typography>Rs. 599.00</Typography> */}
              <Typography
                style={{
                  color: dsID === item.distributorId ? "black" : "darkgray",
                  fontWeight: "bold",
                }}
              >
                {item.distributorName}
              </Typography>

              <div
                className="count"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{
                    color: dsID === item.distributorId ? "black" : "darkgray",
                    padding: "20px",
                  }}
                >
                  Rs. {item?.price}{" "}
                  <span style={{ marginLeft: "5px" }}>
                    (Stock: {item?.stock})
                  </span>
                </Typography>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    onClick={decrementQuantity}
                    // style={{
                    // 	color: dsID === item.distributorId ? '' : 'darkgray',
                    // }}
                  >
                    <RemoveIcon
                    // style={{
                    // 	color: dsID === item.distributorId ? '' : 'darkgray',
                    // }}
                    />
                  </Button>

                  <input
                    style={{
                      color: dsID === item.distributorId ? "" : "darkgray",
                    }}
                    className="quantity-inp"
                    placeholder="10"
                    value={dsID === item.distributorId ? cartQuantity : 0}
                    onChange={handleQuantityChange}
                  ></input>

                  <Button
                    onClick={() => incrementQuantity(item.distributorId)}
                    // style={{
                    // 	color: dsID === item.distributorId ? '' : 'darkgray',
                    // }}
                  >
                    <AddIcon />
                  </Button>
                </div>

                {/* <Link to="/ordersummary">
                  {" "} */}
                <Button
                  onClick={(e) => {
                    if (cartQuantity <= item?.stock) {
                      addToCart(e, item.distributorId, item.price);
                    } else {
                      alert("Quantity is not more then stock");
                    }
                  }}
                  style={{
                    color: dsID === item.distributorId ? "" : "darkgray",
                  }}
                >
                  Add TO Cart
                </Button>

                {/* </Link> */}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Details;

import React from 'react';
import AddOfferForm from '../components/resuableComponents/AddOfferForm';

const AddOffer = () => {
	return (
		<div className='content-wrapper'>
			<div className='container-xxl flex-grow-1 container-p-y'>
				<div className='row'>
					<div className='card mb-12'>
						<div className='card-header d-flex justify-content-between align-items-center'>
							<h5 className='mb-0'>Create Offer</h5>
						</div>

						<hr className='my-0' />
						<div className='card-body'>
							<AddOfferForm />
							{/* <form
								id='formAccountSettings'
								method='POST'
								onsubmit='return false'>
								<div className='row'>
									<div className='mb-3 col-md-6'>
										
									</div>

									<div className='mb-3 col-md-6'>
										<div className='mt-2'>
											<button className='btn btn-primary me-2'>
												<a href='https://www.google.co.in/' target={'_blank'}>
													{' '}
													Download sample file
												</a>
											</button>
										</div>
									</div>
								</div>

								<div className='mt-2'>
									<button type='submit' className='btn btn-primary me-2'>
										Save
									</button>
									<button type='reset' className='btn btn-outline-secondary'>
										Cancel
									</button>
								</div>
							</form> */}
						</div>
					</div>
				</div>

				<div className='content-backdrop fade'></div>
			</div>
		</div>
	);
};

export default AddOffer;

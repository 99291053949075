// import { Margin, Padding } from "@mui/icons-material";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import toastr from "toastr";
import { useNavigate } from "react-router-dom";
// import { API_URL } from '../network/endpoints';
// import { Box } from "@mui/material";
const token = sessionStorage.getItem("token");

const orderStatuses = {
  1: "Order shipped",
  3: "Order delivered",
  4: "Order placed",
  0: "Order cancelled",
};
function Detailsreturn() {
  // console.log("id",id);
  // const { id } = use();
  const [details, setDetails] = useState(null);
  const [returndetails, setReturndetails] = useState({
    products: (details?.products || []).map((product) => ({
      id: product.id,
      return_quantity: "",
    })),
  });
  // const [createInvoice, setCreateInvoice] = useState(false);
  console.log("DETAILOS", details);
  const [formState, setFormState] = useState({
    // products: (details?.products || []).map((product) => ({
    //   id: product.id,
    //   quantity: "",
    // })),
    reason: "",
    message: "",
  });
  console.log(">>>>formstate>>>>>", formState);
  const [retailerData, setRetailerData] = useState(null);
  const [distributorData, setDistributorData] = useState(null);
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/orderlist");
  };

  useEffect(() => {
    const fetchData = async () => {
      const id = localStorage.getItem("order");
      try {
        const response = await axios.post(`/order_detail`, {
          order_id: id,
        });

        if (response.status === 200) {
          setDetails(response?.data?.status?.data);
          console.log("DETAILSSSSSSS", details);
          const retailerId = response?.data?.status?.data?.retailer_id;
          const distributorId = response?.data?.status?.data?.distributor_id;

          if (retailerId) {
            const retailerResponse = await axios.post(`/retailer_detail`, {
              id: retailerId,
            });

            if (retailerResponse.status === 200) {
              setRetailerData(retailerResponse?.data?.data);
            }
          }

          if (distributorId) {
            const distributorResponse = await axios.post(
              `/distributor_detail`,
              { id: distributorId }
            );

            if (distributorResponse.status === 200) {
              setDistributorData(distributorResponse?.data?.data);
              setReturndetails({
                products: response?.data?.status?.data?.products || [],
              });
            }
          }
        }
      } catch (err) {
        toastr.error(err?.response?.data?.message);
        console.log(err);
      }
    };

    fetchData();
  }, []);
  const handleReturnOrder = async () => {
    const id = localStorage.getItem("order");
    const payload = {
      order_id: details.order_id,
      reason: formState.reason,
      message: formState.message,
      items: returndetails?.products?.map((product) => ({
        id: product.id,
        quantity: parseInt(product.return_quantity, 10) || 0,
      })),
    };

    const isReturnQuantityValid = returndetails.products.every((product) => {
      const returnQuantity = parseInt(product.return_quantity, 10) || 0;
      const availableQuantity = product.quantity;
      console.log("Return Quantity:", returnQuantity);
      console.log("Order Quantity:", availableQuantity);
      return returnQuantity <= availableQuantity;
    });
    console.log(isReturnQuantityValid, ">>>>>>>>>>>>>>>>>>>>>>>>>>>>");

    if (!isReturnQuantityValid) {
      console.log("NEWDATA");
      alert("Return quantity should not greater then the product quantity.");
      // alert("return quntity is above then actual quntity");
      return;
    }

    try {
      const response = await axios.post(`/return_order`, payload, {
        headers: {
          token: token,
        },
      });

      if (response.status === 200 && response.data.status) {
        toastr.success(response.data.message);
        navigate("/orderlist");
      } else {
        toastr.error(response.data.message);
      }
    } catch (err) {
      toastr.error(err?.response?.data?.message || "Error returning order");
      console.error(err);
    }
  };

  //   const handleReturnOrder = async () => {
  //     const id = localStorage.getItem('order');
  //     console.log("???????????details??????????", details);

  //     const payload = {
  //       order_id: details.order_id,
  //       reason: formState.reason,
  //       message: formState.message,
  //       items: returndetails?.products?.map((product) => ({
  //         id: product.id,
  //         quantity: product.return_quantity,
  //       })),
  //     }
  //     console.log("???????????payload??????????", payload);

  //     const isReturnQuantityValid = returndetails.products.every((product) => {
  //       const returnQuantity = parseInt(product.return_quantity);
  //       const orderQuantity = product.quantity;

  //       console.log("Return Quantity:", returnQuantity);
  //       console.log("Order Quantity:", orderQuantity);

  //       return returnQuantity >= orderQuantity;
  //     });

  //     console.log("Before if (!isReturnQuantityValid)");
  // if (!isReturnQuantityValid) {
  //   console.log("Inside if (!isReturnQuantityValid)");
  //   console.log("Error: Return quantity should not exceed the order quantity.");
  //   // toastr.error("Return quantity should not exceed the order quantity.");
  //   return;
  // }
  // console.log("After if (!isReturnQuantityValid)");

  //     // if (!isReturnQuantityValid) {
  //     //   toastr.error("Return quantity should not exceed the order quantity.");
  //     //   console.log("Error: Return quantity should not exceed the order quantity.");
  //     //   return;
  //     // }
  //     try {
  //       const response = await axios.post(`/return_order`, payload, {
  //         headers: {
  //           token: token,
  //         },
  //       });
  //       console.log(">>>>>>>>>>>>>>>", response);

  //       if (response.status === 200 && response.data.status) {
  //         toastr.success(response.data.message);
  //         // Additional logic or redirection after successful return order
  //         navigate("/orderlist");
  //       } else {
  //         toastr.error(response.data.message);
  //       }
  //     } catch (err) {
  //       toastr.error(err?.response?.data?.message || "Error returning order");
  //       console.error(err);
  //     }
  //   };

  // const calculateTotalOrderPrice = (products) => {
  //   if (!products || products.length === 0) {
  //     return 0;
  //   }
  //   const totalOrderPrice = products.reduce((sum, product) => {
  //     return sum + product.price * product.quantity;
  //   }, 0);

  //   return totalOrderPrice;
  // };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6">
          <h1>Return Details</h1>
          <p></p>
          <p>
            <strong>Order ID:</strong> {details?.order_id}
          </p>
          <p>
            <strong>Order Date:</strong>{" "}
            {new Date(details?.createdAt).toLocaleString()}
          </p>
          <p>
            <strong>Order Status:</strong>{" "}
            {details?.order_status ? orderStatuses[details?.order_status] : ""}
          </p>
          <p>
            <strong>Payment Type:</strong>{" "}
            {details?.payment_type === 1
              ? "COD"
              : details?.payment_type === 2
              ? "On Credit (This order is on Credit , Kindly accept the on your own. Middaily will not take responsibility of payment failures)"
              : details?.payment_type === 0
              ? "Prepaid"
              : ""}
          </p>
          <p>
            <strong>Total Price:</strong> {details?.price}
          </p>
          {/* <p>
            <strong>Payment Status:</strong> {details?.payment_status}
          </p> */}
          <p>
            <strong>Retailer-Address:</strong>{" "}
            {`${retailerData?.address},${retailerData?.area},${retailerData?.city},${retailerData?.pincode},${retailerData?.state}`}
          </p>
          <p>
            <strong>Distributor-Address:</strong>{" "}
            {`${distributorData?.area},${distributorData?.city},${distributorData?.pincode},${distributorData?.state}`}
          </p>
        </div>

        <div className="">
          <h4>Products:</h4>
          {details &&
            details?.products &&
            details?.products.length > 0 &&
            details?.products.map((product, index) => (
              <div className="m-5 pt-5" key={index}>
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-text">Product Name: {product.name}</h5>
                    <p className="card-text">Price: {product.price}</p>
                    <p className="card-text">
                      Total Price: {product.price * product.quantity}
                    </p>

                    <div>
                      <table style={{ border: "none" }}>
                        <tr>
                          <td>Enter Quantity</td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              id="batch"
                              name="batch"
                              placeholder="quantity"
                              style={{ width: "90px" }}
                              onChange={({ target }) => {
                                const productId = product.id;
                                // setReturndetails((returndetails) => ({
                                //   products: returndetails.products.map((p) => {
                                //     if (p.id === productId) {
                                //       return {
                                //         ...p,
                                //         return_quantity: target.value,
                                //       };
                                //     }
                                //     return p;
                                //   }),
                                // }), () => {
                                //   // Now check the validity after state update
                                //   const isReturnQuantityValid = returndetails.products.every((product) => {
                                //     console.log("Return Quantity:", parseInt(product.return_quantity));
                                //     console.log("Order Quantity:", product.quantity);
                                //     console.log("Error: Return quantity should not exceed the order quantity.");
                                //     return parseInt(product.return_quantity) >= product.quantity;

                                //   });

                                //   if (!isReturnQuantityValid) {
                                //     toastr.error("Return quantity should not exceed the order quantity.");
                                //     return;
                                //   }
                                // });

                                setReturndetails((returndetails) => ({
                                  products: returndetails.products.map((p) => {
                                    if (p.id === productId) {
                                      return {
                                        ...p,
                                        return_quantity: target.value,
                                      };
                                    }
                                    return p;
                                  }),
                                }));
                              }}
                              autoFocus
                            />
                          </td>
                          {/* <td>
                            <input
                              type="number"
                              className="form-control"
                              id="batch"
                              name="batch"
                              placeholder="quantity"
                              style={{ width: "90px" }}
                              // value={returndetails?.product?.quantity}
                              onChange={({ target }) => {
                                const enteredQuantity = parseInt(target.value, 10);
                                const maxQuantity = product?.quantity; 

                                if (maxQuantity >= enteredQuantity) {
                                  setReturndetails((returndetails) => ({
                                    products: returndetails.products.map((p, i) => {
                                      if (i === index) {
                                        return {
                                          ...p,
                                          quantity: enteredQuantity,
                                        };
                                      }
                                      return p;
                                    }),
                                  }));
                                }
                              }}
                              autoFocus
                            />
                          </td> */}
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div style={{ width: "60%", textAlign: "center", margin: "auto" }}>
          <table style={{ border: "none", textAlign: "center" }}>
            <tr>
              <td>Reason</td>
            </tr>
            <tr>
              <td>
                {/* <input
                  type="text"
                  className="form-control"
                  id="batch"
                  name="batch"
                  placeholder="Reason"
                  onChange={({ target }) => {
                    setFormState({
                      ...formState,
                      reason: target.value,
                    });
                  }}
                  autoFocus
                /> */}
                {/* <div className="mb-3 col-md-6"> */}
                {/* <label
                              htmlFor="medType"
                              className="form-label float-start"
                            >
                              Category
                            </label> */}
                <select
                  id="medType"
                  name="productType"
                  // value={categoryId}
                  onChange={({ target }) => {
                    setFormState({
                      ...formState,
                      reason: target.value,
                    });
                  }}
                  className="select2 form-select"
                >
                  <option value="Duplicate Order">Duplicate Order</option>
                  <option value="Change of Mind">Change of Mind</option>
                  <option value="Damaged During Shipping">
                    Damaged During Shipping
                  </option>
                  <option value="Wrong Item Received">
                    Wrong Item Received
                  </option>
                  <option value="Defective or Faulty Product">
                    Defective or Faulty Product
                  </option>
                </select>
                {/* </div> */}
              </td>
            </tr>
            <tr>
              <td>Message</td>
            </tr>
            <tr>
              <td>
                <input
                  type="text"
                  className="form-control"
                  id="batch"
                  name="batch"
                  placeholder="Message"
                  onChange={({ target }) => {
                    setFormState({
                      ...formState,
                      message: target.value,
                    });
                  }}
                  autoFocus
                />
              </td>
            </tr>
          </table>
        </div>
        <div
          style={{ justifyContent: "center", width: "100%", display: "flex" }}
        >
          <Button
            variant="secondary mt-5 "
            style={{ marginRight: "5px" }}
            onClick={handleReturnOrder}
          >
            Return Order
          </Button>
          <Button variant="secondary mt-5" onClick={handleClose}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export default Detailsreturn;

import {
  Typography,
  Button,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Table,
  Box,
} from "@mui/material";
// import Navbar from "../components/Navbar";
import Navbar1 from "../components/navBar_2";
import toastr from "toastr";

import Footer from "../components/footer";
import google from "../image/google.png";
import app from "../image/app.png";
import mobile from "../image/phone.svg";
import "../css/order_summary.css";
import Offer from "../components/offer";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import axios from "axios";
// import { useParams } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import { useDispatch, useSelector } from "react-redux";
import LoaderModal from "../components/resuableComponents/LoaderModal";
import { fetchgetCartDispatch } from "../reducers/HomeReducer";
import { Card } from "react-bootstrap";
// import { CheckBox } from "@mui/icons-material";
import { axiosInstance } from "../Axios";

const OrderSummary = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  let navigate = useNavigate();
  // const location = useLocation();
  // const viewData = location.state;
  // const { _id } = useParams();
  const dispatch = useDispatch();
  const [cartData, setCartData] = useState([]);
  const [itemTotal, setItemTotal] = useState("");
  const [dioffer, setdisoffer] = useState();
  const [itemTaxTotal, setTaxItemTotal] = useState("");
  console.log(cartData, "cartData");
  const { offer, offerSelect } = useSelector((state) => state.home);

  const [selectedType, setSelectedPayment] = useState("");
  const [deliveryfee, setDeliveryFee] = useState(0);
  // const [responsefee, setResponseFee] = useState()
  const [loading2, setLoading2] = useState(false);

  const handelDelivery = async (event) => {
    try {
      // Fetch the current server time from your API
      const response = await axios.get("/my_order", {
        headers: { token: sessionStorage.getItem("token") },
      });
      console.log(response?.data, "63");
      const currentServerTime = response?.data?.data[0]?.createdAt;

      const currentDate = new Date(currentServerTime);
      const currentDateOnly = currentDate.toLocaleDateString();

      const currentDates = new Date();
      const formattedDate = currentDates.toLocaleDateString();

      const lastDeliveryDateTime = localStorage.getItem("lastDeliveryDateTime");
      const currentDateTime = new Date(currentServerTime);
      const currentTimeString = currentDateTime.toLocaleTimeString();

      const delivery_fee = response.data.data[0].delivery_fee;
      console.log(response.data, "delivery");

      // setResponseFee(delivery_fee)

      if (formattedDate === currentDateOnly) {
        console.log("lastDeliveryDateTime:", lastDeliveryDateTime);
        console.log(
          "Comparison result:",
          lastDeliveryDateTime &&
            lastDeliveryDateTime.includes(currentTimeString)
        );
        setDeliveryFee(delivery_fee);
        // setResponseFee(delivery_fee)
      } else {
        setDeliveryFee(delivery_fee);
        // delivery_fee=true
        // setResponseFee(delivery_fee)
        localStorage.setItem("lastDeliveryDateTime", ` ${currentTimeString}`);
      }

      const isNewLogin = localStorage.getItem("isNewLogin");

      if (!isNewLogin) {
        localStorage.setItem("isNewLogin", "true");
      }
    } catch (error) {
      console.error("Error fetching delivery info:", error);
    }
  };

  const handleChangeRadio = (event) => {
    console.log(event.target.value);
    setSelectedPayment(event.target.value);
  };

  useEffect(() => {
    dispatch(fetchgetCartDispatch(setCartData));
    handelDelivery();
  }, [dispatch]);

  useEffect(() => {
    const total = cartData?.reduce((accumulator, cart) => {
      var quantity = parseInt(cart.quantity * 1); // Convert quantity to number
      const price = parseInt(cart.itemPrice);
      console.log(price);
      if (quantity) {
        quantity = quantity;
      } else {
        quantity = 1;
      }
      if (offerSelect?.purchase_quantity >= quantity) {
        quantity -= offerSelect?.bonus_quantity;
      }
      return accumulator + price;
      // return accumulator + price * quantity;
    }, 0);

    console.log("total", cartData, total);

    // const totalTax = cartData?.reduce((accumulator, cart) => {
    //   var quantity = parseInt(cart.quantity * 1);
    //   const tax = parseInt(cart.product.applicable_tax);
    //   console.log(tax);
    //   const price = parseInt(cart.price);
    //   if (quantity) {
    //     quantity = quantity;
    //   } else {
    //     quantity = 1;
    //   }

    //   if (offerSelect?.purchase_quantity >= quantity) {
    //     quantity -= offerSelect?.bonus_quantity;
    //   }
    //   const totalPrice = price * quantity;
    //   const applicableTax = totalPrice * ((tax * 1) / 100);
    //   return accumulator + applicableTax;
    // }, 0);

    const totalTax = cartData?.reduce((accumulator, cart) => {
      var quantity = parseInt(cart.quantity * 1);

      // Check if cart.product is not null or undefined
      if (!cart.product) {
        return accumulator; // Skip this cart item if product is not available
      }

      const tax = parseInt(cart.product.applicable_tax);
      console.log(tax);
      const price = parseInt(cart.itemPrice);

      if (quantity) {
        quantity = quantity;
      } else {
        quantity = 1;
      }

      if (offerSelect?.purchase_quantity >= quantity) {
        quantity -= offerSelect?.bonus_quantity;
      }

      const totalPrice = price * quantity;
      const applicableTax = totalPrice * ((tax * 1) / 100);
      return accumulator + applicableTax;
    }, 0);

    setItemTotal(total);
    console.log(">>>>>>>>>>>>", cartData[0]?.product?.applicable_tax);
    console.log(">>>>>>>>>>>>Total Tax", parseFloat(totalTax));
    console.log(">>>>>>>>>>>>FEE", deliveryfee);
    setTaxItemTotal(parseFloat(totalTax.toFixed(2)));

    handeloffer();
  }, [cartData]);
  const handeloffer = async () => {
    // console.log("???>>>???>>>???>>>", cartData);
    const ddddd = await axios.get(
      `/getoffer?distributor_id=${cartData.distributor_id}&&product_id=${cartData[0]?.product_id}`
    );
    // console.log("disOffersdisOffersdisOffersdisOffersdisOffersdisOffersdisOffers", ddddd.data.data);
    setdisoffer(ddddd.data.data);
    // console.log("nnnnnnnnnnnnnvvvvvvvvvv", dioffer);
  };
  // useEffect(() => {
  //   handeloffer()
  // }, [])

  // const [quantity, setQuantity] = useState("");

  // const incrementQuantity = (id) => {
  //   // setQuantity(cartData.quantity + 1);
  //   const updatedCartData = cartData?.map((cart) => {
  //     if (cart._id === id) {
  //       return {
  //         ...cart,
  //         quantity: cart.quantity * 1 + 1,
  //       };
  //     }
  //     return cart;
  //   });
  //   setCartData(updatedCartData);
  // };

  // const incrementQuantity = async (id) => {
  //   try {
  //     const response = await axios.post("/update_cart", {
  //       cart_id: id,
  //       quantity: cartData.find(cart => cart._id === id).quantity + 1,
  //     }, { headers: { 'token': sessionStorage.getItem('token') } });

  //     if (response.data.status) {
  //       console.log("Cart quantity updated successfully");
  //       dispatch(fetchgetCartDispatch(setCartData));
  //     } else {
  //       console.error("Failed to update cart quantity:", response.data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error updating cart quantity:", error);
  //   }
  // };
  // const incrementQuantity = async (id) => {
  //   try {
  //     const cartItem = cartData.find((cart) => cart._id === id);
  //     const newQuantity = cartItem.quantity + 1;

  //     const distributor = cartItem.product.distributors.find(
  //       (dist) => dist.distributorId === cartItem.distributor_id
  //     );

  //     console.log("cartItem:", cartItem);
  //     console.log("newQuantity:", newQuantity);
  //     console.log("distributor:", distributor);

  //     if (newQuantity <= distributor?.stock) {
  //       console.log("newQuntity???", newQuantity);
  //       const response = await axios.post(
  //         "/update_cart",
  //         {
  //           cart_id: id,
  //           quantity: newQuantity,
  //         },
  //         { headers: { token: sessionStorage.getItem("token") } }
  //       );

  //       if (response.data.status) {
  //         console.log("Cart quantity updated successfully");
  //         dispatch(fetchgetCartDispatch(setCartData));
  //       } else {
  //         console.error(
  //           "Failed to update cart quantity:",
  //           response.data.message
  //         );
  //       }
  //     } else {
  //       alert("stock is not enough Cannot increase quantity.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating cart quantity:", error);
  //   }
  // };

  const storedPrice = localStorage.getItem("productPrice");
  console.log(storedPrice);

  const incrementQuantity = async (id) => {
    try {
      const cartItem = cartData.find((cart) => cart._id === id);
      console.log(cartItem, "cartItem");

      if (!cartItem) {
        console.error("Cart item not found with ID:", id);
        return;
      }

      const newQuantity = cartItem.quantity + 1;
      console.log(cartItem.distributor_id, "distributor_id");
      const distributor = cartItem.product.distributors.find(
        (dist) => dist.distributorId === cartItem.distributor_id
      );

      console.log("cartItem:", cartItem);
      console.log("newQuantity:", newQuantity);
      console.log("distributor:", distributor);

      if (distributor && newQuantity <= distributor.stock) {
        console.log("newQuantity:", newQuantity);
        const response = await axios.post(
          "/update_cart",
          {
            cart_id: id,
            quantity: newQuantity,
          },
          { headers: { token: sessionStorage.getItem("token") } }
        );

        if (response.data.status) {
          toastr.success("Cart quantity updated successfully");
          console.log("Cart quantity updated successfully");
          dispatch(fetchgetCartDispatch(setCartData));
        } else {
          console.error(
            "Failed to update cart quantity:",
            response.data.message
          );
        }
      } else {
        alert("Stock is not enough. Cannot increase quantity.");
      }
    } catch (error) {
      console.error("Error updating cart quantity:", error);
    }
  };

  const decrementQuantity = async (id) => {
    try {
      const response = await axios.post(
        "/update_cart",
        {
          cart_id: id,
          quantity: cartData.find((cart) => cart._id === id).quantity - 1,
        },
        { headers: { token: sessionStorage.getItem("token") } }
      );

      if (response.data.status) {
        console.log("Cart quantity updated successfully");
        dispatch(fetchgetCartDispatch(setCartData));
      } else {
        console.error("Failed to update cart quantity:", response.data.message);
      }
    } catch (error) {
      console.error("Error updating cart quantity:", error);
    }
  };

  // const decrementQuantity = (id, index) => {
  //   // if (quantity > 0) {
  //   //   setQuantity(cartData.quantity - 1);
  //   // }
  //   // console.log('cart.quantity', cart.quantity);
  //   const updatedCartData = cartData.map((cart) => {
  //     if (cart._id === id && cart.quantity > 0) {
  //       return {
  //         ...cart,
  //         quantity: cart.quantity - 1,
  //       };
  //     }
  //     return cart;
  //   });
  //   console.log("updatedCartData", updatedCartData);
  //   if (updatedCartData[index].quantity === 0) {
  //     return;
  //     let form = {
  //       cart_id: id,
  //     };
  //     axios
  //       .delete("https://api-meddaily.onrender.com/delete_cart", {
  //         headers: { token: sessionStorage.getItem("token") },
  //         data: form,
  //       })
  //       .then((response) => {
  //         console.log(response.data);
  //         dispatch(fetchgetCartDispatch());
  //         setCartData([]);
  //         // alert('order success');
  //       })
  //       .catch((error) => {
  //         alert("Error");
  //         console.log("error:", error);
  //         // console.error('Error adding to cart:', error);
  //       });

  //     // console.log('cart', form);
  //     // dispatch(delete_cartDispatch(form));
  //   }

  //   setCartData(updatedCartData);
  // };

  // const handleQuantityChange = (e) => {
  //   const newQuantity = Number(e.target.value);
  //   setQuantity(newQuantity);
  // };

  function deleteCartFunction(e, cartIds) {
    console.log("Deleting cart with ids:", cartIds);
    if (e) {
      e.preventDefault();
    }

    // const payload = {
    //   cart_ids: cartIds,
    // };
    const token = sessionStorage.getItem("token");
    console.log("Token", token);
    axios
      .delete("/delete_cart", {
        headers: { token: token },
        data: { cart_ids: cartIds }, // Correct way to send payload
      })
      .then((response) => {
        console.log(response);
        dispatch(fetchgetCartDispatch());
        setCartData([]);
        // alert('order success');
      })
      .catch((error) => {
        alert("Error");
        console.log("error:", error);
        // console.error('Error deleting carts:', error);
      });
  }

  const handleQuantityChange = (id, e) => {
    const newQuantity = parseInt(e.target.value);
    const updatedCartData = cartData.map((cart) => {
      if (cart._id === id) {
        return {
          ...cart,
          quantity: newQuantity,
        };
      }
      return cart;
    });
    setCartData(updatedCartData);
  };

  const checkOut = () => {
    setIsSubmitting(true);
    if (selectedType == "Pay Now") {
      const data = {
        price: itemTotal * 1 + itemTaxTotal * 1 + deliveryfee * 1,
        originalPrice: itemTotal,
        tax: itemTaxTotal,
        deliveryCost: deliveryfee * 1,
        payment_type: 2,
        bonus_quantity: cartData[0].quantity > 0 ? cartData[0].quantity : 1,
        delivery_fee: deliveryfee === 118 ? true : false,
      };

      console.log("?????????????????????", data);

      axios
        .post("/checkout", data, {
          headers: { token: sessionStorage.getItem("token") },
        })
        .then((response) => {
          if (response.data.status === true) {
            // alert(response.data.message);
            console.log(response.data, "datacheckout");
            // navigate(`/home`)
            const data1 = {
              name: response?.data?.data?.retailer_id,
              amount: response?.data?.data?.price,
              order_id: response?.data?.data?.order_id,
              MUID: "MUID" + Date.now(),
              transactionId: "MT" + Date.now(),
            };

            axiosInstance
              .post("/payment_init", data1, {
                headers: { token: sessionStorage.getItem("token") },
              })
              .then((response) => {
                if (response.data.url) {
                  console.log("DTATA", response);
                  window.location.href = response.data.url;
                  window.open(response.data.url, "_blank");
                }
                setTimeout(() => {
                  setLoading2(false);
                }, 1500);
              })
              .catch((error) => {
                setLoading2(false);
                console.error(error);
              });
          } else {
            // navigate(`/payment_confirmed/${response.data.data.order_id}`)
            alert("error");
          }
        })
        .catch((error) => {
          // const  datas = "Products have different distributors. Cannot create order."
          alert("error");
          console.log("error:", error);
          // console.error('Error adding to cart:', error);
        });

      // const paymentPayload = {
      //   price: itemTotal * 1 + itemTaxTotal * 1 + deliveryfee * 1,
      // };
      // axios
      //   .post(
      //     "https://api.meddaily.in/payment_initiate",
      //     paymentPayload ,
      //     { headers: { token: sessionStorage.getItem("token") } }
      //   )
      //   .then((response) => {
      //     console.log(response.data);
      //     //   alert('order success');
      //     if (response?.data?.data?.instrumentResponse.redirectInfo?.url) {
      //       window.open(
      //         response?.data?.data?.instrumentResponse.redirectInfo?.url,
      //         "__blank"
      //       );

      //       const data = {
      //         price: itemTotal * 1 + itemTaxTotal * 1,
      //         originalPrice: itemTotal,
      //         tax: itemTaxTotal,
      //         deliveryCost: 0,
      //         payment_type: 0,
      //         bonus_quantity: cartData[0].quantity > 0? cartData[0].quantity:1,
      //       };

      //       localStorage.setItem('checkout', JSON.stringify(data))

      //       axios
      //       .post(
      //           "https://api.meddaily.in/checkout",
      //            data ,
      //           { headers: { token: sessionStorage.getItem("token") } }
      //           )
      //           .then((response) => {
      //             console.log(response.data);
      //             // alert('order success');
      //             // navigate('/home')
      //           })
      //           .catch((error) => {
      //             alert("Error");
      //             console.log("error:", error);
      //             // console.error('Error adding to cart:', error);
      //           });
      //         }
      //       })
      //       .catch((error) => {
      //         alert("Error");
      //         console.log("error:", error);
      //         // console.error('Error adding to cart:', error);
      //       });
      //       return;

      // const data = {
      //   price: itemTotal * 1 + itemTaxTotal * 1,
      //   originalPrice: itemTotal,
      //   tax: itemTaxTotal,
      //   deliveryCost: 0,
      //   payment_type: 1,
      //   bonus_quantity: cartData[0].quantity > 0 ? cartData[0].quantity : 1,
      //   delivery_fee: deliveryfee === 118 ? true : false
      // };

      // axios
      //   .post(
      //     "/checkout",
      //     data,
      //     { headers: { token: sessionStorage.getItem("token") } }
      //   )
      //   .then((response) => {
      //     console.log(response.data);
      //     //   alert('order success');
      //     if (response.data) {
      //       window.open(
      //         response?.data?.data?.instrumentResponse?.redirectInfo?.url,
      //         "__blank"
      //       );
      //       localStorage.setItem('checkout', JSON.stringify(data))
      //       deleteCartFunction("", cartData[0]._id);
      //       alert("order success");
      //       setTimeout(() => {
      //         navigate("/orderlist");
      //         setIsSubmitting(false);
      //       }, 2000);
      //     }
      //   })
      //   .catch((error) => {
      //     alert("Error");
      //     console.log("error:", error);
      //     // console.error('Error adding to cart:', error);
      //   });
    } else if (selectedType == "Cash On Delivry") {
      const data = {
        price: itemTotal * 1 + itemTaxTotal * 1 + deliveryfee * 1,
        originalPrice: itemTotal,
        tax: itemTaxTotal,
        deliveryCost: deliveryfee * 1,
        payment_type: 1,
        bonus_quantity: cartData[0].quantity > 0 ? cartData[0].quantity : 1,
        delivery_fee: deliveryfee === 118 ? true : false,
      };

      console.log("?????????????????????", data);

      axios
        .post("/checkout", data, {
          headers: { token: sessionStorage.getItem("token") },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status === false) {
            alert(response.data.message);
            navigate(`/home`);
          } else if (response.data) {
            deleteCartFunction("", cartData[0]._id);
            alert("order success");
            setTimeout(() => {
              navigate("/orderlist");
              setIsSubmitting(false);
            }, 2000);
          }
        })
        .catch((error) => {
          alert("Error");
          console.log("error:", error);
          // console.error('Error adding to cart:', error);
        });
    } else if (selectedType == "Pay On Credit") {
      const data = {
        price: itemTotal * 1 + itemTaxTotal * 1 + deliveryfee * 1,
        originalPrice: itemTotal,
        tax: itemTaxTotal,
        deliveryCost: deliveryfee * 1,
        payment_type: 3,
        bonus_quantity: cartData[0].quantity > 0 ? cartData[0].quantity : 1,
        delivery_fee: deliveryfee === 118 ? true : false,
      };

      axios
        .post("/checkout", data, {
          headers: { token: sessionStorage.getItem("token") },
        })
        .then((response) => {
          console.log(response.data);
          if (response.data.status === false) {
            alert(response.data.message);
            navigate(`/home`);
          } else if (response.data) {
            deleteCartFunction("", cartData[0]._id);
            alert("order success");
            setTimeout(() => {
              navigate("/orderlist");
              setIsSubmitting(false);
            }, 2000);
          }
        })
        .catch((error) => {
          alert("Error");
          console.log("error:", error);
          // console.error('Error adding to cart:', error);
        });
    }
  };

  console.log("cartData", cartData);
  return (
    <>
      {/* <Navbar /> */}
      <Navbar1 />
      {/* {cartData && cartData.length === 0 ? */}
      <Typography
        variant="h4"
        style={{ margin: "5% 12% 10px", fontStyle: "normal" }}
      >
        Order Summary
      </Typography>
      {cartData?.length > 0 ? (
        <div className="med-details-div">
          <div className="medicine-quantity">
            <div style={{ marginTop: "24px" }}>
              <Card>
                <Table style={{ border: "none" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Product</TableCell>
                      <TableCell align="left">Price </TableCell>
                      <TableCell align="center">Quantity</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {cartData &&
                      cartData?.length > 0 &&
                      cartData.map((cart, index) => (
                        <TableRow key={cart.id}>
                          <TableCell align="left">
                            {cart.product_name}
                          </TableCell>
                          <TableCell align="left" style={{ color: "#6EAFAB" }}>
                            Rs. {cart.itemPrice || 0}
                          </TableCell>

                          <TableCell align="center">
                            <Button
                              style={{
                                fontSize: "20px",
                                borderColor: "#6EAFAB",
                                width: "10%",
                              }}
                              onClick={(e) =>
                                decrementQuantity(cart._id, index)
                              }
                            >
                              <RemoveIcon />
                            </Button>

                            <input
                              className="quantity-inp"
                              type="text"
                              value={cart.quantity}
                              name="quantity"
                              style={{ width: "25px" }}
                              onChange={(e) =>
                                handleQuantityChange(cart._id, e)
                              }
                            ></input>

                            <Button
                              onClick={(e) => incrementQuantity(cart._id)}
                            >
                              <AddIcon />
                            </Button>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Card>
            </div>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setIsSubmitting(true);
                setTimeout(() => {
                  navigate("/home");
                }, 2000);
              }}
            >
              +Add more items
            </Button>
            <div className="bill-details w-80">
              <Typography variant="h6" mt={4} ml={2}>
                <strong>Bill Details</strong>
              </Typography>
              <>
                <Typography mt={1} ml={2} style={{ color: "#7D8FAB" }}>
                  Item Total :
                  <span className="priceData" style={{ marginLeft: "147px" }}>
                    Rs. {itemTotal || 0}
                  </span>{" "}
                </Typography>
                <Typography mt={1} ml={2} style={{ color: "#7D8FAB" }}>
                  Delivery Fee:
                  <span className="priceData" style={{ marginLeft: "136px" }}>
                    Rs. {itemTotal === 0 ? "0" : deliveryfee || 0}
                  </span>
                  {/* <span style={{ marginLeft: "10px" }}>
                    <input type="checkbox" onChange={handelDelivery} />
                    Delivery between 10amm to next day 10 am
                  </span> */}
                </Typography>
                <Typography mt={1} ml={2} style={{ color: "#7D8FAB" }}>
                  Taxes and charges:
                  <span style={{ marginLeft: "80px" }}>
                    Rs. {itemTaxTotal || 0}
                  </span>
                </Typography>
              </>
              <hr></hr>
              <Typography mt={1} ml={2}>
                <strong>
                  Order Total:
                  <span className="priceData" style={{ marginLeft: "137px" }}>
                    Rs.{" "}
                    {itemTotal +
                      itemTaxTotal +
                      parseInt(itemTotal === 0 ? "0" : deliveryfee || 0)}
                  </span>
                </strong>
              </Typography>

              <div style={{ marginTop: "20px" }}>
                <Typography variant="h6" mt={2} ml={2}>
                  <strong>Payment Options</strong>
                </Typography>

                <FormControl
                  sx={{ "@media (max-width:992px)": { paddingLeft: "20px" } }}
                >
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      control={<Radio size="small" />}
                      id="Cash On Delivry"
                      name="selectedType"
                      value="Cash On Delivry"
                      onChange={handleChangeRadio}
                      checked={selectedType === "Cash On Delivry"}
                      label="Cash On Delivery"
                    />
                    <FormControlLabel
                      control={<Radio size="small" />}
                      id="Pay Now"
                      name="selectedType"
                      value="Pay Now"
                      onChange={handleChangeRadio}
                      checked={selectedType === "Pay Now"}
                      label="Pay Now"
                    />

                    <FormControlLabel
                      control={<Radio size="small" />}
                      id="Pay On Credit"
                      name="selectedType"
                      value="Pay On Credit"
                      onChange={handleChangeRadio}
                      checked={selectedType === "Pay On Credit"}
                      label="Pay On Credit"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <button className="proceed-btn" onClick={checkOut}>
                {" "}
                Proceed{" "}
              </button>
              <Button
                onClick={(e) =>
                  deleteCartFunction(
                    e,
                    cartData.map((item) => item._id)
                  )
                }
                variant="text"
                style={{ marginLeft: "5px" }}
                color="error"
              >
                Delete
              </Button>
            </div>
          </div>

          <div className="offers-div">
            {/* {offer?.data &&
              offer.data.length > 0 &&
              offer.data.map((l) => {
                return <Offer offers={l} />;
              })} */}
            {dioffer &&
              dioffer?.length > 0 &&
              dioffer?.map((l) => {
                return <Offer offers={l} />;
              })}
          </div>
        </div>
      ) : (
        <div className="med-details-div">
          <h2 style={{}}>Cart is Empty !!</h2>
        </div>
      )}
      <div className="promotion">
        <div>
          <Typography variant="h5" style={{ color: "white" }}>
            One-Stop Solution To Your Medicine Supply Chain <br></br>
            Download The Med-Daily Mobile App
          </Typography>
          <img src={google} alt="logo" className="download" />
          <img src={app} alt="logo" className="download" />
        </div>
        <div className="mobile-img">
          <img src={mobile} alt="mobile_img" />
        </div>
      </div>
      <Footer />
      {isSubmitting ? <LoaderModal /> : ""}
    </>
  );
};

export default OrderSummary;

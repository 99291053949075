import React, { useState, useEffect } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
	fetchdisorderDispatch,
	fetchdisproductDispatch,
	fetchdisInventaryDispatch,
	fetchretailerprofileDispatch
} from '../reducers/HomeReducer';

const Dashboard = () => {
	const { retailerProfile } = useSelector((state) => state.home);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchretailerprofileDispatch())
		dispatch(fetchdisorderDispatch());
		dispatch(fetchdisproductDispatch());
		dispatch(fetchdisInventaryDispatch());
	}, []);
	return (
		<>
			<Box
				style={{
					background: '#fff',
					borderRadius: '10px',
					boxShadow: '0 15px 30px hsla(0,0%,55%,.15)',
					marginLeft: '1.5rem',
					minWidth: '12rem',
					padding: '2rem',
				}}>
				<span style={{ color: 'blue', fontSize: 26 }}>
					Hello {retailerProfile?.data?.ownername} ! 🎉
				</span>
				<p>
					Your business name is {retailerProfile?.data?.businessname} . Check
					your new badge in your profile.
				</p>
			</Box>
		</>
	);
};
export default Dashboard;

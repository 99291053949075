import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { fetchAllOrderDispatch } from '../reducers/HomeReducer';

function createData(
	name: string,
	calories: number,
	fat: number,
	carbs: number,
	protein: number

) {
	return { name, calories, fat, carbs, protein };
}
const head = ['ORDER ID', 'RETAILER NAME', 'PAYMENT TYPE', 'PRICE', 'ACTION'];

const rows = [
	createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
	createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
	createData('Eclair', 262, 16.0, 24, 6.0),
	createData('Cupcake', 305, 3.7, 67, 4.3),
	createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function ProductList() {
	const { disorder } = useSelector((state) => state.home);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(fetchAllOrderDispatch());
	}, []);
	console.log("???????????>>>>>>>>>>>>>>>>>>??????????????", disorder);
	return (
		<div className='content-wrapper'>
			<div className='container-xxl flex-grow-1 container-p-y'>
				<div className='row'>
					<div className='card mb-12'>
						{/* <div className='card-header d-flex justify-content-between align-items-center'></div> */}
						<h1 className='card-header float-start'>Order Table</h1>

						<hr className='my-0' />
						<div className='card-body'>
							<div className='container-xxl flex-grow-1 container-p-y'>
								<div className='row'>
									<div className='card'>
										<div className='table-responsive text-nowrap'>
											<TableContainer component={Paper}>
												<Table
													sx={{ minWidth: 650 }}
													size='small'
													aria-label='a dense table'>
													<TableHead>
														<TableRow>
															{head.map((i) => {
																return <TableCell>{i}</TableCell>;
															})}
														</TableRow>
													</TableHead>
													<TableBody>
														{disorder?.data &&
															disorder?.data.length > 0 &&
															disorder?.data.map((row) => (
																<TableRow
																	key={row._id}
																	sx={{
																		'&:last-child td, &:last-child th': {
																			border: 0,
																		},
																	}}>
																	<TableCell
																		component='th'
																		scope='row'
																		style={{ padding: 15, textAlign: 'start' }}>
																		{row.order_id}
																	</TableCell>
																	<TableCell
																		align='right'
																		style={{ padding: 15, textAlign: 'start' }}>
																		{row.retailer_name}
																	</TableCell>
																	<TableCell
																		align='right'
																		style={{ padding: 15, textAlign: 'start' }}>
																		{row.payment_type}
																	</TableCell>
																	<TableCell
																		align='right'
																		style={{ padding: 15, textAlign: 'start' }}>
																		{row.price}
																	</TableCell>
																	<TableCell
																		onClick={() =>
																			// navigate({
																			// 	pathname: `/disorderdetail/${row.order_id}`,
																			// 	state: row,
																			// })
																			navigate(
																				`/disorderdetail/${row.order_id}`,
																				{ state: row }
																			)
																		}
																		align='right'
																		style={{
																			padding: 15,
																			textAlign: 'start',
																			cursor: 'pointer',
																		}}>
																		View Details
																	</TableCell>
																</TableRow>
															))}
													</TableBody>
												</Table>
											</TableContainer>
										</div>
									</div>
								</div>

								<div className='content-backdrop fade'></div>
							</div>
						</div>
					</div>
				</div>

				<div className='content-backdrop fade'></div>
			</div>
		</div>
	);
}

import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Carousel } from 'react-bootstrap';
import axios from "axios";
import { axiosInstance } from "../Axios";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

const Card = () => {
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
     axiosInstance.get("/get_product", {
        headers: { 'token': sessionStorage.getItem('token') }
      })
      .then((res) => {
        setCardData(res.data.product);
      })
      .catch(error => console.error('Error fetching banner data:', error));
  }, []);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 < 0 ? cardData.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1 >= cardData.length ? 0 : prevIndex + 1));
  };
  const cardContainerStyle = {
    display: "flex",
    width: "100%",
    overflow: "hidden",
  };
  return (
    <>
      <div style={{display:"flex",justifyContent:"center"}}>
        <div className="d-flex scroll-container">
          <button className="button-card" onClick={handlePrev}>
            <FaChevronLeft />
          </button>
          <div style={{ width: '1400px' }}>
            <div className="" style={cardContainerStyle}>
              {cardData?.map((cardItem) => (
                <div className="card" key={cardItem._id} style={{
                  margin: "15px", width: "250px", transform: `translateX(${-currentIndex * 250}px)`, 
                  transition: "transform 0.5s ease",flex: '0 0 auto'
                }}>
                  <Typography variant="h6" style={{ fontSize: "20px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', color: "black" }}>
                    {cardItem.title} <br />
                  </Typography>
                  <Typography mt={2} style={{ fontSize: '12px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {cardItem.sub_title}
                  </Typography>
                  <Typography mt={3} mb={2} variant="h6" style={{ color: "black" }} >
                    Best Price* <span style={{ color: "#30d5c8" }}>₹ {cardItem?.distributors[0]?.price} </span>
                  </Typography>
                  <div style={{ marginTop: 'auto' }}>
                    <Link to={`/details/${cardItem._id}`}>
                      <button className="buy-now-btn" style={{ marginBottom: '2px' }}>
                        Buy Now
                      </button>
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <button className="button-card" onClick={handleNext}>
            <FaChevronRight />
          </button>
        </div>
      </div>
    </>
  );
};

export default Card;

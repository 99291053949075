import { useDispatch, useSelector } from 'react-redux';
import offer_logo from '../image/catalog/demo/banners/Offer.png';
import { Typography } from '@mui/material';
import { setSelectedOffer } from '../reducers/HomeReducer';
const Offer = ({ offers }) => {
	const dispatch = useDispatch();
	const { offerSelect } = useSelector((state) => state.home);
	// console.log("??????????????<<<<<<<<<MMMMMMMMMMMM",offerSelect );
	return (
		<>
			<div
				className='offer-item'
				onClick={() => dispatch(setSelectedOffer(offers))}>
				{/* <img src={offers?.image} alt='offer_logo' width='60px' height='60px' /> */}
				{/* https://th.bing.com/th/id/OIP.UGwtLFUqjmH6_H8-beI9cQHaHa?pid=ImgDet&rs=1 */}
				<img src="https://th.bing.com/th/id/OIP.UGwtLFUqjmH6_H8-beI9cQHaHa?pid=ImgDet&rs=1" alt='offer_logo' width='60px' height='60px' />

				<div>
					<Typography ml={2}>
						{/* <strong>{offers?.title}</strong> */}
						<strong>New Year Offer</strong>

					</Typography>
					<Typography mt={1} ml={2}>
						{/* {offers?.name} */}
						 <div> This is the offer for new Year Celebration  </div>
						
						you get {offers?.purchase_quantity} products free on perchase of  {offers?.bonus_quantity} products

					</Typography>
				</div>
				{offerSelect?._id === offers?._id ? (
					<img
						style={{ marginLeft: 5 }}
						src={
							'https://static.vecteezy.com/system/resources/previews/010/157/635/original/tick-icon-accept-approve-sign-design-free-png.png'
						}
						alt='offer_logo'
						width='40px'
						height='40px'
					/>
				) : (
					''
				)}
			</div>
		</>
	);
};

export default Offer;

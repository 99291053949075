import Typography from "@mui/material/Typography";
import "../css/footer.css";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import footer_logo from "../image/catalog/demo/banners/footer-logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <div className="footer-cover">
        <div className="footer">
          <div className="div-1">
            <Typography variant="h5">
              <Link style={{ textDecoration: "none", color: "white" }} to="/">
                Med-Daily
              </Link>
            </Typography>
            <Typography style={{ color: "#565555" }} mt={3}>
              AT- MALLIPUR , NIALA , KHURDA , Pin- 752055 , Dist - KHURDA ,
              State- ODISHA .
            </Typography>
          </div>
          <div className="div-2">
            <Typography variant="h5" style={{ color: "#30d5c8" }}>
              Company
            </Typography>
            <Typography variant="h5" mt={2} style={{ fontSize: "15px" }}>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/privacy"
              >
                {" "}
                Privacy Policy
              </Link>
            </Typography>
            <Typography variant="h5" mt={2} style={{ fontSize: "15px" }}>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/termscondition"
              >
                Terms & Conditions
              </Link>{" "}
            </Typography>
            <Typography variant="h5" mt={2} style={{ fontSize: "15px" }}>
              <Link
                style={{ textDecoration: "none", color: "white" }}
                to="/return-policy"
              >
                Refund & Return Policy
              </Link>
            </Typography>
          </div>
          <div className="div-3">
            <Typography variant="h5" style={{ color: "#30d5c8" }}>
              <Link
                style={{ textDecoration: "none", color: "#30d5c8" }}
                to="/contactus"
              >
                Connect
              </Link>{" "}
            </Typography>
            <Typography variant="h5" mt={3} style={{ fontSize: "15px" }}>
              <LocalPhoneIcon /> +91 9090158159
            </Typography>
            <Typography variant="h5" mt={2} style={{ fontSize: "15px" }}>
              <EmailIcon /> admin@meddaily.in
            </Typography>
            <Typography
              variant="h5"
              mt={2}
              style={{ fontSize: "15px", color: "#30d5c8" }}
            >
              Social Media
            </Typography>
            <br></br>
            <FacebookIcon fontSize="large" />
            <InstagramIcon fontSize="large" />
          </div>
        </div>
        <div className="footer-line"></div>
        <div className="copy-right">
          <div>
            <Link style={{ textDecoration: "none" }} to="/">
              <img src={footer_logo} alt="footer_logo" />
            </Link>
          </div>
          <div>
            {" "}
            <Link
              style={{
                textDecoration: "none",
                color: "white",
                fontSize: "20px",
              }}
              to="/termscondition"
            >
              Terms & Conditions
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

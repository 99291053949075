import React from 'react';
import DrawerNavigator from '../DrawerNavigator';
const Layout = ({ children }) => {
	return (
		<>
			<DrawerNavigator>{children}</DrawerNavigator>
		</>
	);
};

export default Layout;

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import "./style.css";

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ImageSlider = ({ images }) => {
	const settings = {
		infinite: true,
		dots: true,
		slidesToShow: 1,
		slidesToScroll: 1,
		lazyLoad: true,
		autoplay: true,
		autoplaySpeed: 5000,
	};

	const [banners, setBanners] = useState(null);

	useEffect(() => {
		axios
			.get('https://meddaily.onrender.com/retailer_home', {
				headers: { token: sessionStorage.getItem('token') },
			})
			.then((res) => {
				console.log('car', res.data);
				setBanners(res.data.bannerdata);
			})
			.catch((error) => {
				console.log(error);
			});
	}, []);
	console.log('banners', banners);
	return (
		<>
			<Slider {...settings}>
				{/* {banners && (
          <div key={banners.id}>
            <img src={banners.image} alt={banners.alt} className="hero-img" />
          </div>
        )}  */}

				{banners?.map((item) => (
					<div key={item._id}>
						<img src={item.image} alt={item.name} className='hero-img' />
					</div>
				))}
			</Slider>
		</>
	);
};
export default ImageSlider;

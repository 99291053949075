import { Box, Grid, Typography } from '@mui/material';
import contact_image from '../image/contact.jpeg';
import Navbar from '../components/Navbar';
import Footer from '../components/footer';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import { GoogleMap, LoadScript } from '@react-google-maps/api';
import '../css/contact.css';

const containerStyle = {
	width: '100%',
	height: '100%',
};

const center = {
	lat: -3.745,
	lng: -38.523,
};
const ContactUs = () => {
	return (
		<>
			<Navbar />
			<div className='image-privacy-div'>
				<img
					src={contact_image}
					alt='privacy_img'
					width='100%'
					height='400'
					className='privacy-img'
				/>
				<div className='centered'>
					<Typography variant='h2'> Contact Us</Typography>
					<Typography variant='h4' mt={1}>
						{' '}
						Communication is the lifeline of any relationship
					</Typography>
				</div>
			</div>

			<div className='contact-details'>
				<Grid container spacing={4}>
					<Grid item xs={12} lg={4}>
						{/* <Box style={{ display: 'flex', alignItem: 'center' }}>
							<PhoneAndroidIcon color='#0A7621' />
							<p>Phone</p>
						</Box>
						<span style={{ marginLeft: 10 }}>+919090158159</span> */}
						{/* <div class='vl'></div> */}
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}>
							<Box>
								<Box style={{ display: 'flex', alignItems: 'center' }}>
									<PhoneAndroidIcon color='#0A7621' />
									<Typography mt={2} mb={2} ml={2}>
										Phone
									</Typography>
								</Box>
								<Typography>+91 9090158159</Typography>
							</Box>
							<div class='vl'></div>
						</Box>
					</Grid>
					<Grid item xs={12} lg={4}>
						<Box
							style={{
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'space-between',
							}}>
							<Box>
								<Box style={{ display: 'flex', alignItems: 'center' }}>
									<LocationOnIcon />
									<Typography mt={2} mb={2} ml={2}>
										Address
									</Typography>
								</Box>
								<Typography>
									AT - MALLIPUR , NIALA , KHURDA , Pin- 752055 , Dist - KHURDA ,
									State- ODISHA .
								</Typography>
							</Box>
							<div class='vl'></div>
						</Box>
					</Grid>
					<Grid item xs={12} lg={4}>
						<Box style={{ display: 'flex', alignItems: 'center' }}>
							<EmailIcon />
							<Typography mt={2} mb={2} ml={2}>
								Email
							</Typography>
						</Box>
						<Typography>admin@meddaily.in</Typography>
					</Grid>
				</Grid>
				{/* <div className='phone-div'></div>
				<div class='vl'></div>
				<div className='phone-div'>
					<div>
						<LocationOnIcon />
						<Typography mt={2} mb={2}>
							Address
						</Typography>
						<Typography>
							AT - MALLIPUR , NIALA , KHURDA , Pin- 752055 , Dist - KHURDA ,
							State- ODISHA .
						</Typography>
					</div>
				</div>
				<div class='vl'></div>
				<div className='email-div'>
					<EmailIcon />
					<Typography mt={2} mb={2}>
						Email
					</Typography>
					<Typography>admin@meddaily.in</Typography>
				</div> */}
			</div>
			<div className='get-in-touch'>
				<Typography variant='h3' mb={2}>
					Get in Touch
				</Typography>
				<div className='map-contact'>
					<div>
						<LoadScript googleMapsApiKey='YOUR_API_KEY'>
							<GoogleMap
								mapContainerStyle={containerStyle}
								center={center}
								zoom={10}
								fullwidth>
								{/* Child components, such as markers, info windows, etc. */}
								<></>
							</GoogleMap>
						</LoadScript>
					</div>
					<div className='form-div'>
						<input className='inp-field' placeholder='First Name'></input>
						<input className='inp-field' placeholder='Last Name'></input>
						<input className='inp-field' placeholder='Email'></input>
						<input className='inp-field' placeholder='Phone No.'></input>
						<textarea className='inp-field' placeholder='Message'></textarea>
						<button className='req-btn'>Request Info</button>
					</div>
				</div>
			</div>

			<Footer />
		</>
	);
};

export default ContactUs;

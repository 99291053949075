import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import MoreIcon from '@mui/icons-material/MoreVert';
import logo from "../image/catalog/logonew.jpg";
import cartIcon from "../image/cart.png";

import { Link, useNavigate } from "react-router-dom";
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux";
import { getSearch } from "../reducers/HomeReducer";
import { axiosInstance } from "../Axios";
import Autocomplete from '@mui/material/Autocomplete';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';



const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default function PrimarySearchAppBar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [searchBar, setsearchBar] = React.useState('');
  
  const [searchContent, setsearchContent] = React.useState('');
  const dispatch = useDispatch();
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const navigate = useNavigate();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const [dense, setDense] = React.useState(false);
  const [secondary, setSecondary] = React.useState(false);

  const handleSearchBar = (e) => {
    e.preventDefault()
    var search = !searchBar
    setsearchBar(search)
  };

  const handleDashboard = (e) => {
    navigate("/dashboard");
  };
  const handleCart = (e) => {
    navigate("/ordersummary");
  };
  const handleLogout = (e) => {
    sessionStorage.removeItem("token");
    // sessionStorage.removeItem('loginUser')
    navigate("/");
  };
  const handleSetting = (e) => {
    navigate("/");
  };

  const handleSearch = async (e)=> {
      e.preventDefault()
     
      console.log(e.target.value)

      // setsearchBar(e.target.value)

      var data = {
        field_name: "title",
        value:e.target.value,
      }

       axiosInstance.get(
        "/product_search",
        {params: {
          field_name: data.field_name,
          value: data.value,
        },},
        config
        ,
      ).then((f)=>{
        // console.log('search', f)
        if(f.data){
          setsearchContent(f?.data?.product)
        }
      });
      // console.log(data)
      
      
  
      


  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));
  

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
          <MenuItem onClick={handleDashboard}>My Profile</MenuItem>
            <MenuItem onClick={handleSetting}>Settings</MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>

    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      {/* <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={4} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={17} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem> */}

             <MenuItem onClick={handleSearchBar}>Search</MenuItem>

            <MenuItem onClick={handleCart}>Cart</MenuItem>
     
            <MenuItem onClick={handleDashboard}>My Profile</MenuItem>
            <MenuItem onClick={handleSetting}>Settings</MenuItem>
            <MenuItem onClick={() => handleLogout()}>Logout</MenuItem>
         
    </Menu>
  );

  return (
    <>
 
    <Box sx={{ flexGrow: 1 }} >
      <AppBar position="static" style={{backgroundColor:"#6eafab"}}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 , display:{sm: "none", md: "none", lg:"none", xs:"none"}}}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            noWrap
            component="div"
          
          >
            <Link to={"/home"}>
            <img src={logo} alt="logo" width="200px" />
          </Link>
          </Typography>
          
       
          <HtmlTooltip
        title={
          <React.Fragment>
          {searchContent.length>0 && (
            <div>
              <ul>
              {searchContent.map((f)=>{
              return  <li onClick={(e)=>{e.preventDefault(); navigate(`/details/${f._id}`)}} style={{cursor:"pointer"}}>{f?.name}</li>
            })}
            </ul>
            </div>
          ) }
          </React.Fragment>
        }
      >
      <Search sx={{display:{xs:'none' , sm:"block", md:"block", lg:"block"}}}>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
               onChange={handleSearch}
              inputProps={{ 'aria-label': 'search' }}
             
            />
          </Search>
      </HtmlTooltip>
        

          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { xs: 'none', md: 'flex', sm:"flex" } }}>
            {/* <IconButton size="large" aria-label="show 4 new mails" color="inherit">
              <Badge badgeContent={4} color="error">
                <MailIcon />
              </Badge>
            </IconButton>*/}
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              color="inherit"
            >
            <Link to="/ordersummary">
            <button className="btn-cart">
              <div className="div-cart-btn">
                <div className="cart_logo_div">
                  <img
                    src={cartIcon}
                    className="cart"
                    alt="cart"
                    width="30px"
                  />
                  {/* <ShoppingCartIcon className='cart' /> */}
                </div>
              </div>
            </button>
          </Link>
            </IconButton> 

               
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Box sx={{ display: { xs: 'flex', md: 'none' , sm:"none" } }}>
            
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </Box>
    
    {/* <Dialog sx={{display:{lg:"none", md:'none', sm:'none'}}} style={{width:"100%"}} onClose={handleSearchBar} open={searchBar}>
      <DialogTitle>Search Items</DialogTitle>
      <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1, width: '25ch' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField id="outlined-basic" label="Search" variant="outlined" autoComplete='off'/>
      
    </Box>
    </Dialog> */}
    </>
  );
}

import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import login_image from "../image/bg.svg";
import med_logo from "../image/MEDDAILY-LOGO-inverted.png";
import play_app_icon from "../image/catalog/app.png";
import "../css/login.css";
import { Box, Typography } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { useDispatch, useSelector } from "react-redux";
import { loginDispatch } from "../reducers/HomeReducer";
import LoaderModal from "../components/resuableComponents/LoaderModal";

const logInSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .min(2, "Too Short")
    .max(11, "Too Long")
    .required("Required"),
  password: Yup.string()
    .required("password is required")
    .min(4, "Password is too short - should be 4 chars minimum"),
});

const Login = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const { loading } = useSelector((state) => state.home);
  const [data, setData] = useState(false);
  // console.log('login', login.status);

  // const [isMessage, setIsMessage] = useState(null);
  // const [loginData, setLoginData] = useState({
  // 	phone: '',
  // 	password: '',
  // });

  // const handleChange = (e) => {
  // 	const { id, value } = e.target;
  // 	setLoginData((prevData) => ({
  // 		...prevData,
  // 		[id]: value,
  // 	}));
  // };

  // const handleLogin = (e) => {
  // 	axios
  // 		.post('https://api.meddaily.in/retailer_login', loginData)
  // 		.then((res) => {
  // 			if (res?.data?.status) {
  // 				sessionStorage.setItem('key', JSON.stringify(res.data));
  // 				sessionStorage.setItem('token', res.data.token);
  // 				navigate('/home');
  // 			} else {
  // 				alert('Invalid Credentials!');
  // 				// navigate('/login')
  // 			}
  // 		})
  // 		.catch((error) => {
  // 			console.log(error);
  // 		});
  // };

  return (
    <>
      {isSubmitting ? (
        <LoaderModal />
      ) : (
        <div className="login-body">
          <div className="big-img-div">
            <img src={login_image} alt="login" className="img-login" />
          </div>

          <div className="form-div-1">
            <Link style={{ textDecoration: "none" }} to="/">
              <img src={med_logo} alt="company_logo" width="250" height="100" />
            </Link>

            <div className="form">
              <Typography variant="h4" mt={8}>
                <strong>Log In</strong>
              </Typography>
              <Typography color="#2D3748" mt={2} mb={4}>
                For Retailers.
              </Typography>

              <div className="div-for-data">
                <Formik
                  initialValues={{
                    phoneNumber: "",
                    password: "",
                  }}
                  validationSchema={logInSchema}
                  onSubmit={(values) => {

                    let formData = new FormData();

                    formData.append('phone', values.phoneNumber);
                    formData.append('password', values.password);

                    setIsSubmitting(true)
                    dispatch(loginDispatch(values, navigate),setIsSubmitting(false));
                    console.log("login", values);
                  }}
                >
                  {(formik) => {
                    const { errors, touched, isValid, dirty } = formik;
                    return (
                      <Form>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          sx={{
                            flexWrap: {
                              xs: "wrap",
                              sm: "no-wrap",
                              md: "no-wrap",
                              lg: "no-wrap",
                            },
                          }}
                        >
                          <div>
                            <Typography>
                              <strong>Phone Number</strong>
                            </Typography>
                            <Field
                              type="phoneNumber"
                              name="phoneNumber"
                              style={{
                                height: "45px",
                                marginTop: "15px",
                                width: "15rem",
                                borderRadius: "10px",
                                // border: 'none',
                                // outline: 'none',
                                borderColor: errors.phoneNumber ? "red" : "",
                              }}
                              placeholder="Phone Number"
                              // className='form-data'
                              id="phoneNumber"
                              // onChange={handleChange}
                              // value={loginData.phone}
                              className="form-control"
                            />

                            {errors.phoneNumber ? (
                              <span style={{ color: "red" }}>
                                {errors.phoneNumber}
                              </span>
                            ) : null}
                          </div>

                          <div>
                            <Typography>
                              <strong>Password</strong>
                            </Typography>
                            <Field
                              style={{
                                height: "45px",
                                marginTop: "15px",
                                width: "15rem",
                                borderRadius: "10px",
                                borderColor: errors.password ? "red" : "",
                              }}
                              placeholder="Password"
                              // className='form-data'
                              id="password"
                              // value={loginData.password}
                              name="password"
                              type="password"
                              // onChange={handleChange}
                              className="form-control"
                            />
                            {errors.password ? (
                              <span style={{ color: "red" }}>
                                {errors.password}
                              </span>
                            ) : null}
                          </div>
                        </Box>
                        <div className="create-acc-div">
                          <button className="register-button" type="submit">
                            Log In
                          </button>
                          <Typography mt={2} mb={6}>
                            {" "}
                            <Link to="/retailer_forgot">Forgot Password?</Link>
                          </Typography>
                          <Typography mt={2} mb={6}>
                            Don’t have an account?{" "}
                            <Link to="/retailer_signup">Sign up</Link>
                          </Typography>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
            <img src={play_app_icon} alt="logo" className="play_app_icon" />
          </div>
        </div>
      )}
    </>
  );
};

export default Login;

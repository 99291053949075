import React from 'react';
import { Typography } from '@mui/material';

const TitleText = ({ children, two, one, four, textStyle }) => {
	return (
		<Typography variant='h6' style={textStyle} title={children}>
			{children}
		</Typography>
	);
};

export default TitleText;

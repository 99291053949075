import * as React from "react";
import AppBar from "@mui/material/AppBar";

import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import "../css/navbarleading.css";
import { Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
// import landingLogo from "../image/MEDDAILY-LOGO-inverted.png";
import logo from "../image/catalog/logonew.jpg";
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 240;
const navItems = [{ name: 'Home', link: '/home' }, , { name: 'About', link: '/' }, { name: 'Distributor Registration', link: '/distributor_signup' }, { name: 'Distributor Login', link: '/distributor.meddaily.in/distributor/login' }, { name: 'Contact', link: '/contactus' }, { name: 'Sign In', link: '/retailer_login' }, { name: 'Sign Up', link: '/retailer_signup' }];

export default function NavbarLanding(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const routerLink = (link) => {
    // console.log(link);
    <Link to={link}></Link>
  }

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      {/* <Typography variant="h6" sx={{ my: 2 }}>
        MUI
      </Typography>
      <Divider /> */}
      <List>
        {navItems.map((item) => (
          <ListItem key={item.name} disablePadding>
            <ListItemButton onClick={() => {
              console.log(item.link)
              if (item.name === "Distributor Login") {
                window.location.href = "https://distributor.meddaily.in/distributor/login"
              } else {
                navigate(item.link)
              }

            }} sx={{ textAlign: 'center' }}>
              <ListItemText primary={item.name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>

    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <CssBaseline />
      <AppBar position="static">

        <Toolbar className="navbarlanding">
          <div style={{ marginRight: "80%", position: "absolute" }} className="menuList">
            <Grid item xs={12} lg={2} className="menuList">

              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}

              >
                <MenuIcon />
              </IconButton>


            </Grid>
          </div>
          <Grid item xs={12} lg={2}>
            <Link to={"/home"}>
              <img src={logo} alt="logo" width="200px" />
            </Link>
          </Grid>

          <Grid item xs={12} lg={8} classname="miidlebutton" sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Stack direction="row" spacing={3}>
              <Typography variant="h6">
                <Link to="/" style={{ textDecoration: "none", color: "#ffff" }}>
                  Home
                </Link>
              </Typography>

              <Typography variant="h6">
                <Link to="/" style={{ textDecoration: "none", color: "#ffff" }}>
                  About
                </Link>
              </Typography>

              <Typography variant="h6">
                <Link
                  to="/distributor_signup"
                  style={{ textDecoration: "none", color: "#ffff" }}
                >
                  Distributor Registration
                </Link>
              </Typography>

              <Typography variant="h6">
                <a
                  href="https://distributor.meddaily.in/distributor/login"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "#ffffff" }}
                >
                  Distributor Login
                </a>
              </Typography>

              <Typography variant="h6">
                <Link
                  to="/contactus"
                  style={{ textDecoration: "none", color: "#ffff" }}
                >
                  Contact
                </Link>
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} lg={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Stack direction="row" spacing={3}>
              <Typography variant="h6">
                <Link
                  to="/retailer_signup"
                  style={{ textDecoration: "none", color: "#ffff" }}
                >
                  Sign up
                </Link>
              </Typography>
              <Link to="/retailer_login">
                <Button style={{ backgroundColor: "#ffff", color: "black" }}>
                  Login
                </Button>
              </Link>
            </Stack>
          </Grid>
        </Toolbar>
      </AppBar>

      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

import Navbar from '../components/Navbar';
import Navbar2 from '../components/navBar_2';

import Body from '../components/Body';
import Footer from '../components/footer';
function Home() {
	return (
		<>
			<div >
				{/* style={{ overflowX: 'hidden' }} */}
				<Navbar2 />
				<Body />
				<Footer />
			</div>
		</>
	);
}

export default Home;

import React from 'react';
import { Box, Grid } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchorderdetailsDispatch } from '../reducers/HomeReducer';
const head = ['NAME', 'PRICE'];

const Invoice = () => {
	// const { orderId } = useParams();
	const dispatch = useDispatch();
	const location = useLocation();
	useEffect(() => {
		// dispatch(fetchorderdetailsDispatch(orderId));
	}, []);
	console.log('location', location);
	const { orderDetails } = useSelector((state) => state.home);
	return (
		<>
			<Box style={{ fontSize: '25px', textAlign: 'center' }} mt={4}>
				Create Invoice
			</Box>
			<Box
				mb={2}
				mt={4}
				style={{
					background: '#fff',
					borderRadius: '10px',
					boxShadow: '0 15px 30px hsla(0,0%,55%,.15)',
					marginLeft: '1.5rem',
					minWidth: '12rem',
					padding: '2rem',
				}}>
				<Box style={{ fontSize: '25px' }}>Order Details</Box>
				<Grid container style={{ marginTop: 20 }}>
					<Grid item xs={3}></Grid>
					<Grid item xs={3}>
						<Box mb={2} style={{ fontSize: '17px' }}>
							Order Id : {location?.state?.order_id}
						</Box>
						<Box mb={1} style={{ fontSize: '17px' }}>
							Created At : {location?.state?.createdAt}
						</Box>
						<Box mb={1} style={{ fontSize: '17px' }}>
							Retailer Name : {location?.state?.retailer_name}
						</Box>
						<Box mb={1} style={{ fontSize: '17px' }}>
							Address :
						</Box>
					</Grid>
					<Grid item xs={3}></Grid>
					<Grid item xs={3}></Grid>
				</Grid>
			</Box>
			<Box
				mb={2}
				style={{
					background: '#fff',
					borderRadius: '10px',
					boxShadow: '0 15px 30px hsla(0,0%,55%,.15)',
					marginLeft: '1.5rem',
					minWidth: '12rem',
					padding: '2rem',
				}}>
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
						<TableHead>
							<TableRow>
								{head.map((i) => {
									return <TableCell>{i}</TableCell>;
								})}
							</TableRow>
						</TableHead>
						<TableBody>
							{location?.state &&
								location?.state?.products.length > 0 &&
								location?.state?.products.map((row) => (
									<TableRow
										key={row._id}
										sx={{
											'&:last-child td, &:last-child th': {
												border: 0,
											},
										}}>
										<TableCell
											component='th'
											scope='row'
											style={{ padding: 15, textAlign: 'start' }}>
											{row.name}
										</TableCell>
										<TableCell
											align='right'
											style={{ padding: 15, textAlign: 'start' }}>
											{row.price}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<Grid container style={{ marginTop: 20 }}>
					<Grid item xs={4}></Grid>
					<Grid item xs={4}>
						<Box
							mt={2}
							style={{ display: 'flex', justifyContent: 'space-between' }}>
							<span>Batch no.</span>
							<span>{location?.state?.batch_no}</span>
						</Box>
						<Box
							mt={2}
							style={{ display: 'flex', justifyContent: 'space-between' }}>
							<span>Exp Date</span>
							<span>{location?.state?.exp_date}</span>
						</Box>
					</Grid>
					<Grid item xs={4}></Grid>
				</Grid>
			</Box>
			<Box
				mb={2}
				style={{
					background: '#fff',
					borderRadius: '10px',
					boxShadow: '0 15px 30px hsla(0,0%,55%,.15)',
					marginLeft: '1.5rem',
					minWidth: '12rem',
					padding: '2rem',
				}}>
				<Box style={{ fontSize: '25px' }}>Bill Details</Box>
				<Grid container style={{ marginTop: 20 }}>
					<Grid item xs={4}></Grid>
					<Grid item xs={4}>
						<Box style={{ display: 'flex', justifyContent: 'space-between' }}>
							<span>ITEM TOTAL</span>
							<span>20</span>
						</Box>
						<Box
							mt={2}
							style={{ display: 'flex', justifyContent: 'space-between' }}>
							<span>TAX</span>
							<span>20</span>
						</Box>
						<Box
							mt={2}
							style={{ display: 'flex', justifyContent: 'space-between' }}>
							<span>DELIVERY FEE</span>
							<span>20</span>
						</Box>
						<Box
							mt={2}
							style={{ display: 'flex', justifyContent: 'space-between' }}>
							<span style={{ fontWeight: 'bold' }}>ORDER TOTAL</span>
							<span style={{ fontWeight: 'bold' }}>20</span>
						</Box>
					</Grid>
					<Grid item xs={4}></Grid>
				</Grid>

				{/* <Box mb={2} style={{ fontSize: '20px', fontWeight: '500' }}>
					Order Id: {orderDetails?.data?.order_id}
				</Box> */}
			</Box>
			<Grid container style={{ textAlign: 'center', marginBottom: 50 }}>
				<Grid item xs={4}></Grid>
				<Grid item xs={4}>
					<Box
						style={{ background: 'red', padding: '5px', borderRadius: '5px' }}>
						<span style={{ fontSize: 20, color: 'white' }}>Send & Skip</span>
					</Box>
				</Grid>
				<Grid item xs={4}></Grid>
			</Grid>
		</>
	);
};

export default Invoice;

import * as React from "react";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch, useSelector } from "react-redux";
import { fetchget_all_returnDispatch } from "../reducers/HomeReducer";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import LoaderModal from "../components/resuableComponents/LoaderModal";

function createData(
  name: string,
  calories: number,
  fat: number,
  carbs: number,
  protein: number
) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];
const head = [
  "Order ID",
  "Product",
  "Quantity",
  "TOTAL PRICE",
  "Status",
  "Details",
];
export default function ReturnList() {
  const [isLoading,setIsLoading]=useState(false)
  const [show, setShow] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { allreturn } = useSelector((state) => state.home);
  const dispatch = useDispatch();
  React.useEffect(() => {
    setIsLoading(true)
    setTimeout(() => {
      dispatch(fetchget_all_returnDispatch(),setIsLoading(false));
    }, 1000);
    
  }, []);

  //   order status: 4 = "order placed
  //   order status: 1 = "shipped
  //   order status: 3 = "delivered
  //   order status: 0 = "cancelled
  //   payment type : 1=cod
  //   payment type : 2=On Credit
  //   payment type : 3=prepaid

  function paymentType(code) {
    if (code == 1) {
      return "COD";
    } else if (code == 2) {
      return "On Credit";
    } else if (code == 3) {
      return "PrePaid";
    } else if (code == 0) {
      return "COD";
    }
  }

  function orderStatus(code) {
    if (code == 0) {
      return "Cancelled";
    } else if (code == 1) {
      return "Shipped";
    } else if (code == 3) {
      return "Delivered";
    } else if (code == 4) {
      return "Order Placed";
    }else if (code == 5) {
      return "Return Order";
    }
  }

  const data = {
    columns: [
      {
        label: "Order ID",
        field: "order_id",
      },
      {
        label: "Product Name",
        field: "product_name",
      },
      {
        label: "Quantity",
        field: "quantity",
      },
      {
        label: "Price",
        field: "price",
      },
      {
        label: "Order Status",
        field: "order_status",
      },
      {
        label: "Action",
        field: "action",
      },
    ],
    // rows: allreturn?.data?.map((row) => ({
    //   order_id: row.order_id,
    //   product_name: row.products[0].name,
    //   quantity: row.products[0].quantity,
    //   price: row.price,
    //   order_status: orderStatus(row.order_status),
    //   action: (
    //     <Link
    //       style={{ color: "black", textDecoration: "none" }}
    //       onClick={() => {
    //         setSelectedRowData(row);
    //         handleShow();
    //       }}
    //     >
    //       View Details
    //     </Link>
    //   ),
    // })),
    rows: allreturn?.data?.map((row) => {
      // Filter out orders with "RETURN" before the ID
      if (!row.order_id.startsWith("RETURN")) {
        return null;
      }
  
      return {
        order_id: row.order_id,
        product_name: row.products[0].name,
        quantity: row.products.reduce((accumulator, row) => {
          return accumulator + row.return_quantity;
        }, 0),
        price: row?.delivery_fee === true ? row?.price - 118 : row?.price,
        order_status: orderStatus(row.order_status),
        action: (
          <Link
              to={`/card/${row._id}`} // Navigate to the "/card" route
              style={{ color: "black", textDecoration: "none" }}
              onClick={() => {
                localStorage.setItem('order',row._id)
                setSelectedRowData(row);
                handleShow();
              }}
            >
              View Details
            </Link>
        ),
      };
    }).filter(Boolean),
  };

  return (
    <>
    <div style={{ backgroundColor: "white", padding: "50px" }}>
      <MDBDataTable
        hover
        entriesOptions={[5, 10, 20]}
        entries={5}
        pagesAmount={4}
        data={data}
        noBottomColumns={true}
      />

      {/* <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      > */}
        {/* <Modal.Header closeButton>
          <Modal.Title>Return Item Details</Modal.Title>
        </Modal.Header> */}
        {/* <Modal.Body>
          <div>
            <h3>Product Details</h3>
            <p>Order Id: {selectedRowData?.order_id}</p>
            <p>Expiry Date: {selectedRowData?.exp_date}</p>
            <p>Price : {selectedRowData?.price}</p>
            <p>Distributer and Retailer Details</p>
            <>Distributer Name : {selectedRowData?.distributor_name}</>
            <p>Retailer Name : {selectedRowData?.retailer_name}</p>
            <hr />
            <p>Payment Details</p>
            <p>Payment Status : {(selectedRowData?.payment_status)==4?'Payment Confirmed':(selectedRowData?.payment_status)}</p>
            <p>Payment Type : {paymentType(selectedRowData?.payment_type)}</p>
            <p>Order Status: {orderStatus(selectedRowData?.order_status)}</p>
            <p>Payment Details</p>
            <p>Return Reason: {selectedRowData?.return_reason}</p>
            <p>Return Message: {selectedRowData?.return_message}</p>
            <p>Return Quantity: {selectedRowData?.return_quantity}</p>
            <p>Return Status: {selectedRowData?.return_status==1?"Not Accepted":""}
            {selectedRowData?.return_status==2?"Return Accepted":""}
            </p>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
    {isLoading?<LoaderModal/>:""}
    </>
  );
}

import Navbar from "../components/Navbar";
import Footer from "../components/footer";
import "../css/products.css";
import { MenuItem, Typography } from "@mui/material";
import product_image from "../image/catalog/demo/banners/product.png";
import Card from "../components/card";
import Slider from "@mui/material/Slider";
import Stack from "@mui/material/Stack";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import { useEffect, useState } from "react";
import axios from "axios";
import ProductCard from "../components/Productcard";
import { NavLink, useParams } from "react-router-dom";
import { axiosInstance } from "../Axios";
import { useNavigate } from 'react-router-dom';

const Products = () => {
  const [value, setValue] = useState([1, 2]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const handleData = () => {
  //   axios
  //     .get("/get_product")
  //     .then((res) => {
  //       console.log(res.data);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // };
  // useEffect(() => {
  //   handleData();
  // }, []);

  const [selectCategoryName, setSelectCategoryName] = useState([]);
  const [distributor, setDistributor] = useState([]);
  const [selectedDistributorId, setSelectedDistributorId] = useState(null);
  const [uniqueDistributorNames, setUniqueDistributorNames] = useState(new Map());
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  // const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [filtered, setFiltered] = useState([]);
  // const [sortedProducts, setSortedProducts] = useState();
  const [sortType, setSortType] = useState("");
  const [filteredDistributor, setFilteredDistributor] = useState([]);
  const { categoryId } = useParams();
  const navigate = useNavigate();

  

  const handleSearch = () => {
    axiosInstance
      .post("/category_product", {
        title: searchQuery,
        category_id: categoryId,
      },
        { headers: { 'token': sessionStorage.getItem('token') } })
      .then((response) => {
        setFiltered(response.data.data);
        // console.log(response.data.data, "length");
      })
      .catch((error) => {
        console.error(error);
      });

      axiosInstance
      .post("/get_productss", {
        distributorId: selectedDistributorId,
      productName: searchQuery,
      },
        { headers: { 'token': sessionStorage.getItem('token') } })
      .then((response) => {
        setFilteredDistributor(response.data.length);
        // console.log(response.data.length, "length");
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleCategoryClick = (categoryId) => {
    // setFiltered("")
    // setFilteredProducts("")
    setSelectedDistributorId(null);
    navigate(`/products/${categoryId}`);
  };

  useEffect(() => {
    const handleData = async () => {
      axiosInstance
        .post("/category_product", {
          category_id: categoryId,
        },
          { headers: { 'token': sessionStorage.getItem('token') } })
        .then((response) => {
          setFilteredProducts(response.data.data);
          // setSortedProducts(response.data.product)
          // console.log(response.data.data, "length");
        })
        .catch((error) => {
          console.error(error);
        });
        axiosInstance
        .post("/get_productss", {
          distributorId: selectedDistributorId,
        productName: searchQuery,
        },
          { headers: { 'token': sessionStorage.getItem('token') } })
        .then((response) => {
          setDistributor(response.data.length);
          // console.log(response.data.length, "lengthdistributor");
        })
        .catch((error) => {
          console.error(error);
        });
    }
    handleData()
  }, [categoryId,selectedDistributorId]);

  // useEffect(() => {
  //   if (sortType && sortedProducts) {
  //     const sortedCopy = [...sortedProducts];
  //     if (sortType === "1") {
  //       sortedCopy.sort((a, b) => a.price - b.price);
  //       console.log(sortType, "logsort 1");
  //     } else if (sortType === "2") {
  //       sortedCopy.sort((a, b) => b.price - a.price);
  //     }
  //     setSortedProducts(sortedCopy);
  //   }
  // }, [sortType, sortedProducts]);

  // const categoryGetData = (distributor_id,distributor_name) => {
  //   axios
  //   .get("/retailer_home",
  //   { headers: { 'token': sessionStorage.getItem('token') } })
  //   .then((response) => {
  //     setSelectCategoryName(response.data.categorydata);
  //     // setDistributorName(response.data.productdata)
      
  //     // setUniqueDistributorNames(new Set(response.data.productdata));
  //     // console.log(`Clicked on ${distributor_name} Distributor ID: ${distributor_id}`)
      
  //     const uniqueNamesSet = new Set();
      
  //     response.data.productdata.forEach((distributor) => {
  //       uniqueNamesSet.add({
  //         distributor_id: distributor.distributor_id,
  //         distributor_name: distributor.distributor_name
  //       });
  //     });
      
      
  //     // Convert Set to an array before updating the state
  //     const uniqueNamesArray = Array.from(uniqueNamesSet);
      
  //     setUniqueDistributorNames(uniqueNamesArray);
  //     // console.log(response.data.categorydata, "category");
  //     setSelectedDistributorId(distributor_id);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }

  const categoryGetData = (distributor_id, distributor_name) => {
    axios
      .get("/retailer_home", { headers: { 'token': sessionStorage.getItem('token') } })
      .then((response) => {
        setSelectCategoryName(response.data.categorydata);

        const uniqueNamesMap = new Map();

        response.data.productdata.forEach((distributor) => {
          uniqueNamesMap.set(distributor.distributor_id, distributor.distributor_name);
        });

        setUniqueDistributorNames(uniqueNamesMap);
        setSelectedDistributorId(distributor_id);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  useEffect(() => {
    // console.log('Selected Distributor ID:', selectedDistributorId);
    categoryGetData(selectedDistributorId)
  }, [selectedDistributorId]);

  const handleResetAll = () => {
    setSearchQuery("");
    setSortType("");
    setSelectedDistributorId("")
    // categoryId("")
    setValue([1, 2]);
  };
  return (
    <>
      <Navbar />

      <div className="product-div">
        <div className="search-section">

          {/* <div>
            <NavLink id="listunderline" to="/">
              <Typography variant="h6" className="formTitle" sx={{ color: '#163269' }}> Home | </Typography>
            </NavLink>
          </div> */}
          <div className="apply-div">
            <button className="apply-btn" type="reset" onClick={handleResetAll}>Reset All</button>
          </div>

          <div className="categories">
            <Typography variant="h6" mb={2}>
              Categories
            </Typography>
            <div style={{ border: "0.5px solid #D9D9D9" }}></div>
            <div>
              {selectCategoryName && selectCategoryName.map((option) => (
                <MenuItem key={option._id} onClick={() => handleCategoryClick(option._id)} className='textFont'>
                  {option.name}
                </MenuItem>
              ))}
            </div>
          </div>


          <div className="distributor">
            <Typography variant="h6" mb={2}>
              Distributor
            </Typography>
            <div style={{ border: "0.5px solid #D9D9D9" }}></div>

            {/* <input
              placeholder="Distributor"
              className="distributor-input"
            ></input> */}
            {/* <div style={{ overflow: "scroll", height: "150px" }}>
              {distributorName.map((distributor) => (
                <Typography
                  key={distributor._id}
                  mt={3}
                  onClick={() => categoryGetData(distributor.distributor_name)}
                  style={{ cursor: "pointer" }}
                >
                  {distributor.distributor_name}
                </Typography>
              ))}
            </div> */}
            <div style={{ overflow: "scroll", height: "150px" }}>
        {Array.from(uniqueDistributorNames.values()).map((distributorName, index) => (
          <Typography
            key={index}
            mt={3}
            onClick={() => categoryGetData(Array.from(uniqueDistributorNames.keys())[index])}
            style={{ cursor: "pointer" }}
          >
            {distributorName}
          </Typography>
        ))}
      </div>

          </div>
          <div className="price">
            <Typography variant="h6">Rate</Typography>
            <hr></hr>

            <Stack
              spacing={2}
              direction="row"
              sx={{ mb: 1, mt: 5 }}
              alignItems="center"
            >
              <CurrencyRupeeIcon fontSize="16px" /> 1
              <Slider
                getAriaLabel={() => "Temperature range"}
                min={0}
                max={5000}
                value={value}
                onChange={handleChange}
                valueLabelDisplay="auto"
              // getAriaValueText={valuetext}
              />
              <CurrencyRupeeIcon fontSize="16px" /> 5000
            </Stack>
          </div>
          <div className="apply-div">
            <button className="apply-btn">Apply</button>
          </div>
        </div>

        <div className="brand-medicine">
          <img src={product_image} className="brand-img" alt="product_image" />
          <div className="filter">
        
        {selectedDistributorId ? (
          <Typography mt={4}>
            Showing {searchQuery ? filteredDistributor : distributor} of {distributor}
          </Typography>
        ):(  
        <Typography mt={4}>
          Showing {searchQuery ? filtered : filteredProducts} of {filteredProducts}
        </Typography>
        )}
            <div className="mt-3">
              <input
                type="text"
                placeholder="Search by product name"
                style={{ fontSize: "15px" }}
                value={searchQuery}
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                  handleSearch();
                }}
              />

            </div>
            <div className="select-filter mt-2">
              <select
                className="form-select pt-0 pb-0 mt-3"
                onClick={(e) => {
                  setSortType(e.target.value);
                }}
              >
                <option value="">Sort By</option>
                <option value="1">Price Low to High</option>
                <option value="2">Price High to Low</option>
              </select>
            </div>
          </div>
          <div className="medicine-list">
            <ProductCard searchQuery={searchQuery} sortType={sortType} distributorName={uniqueDistributorNames} distributorId={selectedDistributorId} />

          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Products;

// import React from "react";
// import { useEffect, useState } from "react";
// import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";

// function PaymentConfirmed(){
//     let navigate = useNavigate();
//     console.log("Thanks for your money")
//     useEffect(() => {
//       var data = JSON.parse(String(localStorage.getItem('checkout')))

//       if(data){
//         axios
//         .post(
//           "https://api.meddaily.in/checkout",
//           { data },
//           { headers: { token: sessionStorage.getItem("token") } }
//         )
//         .then((response) => {
//           console.log(response.data);
//           // alert('order success');
//           navigate('/orderlist')
//         })
//         .catch((error) => {
//           alert("Error");
//           console.log("error:", error);
//           // console.error('Error adding to cart:', error);
//         });
//       }

//     }, )

//     return(
//         <>
//         Thank You for the payment , Your Order is confirmed

//         </>
//     )
// }
// export default PaymentConfirmed
import React, { useEffect, useState } from 'react';
// import { useNavigate  } from 'react-router-dom';
import '../css/phonepay.css';
import axios from 'axios';
import { axiosInstance } from "../Axios";
import { useParams, useNavigate } from 'react-router-dom';
// import phonepe from '../../image/phonepe.png'
const PaymentConfirmed = () => {

    const [loading2, setLoading2] = useState(false);
    const [openModal, setOpenModal] = useState(true);
    const [paymentData, setPaymentData] = useState({
        name: '',
        amount: 0,
        order_id: '',
    });
    const { id } = useParams();
    const navigate = useNavigate();

    // console.log("id",id);
    useEffect(() => {
        const handledata = async () => {
            const res = await axiosInstance.get(`/order_details?order_id=${id}`, {
                headers: { token: sessionStorage.getItem("token") },
            })
                .then(res => {
                    const order = res.data.data
                    setPaymentData({
                        name: order.retailer_name,
                        amount: order.price,
                        order_id: order.order_id,
                    });

                }).catch(err => {
                    console.error(err);
                })
        }
        handledata()

        const handlePopstate = () => {
            console.log('Inside the ')
            const isConfirmed = window.confirm('Are you sure you want to cancel the order?');
            if (isConfirmed) {
                cancelOrder();
                navigate(`/ordersummary`)
                return cleanup()
            } else {
                navigate(`/payment_confirmed/${id}`)
                console.log('User decided not to cancel the order');
                setOpenModal(true);
                // return cleanup()
            }
        };
        setOpenModal((prev) => {
            if (prev) {
                window.addEventListener('popstate', handlePopstate);
                console.log('Inside');
                return false
            }
        })


        const cleanup = () => {
            console.log("enter in remove");
            window.removeEventListener('popstate', handlePopstate);
        };

        //   return cleanup()
        // return () => {
        //     window.removeEventListener('popstate', handlePopstate);
        // };
        return
    }, [])


    const data = {
        name: paymentData?.name,
        amount: paymentData?.price,
        order_id: paymentData?.order_id,
        MUID: "MUID" + Date.now(),
        transactionId: 'MT' + Date.now(),
    }
    const handlePayment = (e) => {
        const token = sessionStorage.getItem("token")
        console.log("token", token);
        e.preventDefault();
        setLoading2(true);
        axiosInstance.post('/payment_init',
            data,
            { headers: { token: sessionStorage.getItem("token") } }
        ).then(response => {
            if (response.data.url) {
                console.log("DTATA", response);
                window.location.href = response.data.url;
                window.open(response.data.url, '_blank');

            }
            setTimeout(() => {
                setLoading2(false);
            }, 1500);
        })
            .catch(error => {
                setLoading2(false)
                console.error(error);
            });
    }


    // const handlePopstate = () => {
    //     const isConfirmed = window.confirm('Are you sure you want to cancel the order?');
    //     if (isConfirmed) {
    //         cancelOrder();
    //     } else {
    //         console.log('User decided not to cancel the order');
    //     }
    // };

    const cancelOrder = async () => {
        try {
            const response = await axios.get(`/cancel_order_retailer?order_id=${id}`);
            console.log('Order canceled successfully:', response.data);

        } catch (error) {
            console.error('Error cancelling order:', error);

        }
    };
    // window.addEventListener('popstate', handlePopstate);

    return (
        <>
            {/* {openModal && (
        // Render your modal component here
        <div className="modal">
          <div className="modal-content">
            <p>Are you sure want to back?</p>
            <div style={{display:"flex"}}>
            <button>Ok</button>
            <button>Cancle</button>
            </div>
          </div>
        </div>
      )} */}
            <div className='main'>
                <div className='center'>
                    {/* <img width={300} src={phonepe} alt="" /> */}
                    <h2 className='fs-4 mt-2'><span className='text-danger fw-bold'>LIVE</span> Payment Integration</h2>
                </div>
                <div className='card px-5 py-4 mt-5'>
                    <form>
                        <div className='col-12 '>
                            <p className='fs-5'><strong>OrderId:</strong> {paymentData?.order_id}</p>
                        </div>
                        <div className='col-12 '>
                            <p className='fs-5'><strong>Name:</strong> {paymentData?.name}</p>
                        </div>
                        <div className='col-12 '>
                            <p className='fs-5'><strong>Amount:</strong> {paymentData?.amount}  Rs</p>
                        </div>
                        {!loading2 ? <div className='col-12 center'>
                            <button onClick={handlePayment} className='w-100 buttonpaynow' >Pay Now</button>
                        </div>
                            :
                            <div className='col-12 center'>
                                <button className='w-100 text-center' type="submit">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden ">Wait...</span>
                                    </div>
                                </button>
                            </div>
                        }
                    </form>
                </div>
                <p className='m-0'>@codesense24</p>
                <p className='' style={{ fontSize: "14px" }}>Subscribe my youtube channel</p>
            </div>
        </>
    )
}
export default PaymentConfirmed

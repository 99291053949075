import { Typography } from "@mui/material";
import privacy_image from "../image/privacy.jpeg";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
const TermsCondition = () => {
  return (
    <>
      <Navbar />
      <div className="image-privacy-div">
        <img
          src={privacy_image}
          alt="privacy_img"
          width="100%"
          height="400"
          className="privacy-img"
        />
        <div className="centered">
          <Typography variant="h2"> Terms & Condition</Typography>
          <Typography variant="h4"> Last Modified : Dec 2022 </Typography>
        </div>
      </div>
      <div className="privacy-div">
        <div className="intro-div">
          <Typography mb={4} variant="h6">
            Introduction
          </Typography>

          <Typography mb={4} fontSize="16px">
            THIS DOCUMENT IS AN ELECTRONIC CONTRACT RECOGNISED UNDER THE
            PROVISIONS OF THE INFORMATION TECHNOLOGY ACT OF 2000 READ ALONG WITH
            ITS RULES, AND OUTLINES THE TERMS ON WHICH A USER MAY THROUGH ITS
            WEB APPLICATION –www.meddaily.in (“WEBSITE”), ALONG WITH
            CORRESPONDING MOBILE APPLICATION (hereinafter also called as
            “WEBSITE”) OWNED AND OPERATED BY M/s. MEDDAILY.IN.
          </Typography>

          <Typography mb={4} fontSize="16px">
            PLEASE READ THESE TERMS OF SERVICE CAREFULLY AS THEY CONTAIN
            IMPORTANT INFORMATION REGARDING YOUR LEGAL RIGHTS, REMEDIES AND
            OBLIGATIONS. THESE INCLUDE VARIOUS LIMITATIONS AND EXCLUSIONS, A
            CLAUSE THAT GOVERNS THE JURISDICTION AND VENUE OF DISPUTES, AND
            OBLIGATIONS TO COMPLY WITH APPLICABLE LAWS AND REGULATIONS.
          </Typography>

          <Typography mb={4} fontSize="16px">
            YOU ACKNOWLEDGE AND AGREE THAT, BY ACCESSING OR USING THE WEBSITE OR
            SERVICES OR BY DOWNLOADING OR POSTING ANY CONTENT FROM OR ON THE
            WEBSITE OR THROUGH THE SERVICES, YOU ARE INDICATING THAT YOU HAVE
            READ, AND THAT YOU UNDERSTAND AND AGREE TO BE BOUND BY THESE
            TERMS(AS APPLICABLE), WHETHER OR NOT YOU HAVE REGISTERED WITH THE
            WEBSITE. IF YOU DO NOT AGREE TO THESE TERMS, THEN YOU HAVE NO RIGHT
            TO ACCESS OR USE THE WEBSITE, SERVICES, OR COLLECTIVE CONTENT. IF
            YOU ACCEPT OR AGREE TO THESE TERMS ON BEHALF OF A COMPANY OR OTHER
            LEGAL ENTITY, YOU REPRESENT AND WARRANT THAT YOU HAVE THE AUTHORITY
            TO BIND THAT COMPANY OR OTHER LEGAL ENTITY TO THESE TERMS AND, IN
            SUCH EVENT, "YOU" AND "YOUR" WILL REFER AND APPLY TO THAT COMPANY OR
            OTHER LEGAL ENTITY.
          </Typography>

          <Typography mb={4} fontSize="16px">
            M/s. MEDDAILY.IN. (HEREINAFTER REFERRED TO AS “FIRM”, “WE”, ‘’US” OR
            “OUR”) MAY REVISE THESE TERMS OF SERVICE INCLUDING PRIVACY POLICY,
            AT ITS DISCRETION. WE DO NOT HAVE ANY OBLIGATION OR RESPONSIBILITY
            TO INFORM OR INTIMATE YOU IN ANY MANNER, DIRECTLY OR INDIRECTLY, OF
            THE POSTING ONCE UPDATED OR PRIOR TO SUCH UPDATION. YOUR USE OF THE
            WEBSITE (DEFINED BELOW) FOLLOWING ANY SUCH MODIFICATION CONSTITUTES
            YOUR AGREEMENT TO FOLLOW AND BE BOUND BY THE TERMS OF USE AS
            MODIFIED. ANY ADDITIONAL TERMS AND CONDITIONS, DISCLAIMERS, PRIVACY
            POLICIES, REFUND POLICY AND OTHER POLICIES APPLICABLE TO GENERAL AND
            SPECIFIC AREAS OF THIS WEB SITE OR TO PARTICULAR CONTENT ARE ALSO
            CONSIDERED AS TERMS OF USE. YOU SHOULD VISIT THIS PAGE PERIODICALLY
            TO REVIEW THE TERMS OF USE, BECAUSE THEY ARE BINDING ON YOU. THE
            TERMS "YOU","USER"AND “SERVICE PROVIDER” AS USED HEREIN REFER TO ALL
            INDIVIDUALS AND/OR ENTITIES ACCESSING THE WEB SITE FOR ANY REASON,
            AS APPLICABLE TO SUCH INDIVIDUALS AND/OR ENTITIES ACCESSING THE WEB
            SITE/ .
          </Typography>
        </div>

        <div className="managing-div">
          <Typography mb={4} variant="h6">
            PART A –GENERAL TERMS RELATING TO WEBSITE SERVICES/ MOBILE
            APPLICATION
          </Typography>
          <ul>
            <li>
              Website/ Mobile Application <br />
              <br />
              <Typography fontSize="14px" variant="p">
                You agree and acknowledge the following:
              </Typography>
            </li>

            <br />
            <li>
              That we are not a Seller of the products listed on this Website;
              that we are not an advertiser of any of the items listed on this
              Website; that we are not a party to the transactions between
              registered users on this Website. The Website only provides the
              venue for registered users to privately transact for trade of
              pharmaceutical products, medicines, supplements, equipments, and
              healthcare products and devices(“Items”).
            </li>
            <br />
            <li>
              We are not involved in the actual transaction or transfer of Items
              between users. We recommend that you take precautionary measures
              when communicating with other users and entering into any
              transaction.
            </li>
            <br />
            <li>
              We make no representation as to the truthfulness of other users on
              this Website. We recommend that you take precautionary measures to
              determine another user’s age, identity, location, and authority to
              enter into contracts.
            </li>
            <br />
            <li>
              We make no representation that users on this Website shall
              complete the transfer of the Item or deliver the Item to the
              buyer; nor that a user has truthfully and accurately described an
              Item posted on the Website; or that the transfer of an Item
              complies with applicable legal requirements for the transaction.
            </li>
            <br />
            <li>
              Although we may provide references to relevant laws, nothing on
              this Website is meant to constitute legal advice. You should check
              with your country’s laws to ensure you are in full compliance with
              legal requirements. If you are unsure about proper compliance,
              seek the assistance of a licensed and qualified attorney. You
              shall be permitted to access the Website Services (as defined
              below) only upon creating an Account (as defined below) and
              obtaining a registration on the Website.
            </li>
          </ul>
          <ol>
            <li>
              {" "}
              <Typography mb={1} variant="h6">
                Registration
              </Typography>
              <ol>
                <li>
                  In order to access or use the Website/ Mobile Application you
                  must set up an account by providing information about yourself
                  as prompted on the sign-up page of the Website/ Mobile
                  Application which may include your name, gender, mobile number
                  and such other details relevant for placing orders through
                  your account. Your login ID and password will be created basis
                  the information provided by you, which you can use to access
                  your Meddaily.In account at any time. Your credentials shared
                  by you at the time of registration and your Meddaily.In login
                  ID and password are referred to as “Account Information”.
                </li>
                <li>
                  Your ability to continue using the Website is subject to your
                  continued registration on the Website.
                </li>
                <li>
                  <Typography mb={4} variant="h6">
                    Services
                  </Typography>
                  <Typography variant="p">
                    The Website provides the following services (collectively,
                    “Website Services”):
                  </Typography>
                  <br />
                  <Typography mb={4} variant="p">
                    3.1 To the Users:
                  </Typography>
                  <ol type="a">
                    <li>
                      {" "}
                      It allows Users to view the profiles of all service
                      providers registered on the Website(hereinafter referred
                      to as “Registered Service Providers”)for the sale of Items
                      and related services, along with their service offerings,
                      offers, packages and prices;
                    </li>
                    <li>
                      It enables Users to locate the nearest and preferred
                      Registered Service Providers factoring in the User’s
                      location, requirement and preference;
                    </li>
                    <li>
                      It facilitates regular interface of Users with their
                      chosen Registered Service Provider to purchase the Items,
                      and online payment for services through the Website;
                    </li>
                    <li>
                      It provides Users with the option to post feedback and
                      ratings in relation to any Registered Service Provider
                      products may have been purchased by the User;
                    </li>
                    <li>
                      It facilitates periodic reminders from Registered Service
                      Providers to the Users; and
                    </li>
                    <li>
                      It serves as a medium to drive improvements in the quality
                      of service provided by Registered Service Providers based
                      on User feedback
                    </li>
                  </ol>
                  <br />
                  <Typography mb={4} variant="p">
                    3.2 To the Service Providers:
                  </Typography>
                  <ol type="a">
                    <li>
                      It allows the Users to access to all Items offered by the
                      Service Providers on the Website;
                    </li>
                    <li>
                      It enables the Service Providers to effectively
                      communicate their service offerings, with respect to Items
                      for sale, packages, promotions, and prices to the
                      registered customers of the Website
                    </li>
                    <li>
                      It facilitates regular interface of the Service Providers
                      with the registered customers for receiving and accepting
                      the orders to Purchase the Items, provided the Service
                      Provider has availed of the facility to accept bookings
                      and payment for the Items through the Website; and
                    </li>
                    <li>
                      It facilitates improvement in the quality of the services
                      provided by the Service Providers on the basis of customer
                      reviews and feedback
                    </li>
                  </ol>
                  <br />
                  <Typography mb={4} variant="p">
                    The Company may, at its discretion, add, modify or remove
                    any of the Website Services listed above from time to time
                    without notice.
                  </Typography>
                  <br />
                  <br />
                  <Typography mb={4} variant="p">
                    3.3 - The Firm is only a facilitator between You and the
                    Registered Service Provider. Any contract for Items
                    purchased between You and the Registered Service Provider
                    shall strictly be a bipartite contract between You and the
                    Registered Service Provider and the Company shall not be a
                    party to the same. The firm shall not and is not required to
                    mediate or resolve any dispute or disagreement between You
                    and the Registered Service Providers.
                  </Typography>
                  <br />
                  <br />
                  <Typography mb={4} variant="p">
                    3.4 - The Firm has not independently verified and does not
                    guarantee the truthfulness of the credentials of the
                    Registered Service Provider and any description of their
                    business, authorizations, Items offerings including the
                    nature of services, pricing, location, contact details, etc.
                    provided by the Registered Service Provider. Such
                    information has been collected from the concerned Registered
                    Service Provider, who has guaranteed the truthfulness and
                    completeness of the same and the Firm shall not in any
                    manner, be responsible or liable for the such information.
                  </Typography>
                  <br />
                  <br />
                  <Typography mb={4} variant="p">
                    3.5 - The Website shall also display the cost of the Items
                    for sale by the Registered Service Providers including the
                    applicable taxes payable.
                  </Typography>
                  <br />
                  <br />
                  <Typography mb={4} variant="p">
                    3.6 - Any Items once paid for purchases on the Website, if
                    cancelled or modified by You before the goods are in
                    dispatch state; the Firm shall refund all amounts (adjusting
                    for charges, if any) collected from You towards such Items
                    purchased that were cancelled by You and/or the Registered
                    Seller to the source account within 10 business days of such
                    cancellation. If any cancellation is made during the goods
                    are in dispatch mode, in such cases, the amount 25% of the
                    paid amount will be retained and rest other 75% of the
                    amount paid will be credited to the source account within 10
                    business days. If the products are found to be faulty or
                    expired; in such cases, the amount paid will be converted in
                    the form of “MedCash” and can be used for placing future
                    orders.
                  </Typography>
                  <br />
                  <br />
                  <Typography mb={4} variant="p">
                    3.7 - It shall be the responsibility of the Registered
                    Service Provider to honour the purchase of the Items made by
                    You. The Firm is not responsible, and has no liability for
                    the genuineness, the quality or completeness of such
                    transactions provided by the Registered Service Providers to
                    You, including any delay in services by the Registered
                    Service Providers or cancellation of any Items purchase made
                    with a Registered Service Provider by You through the
                    Website, or any offers, discounts or service packages
                    communicated by the Registered Service Providers, or for any
                    misconduct or fraud committed by a Registered Service
                    Provider or its employees, representatives, etc. The Firm
                    cannot be called upon to provide any guarantee/security with
                    respect to the Items sold by the Registered Service
                    Provider/ purchased by the User.
                  </Typography>
                  <br />
                  <br />
                  <Typography mb={4} variant="p">
                    3.8 - You may conduct searches on the Website to look for
                    Registered Service Providers to process Your requirements.
                    The results for searches are derived on the basis of an
                    algorithm which considers various factors, including but not
                    limited to, the proximity to location from where the service
                    request has been made, User reviews and feedback, pricing,
                    etc. Alternatively, the Registered Service Providers may be
                    listed on the basis of User-selected search criteria. In
                    either case, the Firm does not have any control over the
                    results of searches carried out by You on the Website. The
                    result of any search for Registered Service Providers
                    conducted by You on the Website shall not be construed as
                    the opinion or preference of the Firm.
                  </Typography>
                  <br />
                  <br />
                  <Typography mb={4} variant="p">
                    3.9 - As part of the Website Services provided to You, after
                    making any purchase of any Item/s from a Registered Service
                    Provider, You agree to provide honest feedback/review about
                    the concerned Registered Service Provider. You agree and
                    accept that the ratings provided on the Website are on the
                    basis of the feedback and comments of the Users and that the
                    Firm is no way responsible or liable for the same. All views
                    and opinions expressed on the Website, are those of the
                    individual Users only, and do not in any way reflect the
                    opinion of the Firm.
                  </Typography>
                  <br />
                  <br />
                  <Typography mb={4} variant="p">
                    3.10 - Any purchase of Items made by You through the Website
                    maybe subject to the additional terms and conditions
                    mentioned therein, which You are presumed to have read and
                    accepted at the time of making the service booking.
                  </Typography>
                  <br />
                  <br />
                  <Typography mb={4} variant="p">
                    3.11 - Currently all Website Services are provided to you at
                    no cost. However, the Firm reserves the right to charge a
                    fee for any of the Website Services at any time, without
                    specifically informing You of the same. Any revision of fee
                    shall be notified on the Website and shall be applicable for
                    Website Services availed after such notification.
                  </Typography>
                </li>
                <li>
                  <Typography mb={4} variant="h6">
                    Payment Services
                  </Typography>
                  <ol>
                    <li>
                      {" "}
                      With respect to Item/s purchased by You through the
                      Website, the amount and manner of payment will be either
                      be on the basis of Cash on Delivery (COD) made directly to
                      the logistics partner’s executive, appointed by the Firm
                      or may be permitted/required to be made online by You
                      using the payment services available on the Website. In
                      the event that full payment of any Registered Service
                      Provider is not undertaken on the Website at the time of
                      order booking, it shall be Your responsibility to pay the
                      amounts due, in the manner agreed while placing the order
                      on the Website.
                    </li>
                    <li>
                      To the extent permitted by applicable law and subject to
                      the Firm’s Privacy Policy, you acknowledge and agree that
                      the Firm may use certain third-party vendors and service
                      providers, including payment gateways, to process payments
                      and manage payment card information.
                    </li>
                    <li>
                      In order to make payments online, You undertake to use a
                      valid payment card, to have sufficient funds or credit
                      available to complete the payment and have an Account on
                      the Website in good standing. By providing payment card
                      information, You represent, warrant, and covenant that:
                      <ol>
                        <li>
                          {" "}
                          You are legally authorized to provide such
                          information;
                        </li>
                        <li>
                          You are legally authorized to perform payments from
                          the payment card account; and
                        </li>
                        <li>
                          such action does not violate the terms and conditions
                          applicable to Your use of such payment card account or
                          applicable law. You agree that You are responsible for
                          any fees charged by Your mobile carrier in connection
                          with Your use of the payment services through Your
                          mobile. The Firm shall use the payment card
                          information as described in the Firm’s Privacy Policy.
                          You may add, delete, and edit the payment card account
                          information You have provided from time to time
                          through the Website.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Except to the extent otherwise required by applicable law,
                      the Firm is not liable for any payments authorized through
                      the Website using Your payment card information.
                      Particularly, the Firm is not liable for any payments that
                      do not complete because:
                      <ol>
                        <li>
                          Your payment card account does not contain sufficient
                          funds to complete the transaction or the transaction
                          exceeds the credit limit of the payment card account;
                        </li>
                        <li>
                          {" "}
                          You have not provided the Firm with correct payment
                          card information;
                        </li>
                        <li>Your payment card has expired; or</li>
                        <li>
                          circumstances beyond the Firm’s control (such as, but
                          not limited to, power outages, interruptions of
                          cellular service, or any other interferences from an
                          outside force) prevent the execution of the
                          transaction.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <Typography mb={4} variant="h6">
                  PART A –GENERAL TERMS RELATING TO WEBSITE SERVICES
                </Typography>
                <li>
                  Use of website
                  <ol>
                    <li>
                      Use of and access to the Website is offered to You upon
                      the condition of acceptance of all the terms, conditions
                      and notices contained in these Terms of Use, along with
                      any amendments made by the Firm at its sole discretion and
                      posted on the Website.
                    </li>
                    <li>
                      The Firm shall not be required to notify You, whether as a
                      registered User or not, of any changes made to the Terms
                      of Use. The revised Terms of Use shall be made available
                      on the Website. You are requested to regularly visit the
                      homepage to view the most current Terms of Use. You can
                      determine when the Firm last modified the Terms of Use by
                      referring to the “Last Updated” legend above. It shall be
                      Your responsibility to check these Terms of Use
                      periodically for changes. The Firm may require You to
                      provide Your consent to the updated Terms of Use in a
                      specified manner prior to any further use of the Website
                      and Website Services. If no such separate consent is
                      sought, Your continued use of the Website, following the
                      changes to the Terms of Use, will constitute Your
                      acceptance of those changes. Your use of the Website and
                      the Website Services is subject to the most current
                      version of the Terms of Use made available on the Website
                      at the time of such use.
                    </li>
                    <li>
                      By (i) using this Website or any Website Services in any
                      way; or (ii) merely browsing the Website, You agree that
                      You have read, understood and agreed to be bound by these
                      Terms of Use and the Website’s Privacy Policy available at
                      [www.MEDDAILY.IN].
                    </li>
                  </ol>
                </li>
                <li>
                  Eligibility to Use
                  <ol>
                    <li>
                      The Website Services are not available to minors under the
                      age of 18 (eighteen)years or to any Users suspended or
                      removed by the firm for any reason whatsoever. If You do
                      not conform to the above qualification, You shall not be
                      permitted to avail of the Website Services or use or
                      register on the Website. You represent that You are of
                      legal age to form a binding contract and are not a person
                      barred from receiving Website Services under the
                      applicable laws.
                    </li>
                    <li>
                      The Firm reserves the right to refuse access to the
                      Website or Website Services to new Users or to terminate
                      access granted to existing Users at any time without
                      according any reasons for doing so.
                    </li>
                    <li>
                      You shall not have more than one active Account (as
                      defined hereinafter) on the Website. Additionally, You are
                      prohibited from selling, trading, or otherwise
                      transferring Your Account to another party.
                    </li>
                  </ol>
                </li>
                <li>
                  User Account, Password, and Security
                  <ol>
                    <li>
                      In order to avail the Website Services, You will have to
                      register on the Website and create an account ("Account").
                      If you are using the Website on a compatible mobile or
                      tablet, you will have to install the application and then
                      proceed with registration. You agree to provide details
                      about Yourself, including Your name, address, contact
                      details, email, and KYC documents such as valid drug
                      license, valid food license, valid GST (if applicable),
                      and/or other documents related to your business as
                      necessary.
                    </li>
                    <li>
                      {" "}
                      You shall ensure and confirm that the Account information
                      provided by You is complete, accurate and up to date. If
                      there is any change in the Account information, You shall
                      promptly update Your Account information on the Website.
                      If You provide any information that is untrue, inaccurate,
                      not current or incomplete (or becomes untrue, inaccurate,
                      not current or incomplete), or if the Firm has reasonable
                      grounds to suspect that such information is untrue,
                      inaccurate, not current or incomplete, the Firm has the
                      right to suspend or terminate Your Account and refuse any
                      and all current or future use of the Website(or any
                      portion thereof) at its discretion, in addition to any
                      right that the Company may have against You at law or in
                      equity, for any misrepresentation of information provided
                      by You.
                    </li>
                    <li>
                      {" "}
                      You shall ensure and confirm that the Account information
                      provided by You is complete, accurate and up to date. If
                      there is any change in the Account information, You shall
                      promptly update Your Account information on the Website.
                      If You provide any information that is untrue, inaccurate,
                      not current or incomplete (or becomes untrue, inaccurate,
                      not current or incomplete), or if the Firm has reasonable
                      grounds to suspect that such information is untrue,
                      inaccurate, not current or incomplete, the Firm has the
                      right to suspend or terminate Your Account and refuse any
                      and all current or future use of the Website(or any
                      portion thereof) at its discretion, in addition to any
                      right that the Company may have against You at law or in
                      equity, for any misrepresentation of information provided
                      by You. 7.3 You will be responsible for maintaining the
                      confidentiality of the Account information, and are fully
                      responsible for all activities that occur under Your
                      Account. You agree to (a) immediately notify the Firm of
                      any unauthorized use of Your Account information or any
                      other breach of security, and (b) ensure that You exit
                      from Your Account at the end of each session. The Firm
                      cannot and will not be liable for any loss or damage
                      arising from Your failure to comply with this provision.
                      You may be held liable for losses incurred by the Firm or
                      any other user of or visitor to the Website due to
                      authorized or unauthorized use of Your Account as a result
                      of Your failure in keeping Your Account information secure
                      and confidential.
                    </li>
                    <li>
                      Use of another User’s Account information for availing the
                      Website Services is expressly prohibited.
                    </li>
                  </ol>
                </li>
                <li>
                  {" "}
                  User Representations and Obligations
                  <ol>
                    <li>
                      {" "}
                      Subject to compliance with the Terms of Use, the Firm
                      grants You a non-exclusive, limited privilege to access
                      and use this Website and the Website Services.
                    </li>
                    <li>
                      You agree to use the Website Services, Website and the
                      materials provided therein only: (a) for purposes that are
                      permitted by the Terms of Use; and (b) in accordance with
                      any applicable law, regulation or generally accepted
                      practices or guidelines.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TermsCondition;

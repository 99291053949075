import React from 'react';
import {
	AppBar,
	Toolbar,
	Drawer,
	Grid,
	OutlinedInput,
	InputAdornment,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TitleText from './resuableComponents/TitleText';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Person2Icon from '@mui/icons-material/Person2';
import ViewListIcon from '@mui/icons-material/ViewList';
import { useSelector } from 'react-redux';
const drawerWidth = '17vw';
const DrawerNavigator = ({ children }) => {
	const { retailerProfile } = useSelector((state) => state.home);
	const navigation = useNavigate();
	return (
		<div style={{ display: 'flex' }}>
			<AppBar
				position='fixed'
				style={{
					width: '83vw',
					marginLeft: drawerWidth,
					backgroundColor: '#F2F4F7',
					boxShadow: 'none',
				}}>
				<Toolbar disableGutters style={{ padding: '20px 60px' }}>
					<Grid
						container
						style={{
							color: 'grey',
							justifyContent: 'space-between',
							backgroundColor: 'white',
							padding: '10px 20px',
							borderRadius: '10px',
						}}>
						<Grid
							item
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
							lg={2}
							md={2}
							sm={12}
							xs={12}>
							{/* <OutlinedInput
								startAdornment={
									<InputAdornment position='start'></InputAdornment>
								}
								placeholder='Search'
								style={{ height: 40 }}
							/> */}
						</Grid>
						<Grid
							item
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
							}}
							lg={6}
							md={8}
							sm={12}
							xs={12}>
							<TitleText two textStyle={{ marginLeft: 125 }}>
								Hi,
								{retailerProfile?.data?.ownername}
							</TitleText>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					'& .MuiDrawer-paper': {
						width: drawerWidth,
						boxSizing: 'border-box',
						borderRight: 'none',
					},
				}}
				variant='permanent'
				anchor='left'>
				<div style={{
					display: "flex",
					justifyContent: "center"
				}}>
					<img
						onClick={() => navigation('/home')}
						src={
							'https://distributor.meddaily.in/assets/img/logo2.png'
						}
						alt='Logo'
						style={{
							width: 200,
							cursor: 'pointer',
							display: "flex",
							justifyContent: "center"
						}}></img>
				</div>

				{/* <Toolbar /> */}
				<Divider />
				<List>
					{['My Profile', 'Order', 'Return'].map((text, index) => (
						<ListItem key={text} disablePadding>
							<ListItemButton
								onClick={() =>
									navigation(
										index === 0
											? '/dashboard'
											: index === 1
												? '/orderlist'
												: '/returnlist'
									)
								}>
								<ListItemIcon>
									{index === 0 ? <Person2Icon /> : <ViewListIcon />}
								</ListItemIcon>
								<ListItemText primary={text} />
							</ListItemButton>
						</ListItem>
					))}
				</List>

			</Drawer>

			<main
				style={{
					flexGrow: 1,
					backgroundColor: '#F2F4F7',
					paddingLeft: '60px',
					paddingTop: '20px',
					paddingBottom: '20px',
					paddingRight: '60px',
					minHeight: '100vh',
					width: '83vw',
				}}>
				<div style={{ minHeight: 90 }} />
				{children}
			</main>
		</div>
	);
};

export default DrawerNavigator;

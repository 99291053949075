import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form, Formik } from "formik";
import { Grid, Box, MenuItem } from "@mui/material";

const AddOfferForm = (props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (data, actions) => {
    setIsSubmitting(true);
    // let formData = {
    // 	title: data.title,
    // 	day: data.days,
    // 	night: data.nights,
    // 	image: coverImage,
    // 	amount: data.amount,
    // 	subCatimage: addMultipleImage,
    // 	Itinerary: addItern,
    // 	flight: addMultipleFlightDetail,
    // 	accommodation: addMultipleAccomodationDetail,
    // 	location: data.location,
    // 	month: `${data.months}-02`,
    // };
    // console.log('formData', formData);
    // dispatch(AddPackageDispatch(formData, history));
    // setIsSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        title: "",
        days: "",
        nights: "",
        amount: "",
        location: "",
        months: "",
      }}
      enableReinitialize={true}
      validate={(values) => {
        const errors = {};
        let isError = false;
      }}
      onSubmit={handleSubmit}
    >
      {(formProps) => {
        const {
          submitForm,
          // isSubmitting,
          errors,
          validateForm,
          values,
          setFieldValue,
        } = formProps;
        return (
          <>
            {isSubmitting ? ( // Display loader while submitting
              <div>Loading...</div>
            ) : (
              <Form style={{ marginTop: 50 }}>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                      <span
                        style={{
                          color: "rgba(0, 50, 91, 0.5)",
                          fontWeight: 600,
                        }}
                      >
                        OFFER NAME
                      </span>
                      <Field
                        name="title"
                        type="text"
                        style={{
                          border: "1px solid grey",
                          padding: "14px",
                          marginTop: "10px",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <span
                        style={{
                          color: "rgba(0, 50, 91, 0.5)",
                          fontWeight: 600,
                        }}
                      >
                        OFFER TYPE
                      </span>
                      <Field
                        as="select"
                        name="title"
                        type="text"
                        style={{
                          border: "1px solid grey",
                          padding: "14px",
                          marginTop: "10px",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        <option value="red">Red</option>
                        <option value="green">Green</option>
                        <option value="blue">Blue</option>
                      </Field>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <span
                        style={{
                          color: "rgba(0, 50, 91, 0.5)",
                          fontWeight: 600,
                        }}
                      >
                        SELECT A PRODUCT
                      </span>
                      <Field
                        as="select"
                        name="title"
                        type="text"
                        style={{
                          border: "1px solid grey",
                          padding: "14px",
                          marginTop: "10px",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      >
                        <option value="red">Red</option>
                        <option value="green">Green</option>
                        <option value="blue">Blue</option>
                      </Field>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <span
                        style={{
                          color: "rgba(0, 50, 91, 0.5)",
                          fontWeight: 600,
                        }}
                      >
                        PURCHASE
                      </span>
                      <Field
                        name="title"
                        type="text"
                        style={{
                          border: "1px solid grey",
                          padding: "14px",
                          marginTop: "10px",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <span
                        style={{
                          color: "rgba(0, 50, 91, 0.5)",
                          fontWeight: 600,
                        }}
                      >
                        FREE PRODUCT COUNT
                      </span>
                      <Field
                        name="title"
                        type="text"
                        style={{
                          border: "1px solid grey",
                          padding: "14px",
                          marginTop: "10px",
                          borderRadius: "5px",
                          width: "100%",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} lg={4}>
                      <span
                        style={{
                          color: "rgba(0, 50, 91, 0.5)",
                          fontWeight: 600,
                        }}
                      >
                        UPLOAD PRPODUCT IMAGE
                      </span>
                      <label
                        className="form-label float-start"
                        for="csvFile"
                      ></label>
                      <div className="input-group input-group-merge">
                        <input
                          className="form-control"
                          type="file"
                          id="csvFile"
                          name="csvFile"
                          style={{ marginTop: 10, padding: 15 }}
                          placeholder="enter pharmacist name"
                          // value={bul.csvFile}
                          // onChange={handle}
                        />
                      </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <button
                        className="btn btn-primary me-2"
                        // type='submit'
                        onClick={submitForm}
                        style={{
                          cursor: "pointer",
                          // padding: '14px 20px',
                          // backgroundColor: 'red',
                          // color: 'white',
                          // marginLeft: 10,
                          // borderRadius: 12,
                          width: "100%",
                          // fontSize: 16,
                          marginBottom: 50,
                          marginTop: 50,
                        }}
                      >
                        <span style={{ fontSize: 16 }}>Submit</span>
                      </button>
                    </Grid>
                  </Grid>
                </Box>
              </Form>
            )}
          </>
        );
      }}
    </Formik>
  );
};

export default AddOfferForm;

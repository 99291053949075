import { Typography } from "@mui/material";
import privacy_image from "../image/privacy.jpeg";
import Navbar from "../components/Navbar";
import Footer from "../components/footer";
const ReturnPolicy = () => {
  return (
    <div>
      <Navbar />
      <div className="image-privacy-div">
        <img
          src={privacy_image}
          alt="privacy_img"
          width="100%"
          height="400"
          className="privacy-img"
        />
        <div className="centered">
          <Typography variant="h2"> Return Policy</Typography>
        </div>
      </div>
      <div className="privacy-div">
        <ul>
          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                What is the standard delivery timeline?
              </Typography>
              <Typography mb={4} fontSize="16px">
                The normal delivery timeline is 1 business days/hours from the
                date of dispatch. The seller usually dispatches with 12 business
                hours from receipt of the order. Business days are defined as
                Monday to Saturday (excluding public holidays and Sundays)
              </Typography>
            </div>
          </li>

          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                Is there any Minimum Order Value?{" "}
              </Typography>
              <Typography mb={4} fontSize="16px">
                Yes. Minimum Order value is INR 3,000 (Net Value Excl GST) per
                order, per seller.{" "}
              </Typography>
            </div>
          </li>

          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                What is the delivery charges?
              </Typography>
              <Typography mb={4} fontSize="16px">
                There is a 100 INR charge for every order, per seller (Excluding
                GST).
              </Typography>
            </div>
          </li>

          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                Under what circumstances will the delivery get delayed?
              </Typography>
              <Typography mb={4} fontSize="16px">
                Under usual circumstances the shipment will be delivered within
                the estimated timeline. However, in case of special situation
                such as transport disruption, political turmoil, natural
                catastrophe (flood, earthquake, pandemic etc), or force majeure
                events the shipment can get delayed. However, our team will keep
                you posted on the status of your shipment along with revised
                estimated delivery schedule.
              </Typography>
            </div>
          </li>

          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                How do I find out if MEDDAILY.IN delivers to my locations?
              </Typography>
              <Typography mb={4} fontSize="16px">
                If your areas is not serviceable by our logistic partners, we’ll
                try make alternative arrangements, on best effort basis. We’ll
                keep you informed about the same.
              </Typography>
            </div>
          </li>

          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                Can my shipping address be different from my billing address?
              </Typography>
              <Typography mb={4} fontSize="16px">
                No. The billing address and shipping address will be same as the
                one provided in the Drug License provided during registration /
                reverification.
              </Typography>
            </div>
          </li>

          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                When can I cancel my order?
              </Typography>
              <Typography mb={4} fontSize="16px">
                You can cancel the order any time, subject to the terms and
                condition of MEDDAILY.IN
              </Typography>
            </div>
          </li>

          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                How can I make payment for my order?
              </Typography>
              <Typography mb={4} fontSize="16px">
                When confirming the order, you can choose to make Cash Payment
                on Delivery (COD). You can also opt to make prepaid order using
                flexible options of Netbanking / UPI / Credit Card / Wallets /
                Googlepay etc.
              </Typography>
            </div>
          </li>

          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                How can I return a shipment delivered to me?
              </Typography>
              <Typography mb={4} fontSize="16px">
                Once you’ve received your shipment, you must inform us about
                faulty/expired supply by writing to us at admin@meddaily.in or
                by calling +91 9090158159, referring your Order ID, within 24
                hours with images of the supplied product & invoice. A faulty
                supply is defined as a mismatch in description of the product
                ordered vs product received, breakage in the shipment, shipment
                in non-saleable condition. Under such circumstance, MEDDAILY.IN
                will confirm the same with the seller and arrange for pick-up of
                the same at no extra cost. In case there is no difference in the
                product ordered vs product received, but you want to return the
                goods, the same can be pick up with pick-up charges being borne
                by you..
              </Typography>
            </div>
          </li>

          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                How should I pack my product for return?
              </Typography>
              <Typography mb={4} fontSize="16px">
                While preparing the product for reverse pick-up, all you need to
                do is put the faulty product inside the dispatch box provided by
                us and write us when ready. Our logistic partners will do the
                needful.
              </Typography>
            </div>
          </li>

          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                When is a return not possible?
              </Typography>
              <Typography mb={4} fontSize="16px">
                You can return the shipment for a faulty supply, the same must
                be done within 24 hours of delivery, post which a return shall
                not be allowed.
              </Typography>
            </div>
          </li>
        </ul>

        <Typography mb={4} variant="h4">
          Return & Refund Policy
        </Typography>

        <ul>
          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                My order is not getting processed. Can I cancel it?{" "}
              </Typography>
              <Typography mb={4} fontSize="16px">
                Yes. You can cancel the order before it is dispatched. The
                orders cannot be cancelled after being dispatched.
              </Typography>
            </div>
          </li>

          <li>
            <div className="managing-div">
              <Typography mb={1} variant="h6">
                I’ve cancelled prepaid order, which is yet to be dispatched.
                When can I get refund?
              </Typography>
              <Typography mb={4} fontSize="16px">
                If you’re prepaid order is not dispatched & you’ve cancelled it,
                the refund will be processed within 10 business days. In case of
                delay, you can write to us on admin@meddaily.in or  by calling
                +91 9090158159 referring you Order ID.
              </Typography>
            </div>
          </li>
        </ul>

        <Typography mb={1} variant="h6">
          <Typography variant="span" fontWeight="bold">
            NOTE:{" "}
          </Typography>
          - MEDDAILY.IN reserves the right to amend the Shipping, Returns &
          Refund Policy as and when deemed necessary.
        </Typography>
      </div>
      <Footer />
    </div>
  );
};

export default ReturnPolicy;

import React from 'react';
import { Link } from 'react-router-dom';
// import Expensesofweek from "./charts/Expensesofweek";
// import Growthchart from "./charts/Growthchart";
// import Incomechart from "./charts/Incomechart";
// import OrderStatisticsChart from "./charts/OrderStatisticsChart";
// import Profilereportchart from "./charts/Profilereportchart";
// import Totalrevenuechart from "./charts/Totalrevenuechart";
// import Navbar from "../pages/Navbar";
import Navbar from './Navbar';
import '../css/core.css';
import '../css/theme-default.css';
import Sidebar from './Sidebar';
import Dashboard from './DashBoard';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchdisreturnReq } from '../reducers/HomeReducer';

// export default function DistDashbord({ children }) {
// 	const authToken = localStorage.getItem('disToken');
// 	console.log('ch', children);
// 	return (
// 		<>
// 			<div className='layout-wrapper layout-content-navbar'>
// 				<div className='layout-container'>
// 					<Sidebar />

// 					<div className='layout-page'>
// 						<Navbar />
// 						{children}
// 						{/*  */}
// 					</div>
// 				</div>

// 				<div className='layout-overlay layout-menu-toggle'></div>
// 			</div>
// 		</>
// 	);
// }
const DistDashboard = ({ children }) => {
	console.log('ch', children);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchdisreturnReq());
	}, []);
	return (
		<>
			<div className='layout-wrapper layout-content-navbar'>
				<div className='layout-container'>
					<Sidebar />

					<div className='layout-page'>
						<Navbar />
						{children}
					</div>
				</div>
				<div className='layout-overlay layout-menu-toggle'></div>
			</div>
		</>
	);
};
export default DistDashboard;

import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import { Link, useParams } from "react-router-dom";
import {
  fetchorderdetailsDispatch,
  fetchorderdetailsDispatchNew,
  addReturnDetails,
} from "../reducers/HomeReducer";
import { useDispatch } from "react-redux";
// import { date } from 'yup';
// import { Table, TableBody, TableCell } from '@mui/material';
import {
  Button,
  Col,
  Container,
  Row,
  TabContainer,
  Table,
} from "react-bootstrap";
import Footer from "../components/footer";
import Navbar from "../components/Navbar";
import Typography from "@mui/material/Typography";
import google from "../image/google.png";
import app from "../image/app.png";
import mobile from "../image/phone.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { TableCell, TableRow } from "@mui/material";

function ViewDetails() {
  // console.log("ViewDetails component rendered");
  const { _id } = useParams();
  console.log("Order ID from URL:", _id);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [returnOrderSuccess, setReturnOrderSuccess] = useState(false);
  const [deliveryfee, setDeliveryFee] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchorderdetailsDispatchNew(_id));
        console.log("API response:", response);

        if (response && response.status && response.data) {
          setData(response.data);

          if (response.data.delivery_fee === true) {
            setDeliveryFee(118);
          } else {
            setDeliveryFee(0);
          }
        } else {
          console.error("Invalid response structure:", response);
        }
      } catch (error) {
        console.error("Error in useEffect:", error);
      }
    };

    fetchData();
  }, [dispatch, _id]);
  console.log(deliveryfee, "delivert");
  const navigate = useNavigate();

  function orderStatus(code) {
    if (code == 0) {
      return "Cancelled";
    } else if (code == 1) {
      return "Shipped";
    } else if (code == 3) {
      return "Delivered";
    } else if (code == 4) {
      return "Order Placed";
    } else if (code == 5) {
      return "Order Return";
    }
  }

  const handleCancelOrder = async () => {
    try {
      const response = await axios.get(
        `/cancel_order_retailer?order_id=${data.order_id}`
      );
      if (response.data.status === true) {
        // console.log(response.data);
        alert("Order Cancelled Successfully");
        navigate("/orderlist");
      } else {
        console.error(response?.data?.message);
      }
    } catch (error) {
      console.error("Error cancelling order:", error);
    }
  };

  const totalPrice = data?.products?.reduce(
    (total, product) => total + product.price * product.quantity,
    0
  );
  const totalApplicableTax = data?.products
    ?.reduce((total, product) => {
      const totalPrice = product.price * product.quantity;
      const tax = parseInt(product.tax);
      const applicableTax = totalPrice * (tax / 100);

      return total + applicableTax;
    }, 0)
    .toFixed(2);
  // console.log(deliveryfee, 'dfsdfsfsdfsd')
  // console.log(totalPrice)
  // console.log(totalApplicableTax)

  // const handleReturnOrder = async () => {
  //     try {
  //         const id = data.order_id;
  //         const bodyData = {
  //             order_id: "RETURN" + id,
  //             quantity: 1,
  //             reason: "",
  //             message: "",
  //         };
  //         // console.log("BODYDATA", bodyData);
  //         await dispatch(addReturnDetails(bodyData));
  //         setReturnOrderSuccess(true); // Set the state to true upon success
  //     } catch (error) {
  //         console.error("Error in handleReturnOrder:", error);
  //     }
  // };
  const handleDownload = async () => {
    axios
      .get("/get_invoice", {
        params: { order_id: data.order_id },
        headers: {
          token: sessionStorage.getItem("token"),
          // "access-control-allow-origin":'*'
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const binaryData = atob(response.data.data);

          const byteArray = new Uint8Array(binaryData.length);
          for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([byteArray], { type: `new.pdf` });

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.download = `invoice.pdf`;
          a.href = url;
          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          console.log("PDF downloaded successfully");
        } else {
          console.error(
            "PDF download failed:",
            response.status,
            response.statusText
          );
        }
      })

      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };

  const handleSummary = async () => {
    axios
      .get("/get_summary", {
        params: { order_id: data.order_id },
        headers: {
          token: sessionStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const binaryData = atob(response.data.data);

          const byteArray = new Uint8Array(binaryData.length);
          for (let i = 0; i < binaryData.length; i++) {
            byteArray[i] = binaryData.charCodeAt(i);
          }

          const blob = new Blob([byteArray], { type: `new.pdf` });

          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.download = `summary.pdf`;
          a.href = url;
          document.body.appendChild(a);
          a.click();

          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
          console.log("PDF downloaded successfully");
        } else {
          console.error(
            "PDF download failed:",
            response.status,
            response.statusText
          );
        }
      })

      .catch((error) => {
        console.error("Error downloading PDF:", error);
      });
  };

  function paymentType(code) {
    if (code == 1) {
      return "COD";
    } else if (code == 3) {
      return "On Credit";
    } else if (code == 2) {
      return "PrePaid";
    } else if (code == 0) {
      return "COD";
    }
  }

  return (
    <>
      <Navbar />
      <div>
        <div>
          <Card
            className="text-black mt-5 "
            style={{ width: "70%", margin: "auto" }}
          >
            <Card.Title
              className="mb-3 pb-3 ml-2"
              style={{ fontWeight: "600", fontSize: "32px", color: "black" }}
            >
              ORDER DETAILS
            </Card.Title>
            <Card.Text
              style={{
                color: "black",
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "2px",
              }}
            >
              Distributor Name:{" "}
              <span style={{ color: "gray" }}>{data.distributor_name}</span>
            </Card.Text>
            <Card.Text
              style={{
                color: "black",
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "2px",
              }}
            >
              Retailer Name:{" "}
              <span style={{ color: "gray" }}>{data.retailer_name}</span>
            </Card.Text>
            <Card.Text
              style={{
                color: "black",
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "2px",
              }}
            >
              Billing Address:{" "}
              <span style={{ color: "gray" }}>{data.billing_address}</span>
            </Card.Text>
            <Card.Text
              style={{
                color: "black",
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "2px",
              }}
            >
              Order ID: <span style={{ color: "gray" }}>{data.order_id}</span>
            </Card.Text>
            <Card.Text
              style={{
                color: "black",
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "2px",
              }}
            >
              Payment Type:{" "}
              <span style={{ color: "gray" }}>
                {paymentType(data.payment_type)}
              </span>
            </Card.Text>
            <Card.Text
              style={{
                color: "black",
                fontSize: "16px",
                fontWeight: "600",
                marginBottom: "2px",
              }}
            >
              Order Status:{" "}
              <span style={{ color: "gray" }}>
                {orderStatus(data.order_status)}
              </span>
            </Card.Text>

            <div
              style={{ width: "80%", marginLeft: "50px", marginTop: "10px" }}
            >
              <table style={{ border: "none" }} cellSpacing={"10px"}>
                <tr style={{ borderBottom: "1px solid #E5E5E5" }}>
                  <th
                    style={{
                      color: "black",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Product
                  </th>
                  <th
                    style={{
                      color: "black",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Price
                  </th>
                  <th
                    style={{
                      color: "black",
                      fontWeight: "500",
                      fontSize: "16px",
                    }}
                  >
                    Quantity
                  </th>
                </tr>
                {data?.products?.map((product, index) => (
                  <tr
                    style={{ borderBottom: "1px solid #E5E5E5" }}
                    key={index._id}
                  >
                    <td
                      style={{
                        color: "#102A43",
                        fontSize: "16px",
                        fontWeight: "600",
                        lineHeight: "24px",
                      }}
                    >
                      {product.name}
                    </td>
                    <td
                      style={{
                        color: "#6EAFAB",
                        fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "24px",
                      }}
                    >
                      {" "}
                      ₹ {product.price}
                    </td>
                    <td
                      style={{
                        color: "#102A43",
                        fontSize: "12px",
                        fontWeight: "600",
                        lineHeight: "24px",
                      }}
                    >
                      {product.quantity}
                    </td>
                  </tr>
                ))}
              </table>
            </div>

            <div>
              <table
                style={{
                  border: "none",
                  width: "80%",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                <div>
                  <Col>
                    <Card.Title
                      className="mt-5 "
                      style={{
                        color: "black",
                        fontWeight: "600",
                        fontSize: "18px",
                      }}
                    >
                      Bill Details :
                    </Card.Title>
                  </Col>
                  <tr>
                    <td
                      style={{
                        color: "#7D8FAB",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      Item Total :
                    </td>
                    <td>
                      <span
                        className="fw-bolder"
                        style={{ marginLeft: "70px", paddingBottom: "12px" }}
                      >
                        Rs. {totalPrice}
                      </span>
                    </td>
                  </tr>
                  {data.order_status !== 5 ? (
                    <tr>
                      <td
                        style={{
                          color: "#7D8FAB",
                          fontWeight: "500",
                          fontSize: "16px",
                        }}
                      >
                        {" "}
                        Delivery Fee :
                      </td>
                      <td>
                        <span
                          className="fw-bolder"
                          style={{ marginLeft: "70px", paddingBottom: "12px" }}
                        >
                          Rs .{deliveryfee}
                        </span>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                  <tr style={{ borderBottom: "1px solid " }}>
                    <td
                      style={{
                        color: "#7D8FAB",
                        fontWeight: "500",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      Taxes and charges :
                    </td>
                    <td>
                      <span
                        className="fw-bolder"
                        style={{ marginLeft: "70px", paddingBottom: "12px" }}
                      >
                        Rs. {totalApplicableTax}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        color: "black",
                        fontWeight: "600",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      Order Total :
                    </td>
                    <td>
                      <span
                        className="fw-bolder"
                        style={{ marginLeft: "70px", paddingBottom: "12px" }}
                      >
                        Rs.{" "}
                        {data.order_status === 5
                          ? (
                              parseFloat(totalPrice) +
                              parseFloat(totalApplicableTax)
                            ).toFixed(2)
                          : (
                              parseFloat(totalPrice) +
                              parseFloat(totalApplicableTax) +
                              parseFloat(deliveryfee)
                            ).toFixed(2)}
                      </span>
                    </td>
                  </tr>
                </div>
              </table>
            </div>

            <div className="d-flex  mt-5">
              {data.order_status === 4 ? (
                <Button
                  type="button"
                  className="btn"
                  style={{
                    backgroundColor: "#6eafab",
                    color: "white",
                    borderColor: "#6eafab",
                  }}
                  onClick={handleCancelOrder}
                >
                  Cancel Order
                </Button>
              ) : null}

              {data.order_status === 3 && data.order_status !== 5 ? (
                <Link to="/detailsreturn">
                  <Button
                    type="button"
                    className="btn"
                    style={{
                      backgroundColor: "#6eafab",
                      marginLeft: "100px",
                      color: "white",
                      borderColor: "#6eafab",
                    }}
                    // onClick={handleReturnOrder}
                  >
                    Return Order
                  </Button>
                </Link>
              ) : null}
              {data.order_status === 3 &&
              data.order_status !== 5 &&
              data.return_status !== 1 &&
              data.return_status !== 2 ? (
                <Button
                  type="button"
                  class="btn"
                  style={{
                    backgroundColor: "#6eafab",
                    marginLeft: "50px",
                    color: "white",
                    borderColor: "#6eafab",
                  }}
                  onClick={handleSummary}
                >
                  Download Summary
                </Button>
              ) : null}
              {data.order_status === 3 ||
              data.return_status === 3 ||
              (data.return_status !== 0 &&
                data.return_status !== 1 &&
                data.return_status !== 2) ? (
                <Button
                  type="button"
                  class="btn"
                  style={{
                    backgroundColor: "#6eafab",
                    marginLeft: "50px",
                    color: "white",
                    borderColor: "#6eafab",
                  }}
                  onClick={handleDownload}
                >
                  {data.return_status === 3
                    ? "Download Credit Note"
                    : "Download Invoice"}
                </Button>
              ) : null}
            </div>
          </Card>
        </div>
        <div className="promotionlanding mt-5">
          <div>
            <Typography variant="h5" style={{ color: "black" }}>
              <h3>Working For Your Better health</h3>

              <Typography color="text.secondary" style={{ fontSize: "16px" }}>
                Are you ready to take your healthcare business to new heights?
                Look no further than Meddaily.in app, the groundbreaking B2B app
                designed to revolutionize the way medical professionals
                collaborate and thrive in today's fast-paced industry.
              </Typography>
            </Typography>
            <img src={google} alt="logo" className="downloadlanding" />
            <img src={app} alt="logo" className="downloadlanding" />
          </div>
          <div className="mobile-imglanding">
            <img src={mobile} alt="mobile_img" />
          </div>
        </div>
        <Footer />
      </div>
      {/* </div> */}
    </>
  );
}
export default ViewDetails;
